import { observable, action, autorun } from "mobx";
import { IHotel } from "messages";
import { ApiService } from "../services/index";
import { ContextAndIdStore } from "./context-and-id";
import { LocaleStore } from "./locale";

export class HotelStore {
  @observable public isLoading: boolean = false;
  @observable public hotel?: IHotel;

  @observable private contextAndIdStore?: ContextAndIdStore;
  private localeStore?: LocaleStore;

  constructor(contextAndIdStore: ContextAndIdStore, localeStore: LocaleStore) {
    this.contextAndIdStore = contextAndIdStore;
    this.localeStore = localeStore;

    // NOTE: Fetch the hotel information once the tid and pid is set
    autorun(() => {
      if (!this.contextAndIdStore!.tid || !this.contextAndIdStore!.pid) {
        return;
      }
      this.fetch();
    });
  }

  @action private async fetch() {
    const { pid, tid } = this.contextAndIdStore!;
    this.isLoading = true;
    const hotel = await ApiService.getHotelInformation(tid!, pid!);
    this.hotel = hotel;
    if (this.hotel.localeConf) {
      this.localeStore!.setLocaleList(this.hotel.localeConf);
    }
    this.isLoading = false;
  }
}
