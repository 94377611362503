import { Property, Guest } from "messaging";
import { action, observable } from "mobx";

export class ContextAndIdStore {
  @observable public gid?: string;
  @observable public pid?: string;
  @observable public tid?: string;

  @observable public guest?: Guest;
  @observable public property?: Property;

  @action public setAllIds({ gid, pid, tid }: { gid?: string; pid: string; tid: string }) {
    if (!pid || !tid) {
      throw new Error("Invalid tid or pid");
    }

    this.pid = pid;
    this.tid = tid;

    this.gid = gid || this.createGid();

    this.persistGid();
    this.setAllContext();
  }

  @action private setAllContext() {
    this.property = Property.fromString(`t/${this.tid}/p/${this.pid}`);
    this.guest = Guest.fromString(`${this.property}/g/${this.gid}`);
  }

  private persistGid() {
    localStorage.setItem(`${this.tid}/${this.pid}`, `${this.gid}`);
  }

  private createGid() {
    return (
      "wb-" +
      Math.random()
        .toString(36)
        .substr(2)
    );
  }
}
