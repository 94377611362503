import {
  CircularProgress,
  createMuiTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MuiThemeProvider
} from "@material-ui/core";
import { cyan } from "@material-ui/core/colors";
import StaffChatIcon from "@material-ui/icons/Forum";
import { BotMessage } from "messaging";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, createRef, Fragment } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ThemedStyledProps } from "styled-components";
import { AppBar, CHILDREN_PROP_TYPE_VARIANT } from "../../components/AppBar";
import { CardView } from "../../components/CardView";
import { FilesUpload } from "../../components/FilesUpload";
import { Form } from "../../components/Form";
import { FullscreenGalleryDialog } from "../../components/FullscreenGalleryDialog";
import { InputElement, INPUT_ELEMENT_VARIANT } from "../../components/InputElement";
import { Message, MESSAGE_VARIANT } from "../../components/Message";
import { OptionButtons } from "../../components/OptionButtons";
import { TypingIndicator } from "../../components/TypingIndicator";
import { storage } from "../../services";
import { BotManagerStore, BotStore, ChatManagerStore, ContextAndIdStore, HotelStore } from "../../stores";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";
import { MessagesEnd } from "../MessagingScreen";
import { NotificationToneStore } from "../../stores/notification-tone";

export interface IWebsiteBotMessagingScreenState {
  isUploading: boolean;
  showFullscreenGalleryDialog?: {
    imgUrls: string | string[];
    clickedStep: number;
  };
  showServiceRequests: boolean;
  textBoxContent: string;
}

const Content = styled.div`
  padding: 16px;
  width: 100%;
  /* overflow-x: hidden; */
`;

const muiTheme = createMuiTheme({
  palette: {
    primary: cyan
  }
});

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed};
`;

export interface IWebsiteBotMessagingScreenProps extends RouteComponentProps<{ tid: string; pid: string }> {
  botManagerStore: BotManagerStore;
  botStore: BotStore;
  chatManagerStore: ChatManagerStore;
  contextAndIdStore: ContextAndIdStore;
  hotelStore: HotelStore;
  notificationToneStore: NotificationToneStore;
}

@inject("botStore", "botManagerStore", "chatManagerStore", "contextAndIdStore", "hotelStore", "notificationToneStore")
@observer
class WebsiteBotMessagingScreen extends Component<IWebsiteBotMessagingScreenProps, IWebsiteBotMessagingScreenState> {
  public messagesEnd = createRef<HTMLDivElement>();

  public state: IWebsiteBotMessagingScreenState = {
    isUploading: false,
    showServiceRequests: false,
    textBoxContent: ""
  };

  public componentDidMount = async () => {
    const { tid, pid } = this.props.match.params;
    const gid = localStorage.getItem(`${tid}/${pid}`) as string | undefined;

    const { botManagerStore, botStore, contextAndIdStore } = this.props;
    // TODO: This logic is not working properly when the messages are large in numbers
    // NOTE: Uncomment this when the logic is fixed @technical-debt @future-improvement
    // botStore.setOnRehydrate(this.scrollToBottom);
    // botStore.setOnNewMessage(this.scrollToBottom);

    contextAndIdStore.setAllIds({ gid, pid, tid });
    botStore.rehydrate();
    botManagerStore.ping();
  };

  // NOTE: Remove this once the logic above is fixed
  public componentDidUpdate = () => {
    this.scrollToBottom();
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  public handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textBoxContent: event.currentTarget.value });
  };

  public handleSendMessage = () => {
    this.props.botManagerStore.reply({ text: this.state.textBoxContent });
    this.setState({ textBoxContent: "" });
  };

  public handleFullscreenGalleryDialogClose = () => {
    this.setState({ showFullscreenGalleryDialog: undefined });
  };

  public handleGalleryImageClick = (imgUrls: string | string[], clickedStep: number) => {
    this.setState({ showFullscreenGalleryDialog: { imgUrls, clickedStep } });
  };

  public handleFilesChange = async (e: ChangeEvent<HTMLInputElement>, serreqid: string) => {
    const files = e.target.files;
    if (files) {
      const uploadPromises: Array<Promise<string>> = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        uploadPromises.push(this.uploadAsAPromise(files[index], index, serreqid));
      }
      try {
        this.setState({ isUploading: true });
        const images = await Promise.all(uploadPromises);
        this.props.botManagerStore.reply({ text: JSON.stringify(images), gallery: true }); // NOTE: true will make set the gallery to true
        this.setState({ isUploading: false });
      } catch (error) {
        // TODO: handle error here
        this.setState({ isUploading: false });
      }
    }
  };

  public uploadAsAPromise = (imageFile: File, index: number, serreqid: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const {
        contextAndIdStore: { property }
      } = this.props;
      const storageRef = storage.ref(`${property}/request/${serreqid}/${new Date().getTime()}-${index}`);
      const task = storageRef.put(imageFile);
      task.on(
        "state_changed",
        (snapshot: any) => {
          // NOTE: Can be used to calculate the actual progress percentage
        },
        (error: Error) => {
          reject(error);
        },
        async () => {
          resolve(await task.snapshot.ref.getDownloadURL());
        }
      );
    });
  };

  public render() {
    const {
      botManagerStore,
      botStore: { messages },
      chatManagerStore,
      notificationToneStore,
      contextAndIdStore,
      contextAndIdStore: { guest },
      hotelStore: { hotel }
    } = this.props;
    const { isUploading, showFullscreenGalleryDialog } = this.state;

    return (
      <AppBar
        title={(hotel && hotel.displayName) || "Hotel Bot"}
        isLocaleShown={true}
        notificationToneStore={notificationToneStore}
        contextAndIdStore={contextAndIdStore}
        render={() => (
          <Fragment>
            {chatManagerStore.session && (
              <IconButton
                color="inherit"
                component={({ innerRef, ...props }) => <Link {...props} to={`${this.props.match.url}/chat`} />}
              >
                <StaffChatIcon />
              </IconButton>
            )}
          </Fragment>
        )}
        variant={CHILDREN_PROP_TYPE_VARIANT.REACT_NODE}
      >
        {showFullscreenGalleryDialog && (
          <FullscreenGalleryDialog
            {...showFullscreenGalleryDialog}
            open={showFullscreenGalleryDialog ? true : false}
            handleClose={this.handleFullscreenGalleryDialogClose}
          />
        )}
        <Dialog open={isUploading}>
          <DialogTitle>Uploading</DialogTitle>
          <DialogContent>
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          </DialogContent>
        </Dialog>
        <MuiThemeProvider theme={muiTheme}>
          <Content>
            {messages &&
              messages.length > 0 &&
              messages.map(
                (message: BotMessage, index: number) =>
                  message.type === "BotMessage" && (
                    <Fragment key={`${index}`}>
                      <Message
                        message={message}
                        previousMessage={messages[index - 1]}
                        guest={guest!}
                        logo={hotel && hotel.displayLogo}
                        index={index}
                        handleGalleryImageClick={this.handleGalleryImageClick}
                        variant={MESSAGE_VARIANT.BOT_MESSAGE}
                      />
                      {index === messages.length - 1 && message.continueTyping && <TypingIndicator />}
                      {message.sender === `${guest}` && index === messages.length - 1 && <TypingIndicator />}
                      {index === messages.length - 1 && message.cardView && (
                        <CardView
                          cardView={message.cardView}
                          transitionDelay={1000}
                          handleClick={(text: string) => botManagerStore.reply({ text })}
                        />
                      )}
                      {index === messages.length - 1 &&
                        message.serviceListMessage &&
                        message.serviceListMessage.options && (
                          <OptionButtons
                            baseUrl={this.props.match.url}
                            optionMessage={message.serviceListMessage}
                            variant="tile"
                            transitionDelay={100}
                            handleClick={(text: string) => botManagerStore.reply({ text })}
                          />
                        )}
                      {index === messages.length - 1 && message.uploadFilesMessage && (
                        <FilesUpload
                          uploadFilesMessage={message.uploadFilesMessage}
                          handleFilesChange={this.handleFilesChange}
                        />
                      )}
                      {index === messages.length - 1 && message.inputMessage && (
                        <InputElement
                          inputElementMessage={message.inputMessage}
                          textBoxContent={this.state.textBoxContent}
                          handleTyping={this.handleTyping}
                          handleSendMessage={this.handleSendMessage}
                          variant={INPUT_ELEMENT_VARIANT.BOT_FREE_TEXT}
                        />
                      )}
                      {index === messages.length - 1 && message.formView && (
                        <Form
                          formView={message.formView}
                          onFormSubmitHandler={(text, gallery, data) => botManagerStore.reply({ text, gallery, data })}
                        />
                      )}
                      {index === messages.length - 1 && message.optionMessage && message.optionMessage.options && (
                        <OptionButtons
                          baseUrl={this.props.match.url}
                          optionMessage={message.optionMessage}
                          variant="pill"
                          transitionDelay={1000}
                          handleClick={(text: string) => botManagerStore.reply({ text })}
                        />
                      )}
                    </Fragment>
                  )
              )}

            <MessagesEnd ref={this.messagesEnd} />
          </Content>
        </MuiThemeProvider>
      </AppBar>
    );
  }
}

export default WebsiteBotMessagingScreen;
