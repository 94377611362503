import { Button, TextField } from "@material-ui/core";
import { Field, FieldProps, Form, Formik, FormikActions, FormikProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import * as Yup from "yup";

enum CHAT_WITH_US_FORM_ERROR {
  INVALID_EMAIL = "Invalid email",
  MAX_BOOKING_NO = 20,
  MIN_BOOKING_NO = 2,
  MAX_NAME = 50,
  MIN_NAME = 2,
  TOO_SHORT = "Too short!",
  TOO_LONG = "Too long!"
}

const chatWithUsFormSchema = Yup.object().shape({
  bookingNo: Yup.string()
    .min(CHAT_WITH_US_FORM_ERROR.MIN_BOOKING_NO, CHAT_WITH_US_FORM_ERROR.TOO_SHORT)
    .max(CHAT_WITH_US_FORM_ERROR.MAX_BOOKING_NO, CHAT_WITH_US_FORM_ERROR.TOO_LONG),
  email: Yup.string().email(CHAT_WITH_US_FORM_ERROR.INVALID_EMAIL),
  name: Yup.string()
    .min(CHAT_WITH_US_FORM_ERROR.MIN_NAME, CHAT_WITH_US_FORM_ERROR.TOO_SHORT)
    .max(CHAT_WITH_US_FORM_ERROR.MAX_NAME, CHAT_WITH_US_FORM_ERROR.TOO_LONG)
});

export interface IChatWithUsFormValues {
  bookingNo: string;
  email: string;
  name: string;
}

export interface IChatWithUsFormProps {
  initialchatWithUsFormValues: IChatWithUsFormValues;
  onSubmit: (values: IChatWithUsFormValues) => void;
}

export const ChatWithUsForm: FunctionComponent<IChatWithUsFormProps> = (props: IChatWithUsFormProps) => {
  return (
    <Formik
      initialValues={props.initialchatWithUsFormValues}
      validationSchema={chatWithUsFormSchema}
      onSubmit={(values: IChatWithUsFormValues, actions: FormikActions<IChatWithUsFormValues>) => {
        props.onSubmit(values);
        actions.setSubmitting(false);
      }}
      render={(formikBag: FormikProps<IChatWithUsFormValues>) => (
        <Form>
          <Field
            name="bookingNo"
            render={({ field, form }: FieldProps<IChatWithUsFormValues>) => (
              <Fragment>
                <TextField
                  type="text"
                  {...field}
                  label="Booking #"
                  placeholder="BD9311S2D32"
                  fullWidth
                  autoFocus
                  margin="dense"
                />
                {form.touched.bookingNo && form.errors.bookingNo && form.errors.bookingNo}
              </Fragment>
            )}
          />
          <p>OR</p>
          <Field
            name="name"
            render={({ field, form }: FieldProps<IChatWithUsFormValues>) => (
              <Fragment>
                <TextField type="text" {...field} label="Name" placeholder="John Doe" fullWidth margin="dense" />
                {form.touched.name && form.errors.name && form.errors.name}
              </Fragment>
            )}
          />
          <Field
            name="email"
            render={({ field, form }: FieldProps<IChatWithUsFormValues>) => (
              <Fragment>
                <TextField
                  type="text"
                  {...field}
                  label="Email"
                  placeholder="johnd@example.com"
                  fullWidth
                  margin="dense"
                />
                {form.touched.name && form.errors.name && form.errors.name}
              </Fragment>
            )}
          />
          <Button type="submit" color="primary">
            Chat with us
          </Button>
        </Form>
      )}
    />
  );
};

export default ChatWithUsForm;
