import {
  Button,
  createStyles,
  FormLabel,
  Select,
  TextField,
  Typography,
  withStyles,
  WithStyles,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { IInputElementMessage } from "messaging";
import { inject, observer } from "mobx-react";
import React, { Fragment, FunctionComponent } from "react";
import { DynamicFormStore } from "../../stores";
import styled from "../../styles/styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
`;

const styles = createStyles({
  formControl: {
    width: "100px"
  }
});

interface IFormControlButtonProps extends WithStyles<typeof styles> {
  dynamicFormStore?: DynamicFormStore;
  input: IInputElementMessage;
}

const FormControlButton: FunctionComponent<IFormControlButtonProps> = inject("dynamicFormStore")(
  observer((props: IFormControlButtonProps) => {
    const {
      dynamicFormStore,
      input: { accept, disabled, label, max, min, name, options, placeHolder, required, type }
    } = props;
    const { formData, setFilesUpload, setProperty } = dynamicFormStore!;
    return (
      <Fragment>
        <InputContainer>
          {type === "text" && (
            <TextField
              disabled={disabled || false}
              required={required || false}
              fullWidth
              type="text"
              label={label!}
              placeholder={placeHolder!}
              value={formData[name!] || ""}
              onChange={e => setProperty(e, name!)}
            />
          )}
          {type === "textarea" && (
            <TextField
              disabled={disabled || false}
              required={required || false}
              type="text"
              label={label!}
              placeholder={placeHolder!}
              multiline
              rows={3}
              rowsMax={4}
              value={formData[name!] || ""}
              onChange={e => setProperty(e, name!)}
            />
          )}
          {type === "number" && (
            <TextField
              inputProps={{ min, max }}
              disabled={disabled || false}
              required={required || false}
              fullWidth
              type="number"
              label={label!}
              placeholder={placeHolder!}
              value={formData[name!] || ""}
              onChange={e => setProperty(e, name!)}
            />
          )}
          {type === "select" && (
            <FormControl>
              <InputLabel htmlFor="dynamic-select">{label}</InputLabel>
              <Select
                native
                disabled={disabled || false}
                required={required || false}
                inputProps={{ id: "dynamic-select", name }}
                value={formData[name!] || ""}
                onChange={e => setProperty(e, name!)}
              >
                {options &&
                  options.map((option, index: number) => (
                    <option key={`${name}-${index}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          )}
          {type === "file" && (
            <Fragment>
              <FormLabel htmlFor="dynamic-upload">{label}</FormLabel>
              <Button variant="outlined" color="secondary" component="label">
                <Typography color="inherit">Upload File(s)</Typography>
                <input
                  id="dynamic-upload"
                  style={{ display: "none" }}
                  type="file"
                  accept={accept || "image/*"}
                  multiple={max && parseInt(max, 10) > 1 ? true : false}
                  onChange={e => setFilesUpload(e, name!)}
                />
              </Button>
            </Fragment>
          )}
        </InputContainer>
      </Fragment>
    );
  })
);

export default withStyles(styles)(FormControlButton);
