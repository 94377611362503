import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Grow, createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";

import styled from "../../styles/styled-components";
import { IOptionButtonProps } from "./option-buttons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      margin: "0 8px 8px 0",
      width: "90px",
      height: "90px",
      fontSize: "12px",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      color: theme.palette.getContrastText("#ffffff"),
      "&:hover": {
        backgroundColor: grey[50]
      }
    },
    label: {
      textTransform: "none",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      whiteSpace: "normal",
      lineHeight: "0.8rem"
    }
  });

export const Icon = styled.img`
  margin: 10px 0 10px 0;
  width: 50px;
  height: 50px;
`;

interface ITileButtonProps extends IOptionButtonProps, WithStyles<typeof styles> {}

const getIcon = (icon: string) => {
  switch (icon) {
    case "local_laundry_service":
      return <Icon src="/img/laundry.svg" alt="Laundry" />;
    case "about":
      return <Icon src="/img/hotel.svg" alt="About Hotel" />;
    case "local_amenities_service":
      return <Icon src="/img/amenities.svg" alt="Room Amenities" />;
    case "dining":
      return <Icon src="/img/dining.svg" alt="In Room Dining" />;
    case "maintenance":
      return <Icon src="/img/maintenance.svg" alt="Maintenance" />;
    case "people":
      return <Icon src="/img/chat.svg" alt="Chat with S   taff" />;
    case "check-in":
      return <Icon src="/img/check-in.svg" alt="Check in" />;
    case "amenities":
      return <Icon src="/img/hotel-amenities.svg" alt="Hotel Amenities" />;
    case "rooms":
      return <Icon src="/img/rooms.svg" alt="Hotel Rooms" />;
    case "meeting-rooms":
      return <Icon src="/img/meeting-rooms.svg" alt="Meeting Rooms" />;
    case "near-location":
      return <Icon src="/img/near-location.svg" alt="Near Locations" />;
    case "airport-pickup":
      return <Icon src="/img/airport-pickup.svg" alt="Airport Pickup" />;
    case "restaurants":
      return <Icon src="/img/restaurants.svg" alt="Restaurants" />;
    case "breakfast":
      return <Icon src="/img/breakfast.svg" alt="Breakfast" />;
    case "housekeeping":
      return <Icon src="/img/housekeeping.svg" alt="Housekeeping" />;
    case "cleaning":
      return <Icon src="/img/cleaning.svg" alt="Cleaning" />;
    case "forex":
      return <Icon src="/img/forex.svg" alt="Forex" />;
    case "change-booking-number":
      return <Icon src="/img/change-booking-number.svg" alt="Change Booking Number" />;
    case "banquet":
      return <Icon src="/img/stage.svg" alt="Banquet" />;
  }
};

const TileButton: FunctionComponent<ITileButtonProps> = (props: ITileButtonProps) => {
  const {
    baseUrl,
    classes,
    index,
    transitionDelay,
    option: { externalUrl, internalRoute, icon, label, value },
    handleClick
  } = props;
  return (
    <Grow in={true} timeout={transitionDelay + index * 200}>
      <Button
        classes={{ root: classes.root, label: classes.label }}
        {...(externalUrl && { component: "a", href: externalUrl, target: "_blank" })}
        {...(internalRoute !== undefined &&
          internalRoute !== null && {
            component: ({ innerRef, ...props }) => <Link {...props} to={`${baseUrl}${internalRoute}`} />
          })}
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => handleClick(value)}
      >
        {getIcon(icon!)}
        {label}
      </Button>
    </Grow>
  );
};

export default withStyles(styles)(TileButton);
