import React, { ComponentType, FC, Fragment } from "react";
import { Redirect, RouteComponentProps } from "react-router";

let firstRehydrationDone: boolean = false;

const withWebsiteBotURLPersistence = <P extends RouteComponentProps<{ tid: string; pid: string }>>(
  Component: ComponentType<P>
): FC<P> => {
  const HOC: FC<P> = (props: P) => {
    const isWebsiteBotHostSPA =
      process.env.IS_WEBSITE_BOT_HOST_SPA && process.env.IS_WEBSITE_BOT_HOST_SPA === "true" ? true : false;
    const activeUrl = localStorage.getItem(
      `${props.match.params.tid}/${props.match.params.pid}/WEBSITE_BOT_ACTIVE_URL`
    );

    if (firstRehydrationDone && props && props.match && props.match.url) {
      localStorage.setItem(
        `${props.match.params.tid}/${props.match.params.pid}/WEBSITE_BOT_ACTIVE_URL`,
        props.match.url
      );
    }

    if (!firstRehydrationDone && !isWebsiteBotHostSPA) {
      firstRehydrationDone = true;

      return (
        <Fragment>
          {!activeUrl && <Component {...props} />}
          {activeUrl && <Redirect to={activeUrl} />}
        </Fragment>
      );
    }

    return <Component {...props} />;
  };

  return HOC;
};

export default withWebsiteBotURLPersistence;
