import React from "react";
import { keyframes } from "styled-components";
import { Grow } from "@material-ui/core";

import { MessageWrapper, StyledTextBubble, EmptyDiv } from "../Message";
import styled from "../../styles/styled-components";

const TypingAnimation = keyframes`
  0% {
    background-color: #727272;
  }
  100% {
    background-color: #f0f0f0;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  margin-right: 4px;
  background-color: ${props => props.theme.colorPalette.background};
`;

const TypingIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :nth-child(2) {
    animation: ${TypingAnimation} 1s linear 0.333s infinite;
  }

  & > :last-child {
    animation: ${TypingAnimation} 1s linear 0.666s infinite;
    margin: 0;
  }

  & > :first-child {
    animation: ${TypingAnimation} 1s linear infinite;
  }
`;

export default function TypingIndicator(props: {}) {
  return (
    <Grow in={true} timeout={100}>
      <MessageWrapper>
        <EmptyDiv />
        <StyledTextBubble self={false}>
          <TypingIndicatorContainer>
            <Dot />
            <Dot />
            <Dot />
          </TypingIndicatorContainer>
        </StyledTextBubble>
      </MessageWrapper>
    </Grow>
  );
}
