import { EventEmitter } from "events";
import { IPeerMessage, PeerMessage } from "messaging";
import { GuestChatPersistenceService } from "../../services";
import { IAddMessageToChat, IChat, ICompleteChat, IFetchChat, IUnreadMessages } from "./interfaces";

export class ActiveChat extends EventEmitter
  implements
    IChat<PeerMessage>,
    ICompleteChat,
    IAddMessageToChat<PeerMessage>,
    IUnreadMessages<PeerMessage>,
    IFetchChat {
  public static type: string = "ActiveChat";

  public messages: PeerMessage[] = [];

  public noOfLastLoadRecords: number = 0;
  public noOfRecords: number = 10;

  public readonly type: string = ActiveChat.type;

  public unread: number = 0;

  private completed: boolean = false;

  constructor(public readonly sesid: string) {
    super();
    this.fetchFirstPage();
  }

  public addMessage(message: PeerMessage) {
    if (!this.completed) {
      this.addNewMessageToChat(message).then(() => this.emit("new-message"));
    } else {
      this.emit("error", new Error("Chat closed already, cannot add new messages"));
    }
  }

  public addUnreadMessage(message: PeerMessage) {
    if (!this.completed) {
      this.unread += 1;
      this.addNewMessageToChat(message);
    } else {
      this.emit("error", new Error("Chat closed already, cannot add new unread messages"));
    }
  }

  public complete() {
    if (!this.completed) {
      this.completed = true;
    }
  }

  public fetchNextPage = async (page: number) => {
    const messages = await GuestChatPersistenceService.fetchMessages(this.sesid, page, this.noOfRecords);
    this.messages = [...messages.map((message: IPeerMessage) => new PeerMessage(message)), ...this.messages];
    this.noOfLastLoadRecords = messages.length;
  };

  public readUnreadMessages() {
    this.unread = 0;
  }

  private addNewMessageToChat(message: PeerMessage) {
    return new Promise((resolve, reject) => {
      this.messages.push(message);
      resolve();
    });
  }

  private fetchFirstPage() {
    this.fetchNextPage(0);
  }
}
