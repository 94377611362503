import React, { FunctionComponent, ChangeEvent } from "react";
import { IInputElementMessage } from "messaging";
import { Grow, InputBase, Paper, IconButton, createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import SendIcon from "@material-ui/icons/Send";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      padding: "2px 4px",
      marginLeft: "50px",
      marginTop: "8px",
      "&:hover": {
        backgroundColor: grey[50]
      },
      backgroundColor: "#ffffff",
      color: theme.palette.getContrastText("#ffffff")
    },
    iconButton: {
      padding: 10
    },
    input: {
      flex: 1,
      marginLeft: 8
    }
  });

export enum INPUT_ELEMENT_VARIANT {
  BOT_FREE_TEXT = "BOT_FREE_TEXT",
  CHAT_FREE_TEXT = "CHAT_FREE_TEXT"
}

interface ISharedInputElementProps extends WithStyles<typeof styles> {
  inputElementMessage: IInputElementMessage;
  textBoxContent: string;
  handleTyping: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSendMessage: () => void;
}

type IInputElementProps =
  | ISharedInputElementProps & {
      variant: INPUT_ELEMENT_VARIANT.BOT_FREE_TEXT;
    }
  | ISharedInputElementProps & {
      textArea: boolean;
      variant: INPUT_ELEMENT_VARIANT.CHAT_FREE_TEXT;
    };

const InputElement: FunctionComponent<IInputElementProps> = (props: IInputElementProps) => {
  const { classes, inputElementMessage, textBoxContent, handleTyping, handleSendMessage } = props;
  return (
    <Grow in={true} timeout={100}>
      <Paper className={classes.root} elevation={1}>
        <InputBase
          {...(props.variant === INPUT_ELEMENT_VARIANT.CHAT_FREE_TEXT &&
            props.textArea && { multiline: true, rows: 3, rowsMax: 3 })}
          inputProps={{ min: inputElementMessage.min }}
          type={inputElementMessage.type}
          className={classes.input}
          placeholder={inputElementMessage.placeHolder!}
          fullWidth
          value={textBoxContent}
          onChange={handleTyping}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="Message box"
          disabled={textBoxContent.trim().length === 0}
          color="primary"
          onClick={handleSendMessage}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    </Grow>
  );
};

export default withStyles(styles)(InputElement);
