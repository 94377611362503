/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */
  if (typeof define === 'function' && define.amd) define(["protobufjs/minimal"], factory);
  /* CommonJS */
  else if (typeof require === 'function' && typeof module === 'object' && module && module.exports) module.exports = factory(require("protobufjs/minimal"));
})(this, function ($protobuf) {
  "use strict"; // Common aliases

  var $Reader = $protobuf.Reader,
      $Writer = $protobuf.Writer,
      $util = $protobuf.util; // Exported root namespace

  var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

  $root.Notification = function () {
    /**
     * Properties of a Notification.
     * @exports INotification
     * @interface INotification
     * @property {string} body Notification body
     * @property {string} title Notification title
     */

    /**
     * Constructs a new Notification.
     * @exports Notification
     * @classdesc Represents a Notification.
     * @implements INotification
     * @constructor
     * @param {INotification=} [properties] Properties to set
     */
    function Notification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * Notification body.
     * @member {string} body
     * @memberof Notification
     * @instance
     */


    Notification.prototype.body = "";
    /**
     * Notification title.
     * @member {string} title
     * @memberof Notification
     * @instance
     */

    Notification.prototype.title = "";
    /**
     * Encodes the specified Notification message. Does not implicitly {@link Notification.verify|verify} messages.
     * @function encode
     * @memberof Notification
     * @static
     * @param {Notification} message Notification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    Notification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.body);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.title);
      return writer;
    };
    /**
     * Decodes a Notification message from the specified reader or buffer.
     * @function decode
     * @memberof Notification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Notification} Notification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    Notification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.Notification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.body = reader.string();
            break;

          case 1:
            message.title = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("body")) throw $util.ProtocolError("missing required 'body'", {
        instance: message
      });
      if (!message.hasOwnProperty("title")) throw $util.ProtocolError("missing required 'title'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a Notification message.
     * @function verify
     * @memberof Notification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    Notification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.body)) return "body: string expected";
      if (!$util.isString(message.title)) return "title: string expected";
      return null;
    };

    return Notification;
  }();

  $root.NotificationData = function () {
    /**
     * Properties of a NotificationData.
     * @exports INotificationData
     * @interface INotificationData
     * @property {string} operationType NotificationData operationType
     * @property {ServiceRequest|null} [request] NotificationData request
     * @property {ServiceRequestEscalation|null} [escalation] NotificationData escalation
     * @property {HotelBooking|null} [hotelBooking] NotificationData hotelBooking
     * @property {RestaurantOrders|null} [restaurantOrders] NotificationData restaurantOrders
     * @property {Array.<string>|null} [updatedFields] NotificationData updatedFields
     */

    /**
     * Constructs a new NotificationData.
     * @exports NotificationData
     * @classdesc Represents a NotificationData.
     * @implements INotificationData
     * @constructor
     * @param {INotificationData=} [properties] Properties to set
     */
    function NotificationData(properties) {
      this.updatedFields = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * NotificationData operationType.
     * @member {string} operationType
     * @memberof NotificationData
     * @instance
     */


    NotificationData.prototype.operationType = "";
    /**
     * NotificationData request.
     * @member {ServiceRequest|null|undefined} request
     * @memberof NotificationData
     * @instance
     */

    NotificationData.prototype.request = null;
    /**
     * NotificationData escalation.
     * @member {ServiceRequestEscalation|null|undefined} escalation
     * @memberof NotificationData
     * @instance
     */

    NotificationData.prototype.escalation = null;
    /**
     * NotificationData hotelBooking.
     * @member {HotelBooking|null|undefined} hotelBooking
     * @memberof NotificationData
     * @instance
     */

    NotificationData.prototype.hotelBooking = null;
    /**
     * NotificationData restaurantOrders.
     * @member {RestaurantOrders|null|undefined} restaurantOrders
     * @memberof NotificationData
     * @instance
     */

    NotificationData.prototype.restaurantOrders = null;
    /**
     * NotificationData updatedFields.
     * @member {Array.<string>} updatedFields
     * @memberof NotificationData
     * @instance
     */

    NotificationData.prototype.updatedFields = $util.emptyArray; // OneOf field names bound to virtual getters and setters

    var $oneOfFields;
    /**
     * NotificationData msg.
     * @member {"request"|"escalation"|"hotelBooking"|"restaurantOrders"|undefined} msg
     * @memberof NotificationData
     * @instance
     */

    Object.defineProperty(NotificationData.prototype, "msg", {
      get: $util.oneOfGetter($oneOfFields = ["request", "escalation", "hotelBooking", "restaurantOrders"]),
      set: $util.oneOfSetter($oneOfFields)
    });
    /**
     * Encodes the specified NotificationData message. Does not implicitly {@link NotificationData.verify|verify} messages.
     * @function encode
     * @memberof NotificationData
     * @static
     * @param {NotificationData} message NotificationData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    NotificationData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.operationType);
      if (message.request != null && message.hasOwnProperty("request")) $root.ServiceRequest.encode(message.request, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      if (message.escalation != null && message.hasOwnProperty("escalation")) $root.ServiceRequestEscalation.encode(message.escalation, writer.uint32(
      /* id 2, wireType 2 =*/
      18).fork()).ldelim();
      if (message.hotelBooking != null && message.hasOwnProperty("hotelBooking")) $root.HotelBooking.encode(message.hotelBooking, writer.uint32(
      /* id 3, wireType 2 =*/
      26).fork()).ldelim();
      if (message.restaurantOrders != null && message.hasOwnProperty("restaurantOrders")) $root.RestaurantOrders.encode(message.restaurantOrders, writer.uint32(
      /* id 4, wireType 2 =*/
      34).fork()).ldelim();
      if (message.updatedFields != null && message.updatedFields.length) for (var i = 0; i < message.updatedFields.length; ++i) {
        writer.uint32(
        /* id 5, wireType 2 =*/
        42).string(message.updatedFields[i]);
      }
      return writer;
    };
    /**
     * Decodes a NotificationData message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationData} NotificationData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    NotificationData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.NotificationData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.operationType = reader.string();
            break;

          case 1:
            message.request = $root.ServiceRequest.decode(reader, reader.uint32());
            break;

          case 2:
            message.escalation = $root.ServiceRequestEscalation.decode(reader, reader.uint32());
            break;

          case 3:
            message.hotelBooking = $root.HotelBooking.decode(reader, reader.uint32());
            break;

          case 4:
            message.restaurantOrders = $root.RestaurantOrders.decode(reader, reader.uint32());
            break;

          case 5:
            if (!(message.updatedFields && message.updatedFields.length)) message.updatedFields = [];
            message.updatedFields.push(reader.string());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("operationType")) throw $util.ProtocolError("missing required 'operationType'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a NotificationData message.
     * @function verify
     * @memberof NotificationData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    NotificationData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      var properties = {};
      if (!$util.isString(message.operationType)) return "operationType: string expected";

      if (message.request != null && message.hasOwnProperty("request")) {
        properties.msg = 1;
        {
          var error = $root.ServiceRequest.verify(message.request);
          if (error) return "request." + error;
        }
      }

      if (message.escalation != null && message.hasOwnProperty("escalation")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.ServiceRequestEscalation.verify(message.escalation);
          if (error) return "escalation." + error;
        }
      }

      if (message.hotelBooking != null && message.hasOwnProperty("hotelBooking")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.HotelBooking.verify(message.hotelBooking);
          if (error) return "hotelBooking." + error;
        }
      }

      if (message.restaurantOrders != null && message.hasOwnProperty("restaurantOrders")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.RestaurantOrders.verify(message.restaurantOrders);
          if (error) return "restaurantOrders." + error;
        }
      }

      if (message.updatedFields != null && message.hasOwnProperty("updatedFields")) {
        if (!Array.isArray(message.updatedFields)) return "updatedFields: array expected";

        for (var i = 0; i < message.updatedFields.length; ++i) {
          if (!$util.isString(message.updatedFields[i])) return "updatedFields: string[] expected";
        }
      }

      return null;
    };

    return NotificationData;
  }();

  $root.InternalNotification = function () {
    /**
     * Properties of an InternalNotification.
     * @exports IInternalNotification
     * @interface IInternalNotification
     * @property {NotificationData} data InternalNotification data
     * @property {string|null} [guest] InternalNotification guest
     * @property {Notification} notification InternalNotification notification
     * @property {string} property InternalNotification property
     * @property {string|null} [staff] InternalNotification staff
     */

    /**
     * Constructs a new InternalNotification.
     * @exports InternalNotification
     * @classdesc Represents an InternalNotification.
     * @implements IInternalNotification
     * @constructor
     * @param {IInternalNotification=} [properties] Properties to set
     */
    function InternalNotification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * InternalNotification data.
     * @member {NotificationData} data
     * @memberof InternalNotification
     * @instance
     */


    InternalNotification.prototype.data = null;
    /**
     * InternalNotification guest.
     * @member {string} guest
     * @memberof InternalNotification
     * @instance
     */

    InternalNotification.prototype.guest = "";
    /**
     * InternalNotification notification.
     * @member {Notification} notification
     * @memberof InternalNotification
     * @instance
     */

    InternalNotification.prototype.notification = null;
    /**
     * InternalNotification property.
     * @member {string} property
     * @memberof InternalNotification
     * @instance
     */

    InternalNotification.prototype.property = "";
    /**
     * InternalNotification staff.
     * @member {string} staff
     * @memberof InternalNotification
     * @instance
     */

    InternalNotification.prototype.staff = "";
    /**
     * Encodes the specified InternalNotification message. Does not implicitly {@link InternalNotification.verify|verify} messages.
     * @function encode
     * @memberof InternalNotification
     * @static
     * @param {InternalNotification} message InternalNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    InternalNotification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.NotificationData.encode(message.data, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      if (message.guest != null && message.hasOwnProperty("guest")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.guest);
      $root.Notification.encode(message.notification, writer.uint32(
      /* id 2, wireType 2 =*/
      18).fork()).ldelim();
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.property);
      if (message.staff != null && message.hasOwnProperty("staff")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.staff);
      return writer;
    };
    /**
     * Decodes an InternalNotification message from the specified reader or buffer.
     * @function decode
     * @memberof InternalNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InternalNotification} InternalNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    InternalNotification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.InternalNotification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.data = $root.NotificationData.decode(reader, reader.uint32());
            break;

          case 1:
            message.guest = reader.string();
            break;

          case 2:
            message.notification = $root.Notification.decode(reader, reader.uint32());
            break;

          case 3:
            message.property = reader.string();
            break;

          case 4:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("notification")) throw $util.ProtocolError("missing required 'notification'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an InternalNotification message.
     * @function verify
     * @memberof InternalNotification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    InternalNotification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.NotificationData.verify(message.data);
        if (error) return "data." + error;
      }
      if (message.guest != null && message.hasOwnProperty("guest")) if (!$util.isString(message.guest)) return "guest: string expected";
      {
        var error = $root.Notification.verify(message.notification);
        if (error) return "notification." + error;
      }
      if (!$util.isString(message.property)) return "property: string expected";
      if (message.staff != null && message.hasOwnProperty("staff")) if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return InternalNotification;
  }();

  $root.GuestNotification = function () {
    /**
     * Properties of a GuestNotification.
     * @exports IGuestNotification
     * @interface IGuestNotification
     * @property {NotificationData} data GuestNotification data
     * @property {string} guest GuestNotification guest
     * @property {Notification} notification GuestNotification notification
     */

    /**
     * Constructs a new GuestNotification.
     * @exports GuestNotification
     * @classdesc Represents a GuestNotification.
     * @implements IGuestNotification
     * @constructor
     * @param {IGuestNotification=} [properties] Properties to set
     */
    function GuestNotification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestNotification data.
     * @member {NotificationData} data
     * @memberof GuestNotification
     * @instance
     */


    GuestNotification.prototype.data = null;
    /**
     * GuestNotification guest.
     * @member {string} guest
     * @memberof GuestNotification
     * @instance
     */

    GuestNotification.prototype.guest = "";
    /**
     * GuestNotification notification.
     * @member {Notification} notification
     * @memberof GuestNotification
     * @instance
     */

    GuestNotification.prototype.notification = null;
    /**
     * Encodes the specified GuestNotification message. Does not implicitly {@link GuestNotification.verify|verify} messages.
     * @function encode
     * @memberof GuestNotification
     * @static
     * @param {GuestNotification} message GuestNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestNotification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.NotificationData.encode(message.data, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.guest);
      $root.Notification.encode(message.notification, writer.uint32(
      /* id 2, wireType 2 =*/
      18).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestNotification message from the specified reader or buffer.
     * @function decode
     * @memberof GuestNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestNotification} GuestNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestNotification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestNotification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.data = $root.NotificationData.decode(reader, reader.uint32());
            break;

          case 1:
            message.guest = reader.string();
            break;

          case 2:
            message.notification = $root.Notification.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      if (!message.hasOwnProperty("notification")) throw $util.ProtocolError("missing required 'notification'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestNotification message.
     * @function verify
     * @memberof GuestNotification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestNotification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.NotificationData.verify(message.data);
        if (error) return "data." + error;
      }
      if (!$util.isString(message.guest)) return "guest: string expected";
      {
        var error = $root.Notification.verify(message.notification);
        if (error) return "notification." + error;
      }
      return null;
    };

    return GuestNotification;
  }();

  $root.StaffNotification = function () {
    /**
     * Properties of a StaffNotification.
     * @exports IStaffNotification
     * @interface IStaffNotification
     * @property {NotificationData} data StaffNotification data
     * @property {Notification} notification StaffNotification notification
     * @property {string} staff StaffNotification staff
     */

    /**
     * Constructs a new StaffNotification.
     * @exports StaffNotification
     * @classdesc Represents a StaffNotification.
     * @implements IStaffNotification
     * @constructor
     * @param {IStaffNotification=} [properties] Properties to set
     */
    function StaffNotification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * StaffNotification data.
     * @member {NotificationData} data
     * @memberof StaffNotification
     * @instance
     */


    StaffNotification.prototype.data = null;
    /**
     * StaffNotification notification.
     * @member {Notification} notification
     * @memberof StaffNotification
     * @instance
     */

    StaffNotification.prototype.notification = null;
    /**
     * StaffNotification staff.
     * @member {string} staff
     * @memberof StaffNotification
     * @instance
     */

    StaffNotification.prototype.staff = "";
    /**
     * Encodes the specified StaffNotification message. Does not implicitly {@link StaffNotification.verify|verify} messages.
     * @function encode
     * @memberof StaffNotification
     * @static
     * @param {StaffNotification} message StaffNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    StaffNotification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.NotificationData.encode(message.data, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      $root.Notification.encode(message.notification, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.staff);
      return writer;
    };
    /**
     * Decodes a StaffNotification message from the specified reader or buffer.
     * @function decode
     * @memberof StaffNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StaffNotification} StaffNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    StaffNotification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.StaffNotification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.data = $root.NotificationData.decode(reader, reader.uint32());
            break;

          case 1:
            message.notification = $root.Notification.decode(reader, reader.uint32());
            break;

          case 2:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("notification")) throw $util.ProtocolError("missing required 'notification'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a StaffNotification message.
     * @function verify
     * @memberof StaffNotification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    StaffNotification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.NotificationData.verify(message.data);
        if (error) return "data." + error;
      }
      {
        var error = $root.Notification.verify(message.notification);
        if (error) return "notification." + error;
      }
      if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return StaffNotification;
  }();

  $root.PropertyNotification = function () {
    /**
     * Properties of a PropertyNotification.
     * @exports IPropertyNotification
     * @interface IPropertyNotification
     * @property {NotificationData} data PropertyNotification data
     * @property {Notification} notification PropertyNotification notification
     * @property {string} property PropertyNotification property
     */

    /**
     * Constructs a new PropertyNotification.
     * @exports PropertyNotification
     * @classdesc Represents a PropertyNotification.
     * @implements IPropertyNotification
     * @constructor
     * @param {IPropertyNotification=} [properties] Properties to set
     */
    function PropertyNotification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * PropertyNotification data.
     * @member {NotificationData} data
     * @memberof PropertyNotification
     * @instance
     */


    PropertyNotification.prototype.data = null;
    /**
     * PropertyNotification notification.
     * @member {Notification} notification
     * @memberof PropertyNotification
     * @instance
     */

    PropertyNotification.prototype.notification = null;
    /**
     * PropertyNotification property.
     * @member {string} property
     * @memberof PropertyNotification
     * @instance
     */

    PropertyNotification.prototype.property = "";
    /**
     * Encodes the specified PropertyNotification message. Does not implicitly {@link PropertyNotification.verify|verify} messages.
     * @function encode
     * @memberof PropertyNotification
     * @static
     * @param {PropertyNotification} message PropertyNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    PropertyNotification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.NotificationData.encode(message.data, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      $root.Notification.encode(message.notification, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      return writer;
    };
    /**
     * Decodes a PropertyNotification message from the specified reader or buffer.
     * @function decode
     * @memberof PropertyNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PropertyNotification} PropertyNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    PropertyNotification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.PropertyNotification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.data = $root.NotificationData.decode(reader, reader.uint32());
            break;

          case 1:
            message.notification = $root.Notification.decode(reader, reader.uint32());
            break;

          case 2:
            message.property = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("notification")) throw $util.ProtocolError("missing required 'notification'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a PropertyNotification message.
     * @function verify
     * @memberof PropertyNotification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    PropertyNotification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.NotificationData.verify(message.data);
        if (error) return "data." + error;
      }
      {
        var error = $root.Notification.verify(message.notification);
        if (error) return "notification." + error;
      }
      if (!$util.isString(message.property)) return "property: string expected";
      return null;
    };

    return PropertyNotification;
  }();

  $root.DepartmentNotification = function () {
    /**
     * Properties of a DepartmentNotification.
     * @exports IDepartmentNotification
     * @interface IDepartmentNotification
     * @property {NotificationData} data DepartmentNotification data
     * @property {string} department DepartmentNotification department
     * @property {Notification} notification DepartmentNotification notification
     * @property {string} property DepartmentNotification property
     */

    /**
     * Constructs a new DepartmentNotification.
     * @exports DepartmentNotification
     * @classdesc Represents a DepartmentNotification.
     * @implements IDepartmentNotification
     * @constructor
     * @param {IDepartmentNotification=} [properties] Properties to set
     */
    function DepartmentNotification(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * DepartmentNotification data.
     * @member {NotificationData} data
     * @memberof DepartmentNotification
     * @instance
     */


    DepartmentNotification.prototype.data = null;
    /**
     * DepartmentNotification department.
     * @member {string} department
     * @memberof DepartmentNotification
     * @instance
     */

    DepartmentNotification.prototype.department = "";
    /**
     * DepartmentNotification notification.
     * @member {Notification} notification
     * @memberof DepartmentNotification
     * @instance
     */

    DepartmentNotification.prototype.notification = null;
    /**
     * DepartmentNotification property.
     * @member {string} property
     * @memberof DepartmentNotification
     * @instance
     */

    DepartmentNotification.prototype.property = "";
    /**
     * Encodes the specified DepartmentNotification message. Does not implicitly {@link DepartmentNotification.verify|verify} messages.
     * @function encode
     * @memberof DepartmentNotification
     * @static
     * @param {DepartmentNotification} message DepartmentNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    DepartmentNotification.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.NotificationData.encode(message.data, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.department);
      $root.Notification.encode(message.notification, writer.uint32(
      /* id 2, wireType 2 =*/
      18).fork()).ldelim();
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.property);
      return writer;
    };
    /**
     * Decodes a DepartmentNotification message from the specified reader or buffer.
     * @function decode
     * @memberof DepartmentNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DepartmentNotification} DepartmentNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    DepartmentNotification.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.DepartmentNotification();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.data = $root.NotificationData.decode(reader, reader.uint32());
            break;

          case 1:
            message.department = reader.string();
            break;

          case 2:
            message.notification = $root.Notification.decode(reader, reader.uint32());
            break;

          case 3:
            message.property = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("department")) throw $util.ProtocolError("missing required 'department'", {
        instance: message
      });
      if (!message.hasOwnProperty("notification")) throw $util.ProtocolError("missing required 'notification'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a DepartmentNotification message.
     * @function verify
     * @memberof DepartmentNotification
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    DepartmentNotification.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.NotificationData.verify(message.data);
        if (error) return "data." + error;
      }
      if (!$util.isString(message.department)) return "department: string expected";
      {
        var error = $root.Notification.verify(message.notification);
        if (error) return "notification." + error;
      }
      if (!$util.isString(message.property)) return "property: string expected";
      return null;
    };

    return DepartmentNotification;
  }();

  $root.ServiceRequest = function () {
    /**
     * Properties of a ServiceRequest.
     * @exports IServiceRequest
     * @interface IServiceRequest
     * @property {string|null} [serreqid] ServiceRequest serreqid
     * @property {string|null} [serreqtypid] ServiceRequest serreqtypid
     * @property {string} tid ServiceRequest tid
     * @property {string} pid ServiceRequest pid
     * @property {string} gid ServiceRequest gid
     * @property {string} bookingNo ServiceRequest bookingNo
     * @property {LaundryData|null} [laundry] ServiceRequest laundry
     * @property {InRoomAmenityData|null} [inRoomAmenity] ServiceRequest inRoomAmenity
     * @property {ReportIssueData|null} [reportIssue] ServiceRequest reportIssue
     * @property {CheckInData|null} [checkIn] ServiceRequest checkIn
     * @property {CheckOutData|null} [checkOut] ServiceRequest checkOut
     * @property {RestaurantReservationData|null} [restaurantReservation] ServiceRequest restaurantReservation
     * @property {CabBookingData|null} [cabBooking] ServiceRequest cabBooking
     * @property {AirportPickupData|null} [airportPickup] ServiceRequest airportPickup
     * @property {RoomBookingData|null} [roomBooking] ServiceRequest roomBooking
     * @property {BanquetInquiryData|null} [banquetInquiry] ServiceRequest banquetInquiry
     * @property {RoomCleaningData|null} [roomCleaning] ServiceRequest roomCleaning
     * @property {LinenChangeData|null} [linenChange] ServiceRequest linenChange
     * @property {ServiceRequestData} data ServiceRequest data
     * @property {boolean} isClosed ServiceRequest isClosed
     * @property {string} status ServiceRequest status
     * @property {string} type ServiceRequest type
     * @property {string|null} [roomNo] ServiceRequest roomNo
     * @property {string|null} [department] ServiceRequest department
     * @property {number|Long|null} [guestSLATime] ServiceRequest guestSLATime
     * @property {number|Long|null} [l1Time] ServiceRequest l1Time
     * @property {Array.<AssignedStaffInfo>|null} [staffs] ServiceRequest staffs
     * @property {number|Long|null} [createdAt] ServiceRequest createdAt
     * @property {string|null} [createdBy] ServiceRequest createdBy
     * @property {number|Long|null} [modifiedAt] ServiceRequest modifiedAt
     * @property {string|null} [modifiedBy] ServiceRequest modifiedBy
     */

    /**
     * Constructs a new ServiceRequest.
     * @exports ServiceRequest
     * @classdesc Represents a ServiceRequest.
     * @implements IServiceRequest
     * @constructor
     * @param {IServiceRequest=} [properties] Properties to set
     */
    function ServiceRequest(properties) {
      this.staffs = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ServiceRequest serreqid.
     * @member {string} serreqid
     * @memberof ServiceRequest
     * @instance
     */


    ServiceRequest.prototype.serreqid = "";
    /**
     * ServiceRequest serreqtypid.
     * @member {string} serreqtypid
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.serreqtypid = "";
    /**
     * ServiceRequest tid.
     * @member {string} tid
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.tid = "";
    /**
     * ServiceRequest pid.
     * @member {string} pid
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.pid = "";
    /**
     * ServiceRequest gid.
     * @member {string} gid
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.gid = "";
    /**
     * ServiceRequest bookingNo.
     * @member {string} bookingNo
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.bookingNo = "";
    /**
     * ServiceRequest laundry.
     * @member {LaundryData|null|undefined} laundry
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.laundry = null;
    /**
     * ServiceRequest inRoomAmenity.
     * @member {InRoomAmenityData|null|undefined} inRoomAmenity
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.inRoomAmenity = null;
    /**
     * ServiceRequest reportIssue.
     * @member {ReportIssueData|null|undefined} reportIssue
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.reportIssue = null;
    /**
     * ServiceRequest checkIn.
     * @member {CheckInData|null|undefined} checkIn
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.checkIn = null;
    /**
     * ServiceRequest checkOut.
     * @member {CheckOutData|null|undefined} checkOut
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.checkOut = null;
    /**
     * ServiceRequest restaurantReservation.
     * @member {RestaurantReservationData|null|undefined} restaurantReservation
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.restaurantReservation = null;
    /**
     * ServiceRequest cabBooking.
     * @member {CabBookingData|null|undefined} cabBooking
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.cabBooking = null;
    /**
     * ServiceRequest airportPickup.
     * @member {AirportPickupData|null|undefined} airportPickup
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.airportPickup = null;
    /**
     * ServiceRequest roomBooking.
     * @member {RoomBookingData|null|undefined} roomBooking
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.roomBooking = null;
    /**
     * ServiceRequest banquetInquiry.
     * @member {BanquetInquiryData|null|undefined} banquetInquiry
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.banquetInquiry = null;
    /**
     * ServiceRequest roomCleaning.
     * @member {RoomCleaningData|null|undefined} roomCleaning
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.roomCleaning = null;
    /**
     * ServiceRequest linenChange.
     * @member {LinenChangeData|null|undefined} linenChange
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.linenChange = null;
    /**
     * ServiceRequest data.
     * @member {ServiceRequestData} data
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.data = null;
    /**
     * ServiceRequest isClosed.
     * @member {boolean} isClosed
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.isClosed = false;
    /**
     * ServiceRequest status.
     * @member {string} status
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.status = "";
    /**
     * ServiceRequest type.
     * @member {string} type
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.type = "";
    /**
     * ServiceRequest roomNo.
     * @member {string} roomNo
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.roomNo = "";
    /**
     * ServiceRequest department.
     * @member {string} department
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.department = "";
    /**
     * ServiceRequest guestSLATime.
     * @member {number|Long} guestSLATime
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.guestSLATime = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequest l1Time.
     * @member {number|Long} l1Time
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.l1Time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequest staffs.
     * @member {Array.<AssignedStaffInfo>} staffs
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.staffs = $util.emptyArray;
    /**
     * ServiceRequest createdAt.
     * @member {number|Long} createdAt
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequest createdBy.
     * @member {string} createdBy
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.createdBy = "";
    /**
     * ServiceRequest modifiedAt.
     * @member {number|Long} modifiedAt
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.modifiedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequest modifiedBy.
     * @member {string} modifiedBy
     * @memberof ServiceRequest
     * @instance
     */

    ServiceRequest.prototype.modifiedBy = ""; // OneOf field names bound to virtual getters and setters

    var $oneOfFields;
    /**
     * ServiceRequest requestData.
     * @member {"laundry"|"inRoomAmenity"|"reportIssue"|"checkIn"|"checkOut"|"restaurantReservation"|"cabBooking"|"airportPickup"|"roomBooking"|"banquetInquiry"|"roomCleaning"|"linenChange"|undefined} requestData
     * @memberof ServiceRequest
     * @instance
     */

    Object.defineProperty(ServiceRequest.prototype, "requestData", {
      get: $util.oneOfGetter($oneOfFields = ["laundry", "inRoomAmenity", "reportIssue", "checkIn", "checkOut", "restaurantReservation", "cabBooking", "airportPickup", "roomBooking", "banquetInquiry", "roomCleaning", "linenChange"]),
      set: $util.oneOfSetter($oneOfFields)
    });
    /**
     * Encodes the specified ServiceRequest message. Does not implicitly {@link ServiceRequest.verify|verify} messages.
     * @function encode
     * @memberof ServiceRequest
     * @static
     * @param {ServiceRequest} message ServiceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ServiceRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.serreqid != null && message.hasOwnProperty("serreqid")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.serreqid);
      if (message.serreqtypid != null && message.hasOwnProperty("serreqtypid")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.serreqtypid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.tid);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.pid);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.gid);
      writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.bookingNo);
      $root.ServiceRequestData.encode(message.data, writer.uint32(
      /* id 6, wireType 2 =*/
      50).fork()).ldelim();
      writer.uint32(
      /* id 7, wireType 0 =*/
      56).bool(message.isClosed);
      writer.uint32(
      /* id 8, wireType 2 =*/
      66).string(message.status);
      writer.uint32(
      /* id 9, wireType 2 =*/
      74).string(message.type);
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) writer.uint32(
      /* id 10, wireType 2 =*/
      82).string(message.roomNo);
      if (message.department != null && message.hasOwnProperty("department")) writer.uint32(
      /* id 11, wireType 2 =*/
      90).string(message.department);
      if (message.guestSLATime != null && message.hasOwnProperty("guestSLATime")) writer.uint32(
      /* id 12, wireType 0 =*/
      96).uint64(message.guestSLATime);
      if (message.l1Time != null && message.hasOwnProperty("l1Time")) writer.uint32(
      /* id 13, wireType 0 =*/
      104).uint64(message.l1Time);
      if (message.staffs != null && message.staffs.length) for (var i = 0; i < message.staffs.length; ++i) {
        $root.AssignedStaffInfo.encode(message.staffs[i], writer.uint32(
        /* id 14, wireType 2 =*/
        114).fork()).ldelim();
      }
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) writer.uint32(
      /* id 15, wireType 0 =*/
      120).uint64(message.createdAt);
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) writer.uint32(
      /* id 16, wireType 2 =*/
      130).string(message.createdBy);
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) writer.uint32(
      /* id 17, wireType 0 =*/
      136).uint64(message.modifiedAt);
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) writer.uint32(
      /* id 18, wireType 2 =*/
      146).string(message.modifiedBy);
      if (message.laundry != null && message.hasOwnProperty("laundry")) $root.LaundryData.encode(message.laundry, writer.uint32(
      /* id 19, wireType 2 =*/
      154).fork()).ldelim();
      if (message.inRoomAmenity != null && message.hasOwnProperty("inRoomAmenity")) $root.InRoomAmenityData.encode(message.inRoomAmenity, writer.uint32(
      /* id 20, wireType 2 =*/
      162).fork()).ldelim();
      if (message.reportIssue != null && message.hasOwnProperty("reportIssue")) $root.ReportIssueData.encode(message.reportIssue, writer.uint32(
      /* id 21, wireType 2 =*/
      170).fork()).ldelim();
      if (message.checkIn != null && message.hasOwnProperty("checkIn")) $root.CheckInData.encode(message.checkIn, writer.uint32(
      /* id 22, wireType 2 =*/
      178).fork()).ldelim();
      if (message.checkOut != null && message.hasOwnProperty("checkOut")) $root.CheckOutData.encode(message.checkOut, writer.uint32(
      /* id 23, wireType 2 =*/
      186).fork()).ldelim();
      if (message.restaurantReservation != null && message.hasOwnProperty("restaurantReservation")) $root.RestaurantReservationData.encode(message.restaurantReservation, writer.uint32(
      /* id 24, wireType 2 =*/
      194).fork()).ldelim();
      if (message.cabBooking != null && message.hasOwnProperty("cabBooking")) $root.CabBookingData.encode(message.cabBooking, writer.uint32(
      /* id 25, wireType 2 =*/
      202).fork()).ldelim();
      if (message.airportPickup != null && message.hasOwnProperty("airportPickup")) $root.AirportPickupData.encode(message.airportPickup, writer.uint32(
      /* id 26, wireType 2 =*/
      210).fork()).ldelim();
      if (message.roomBooking != null && message.hasOwnProperty("roomBooking")) $root.RoomBookingData.encode(message.roomBooking, writer.uint32(
      /* id 27, wireType 2 =*/
      218).fork()).ldelim();
      if (message.banquetInquiry != null && message.hasOwnProperty("banquetInquiry")) $root.BanquetInquiryData.encode(message.banquetInquiry, writer.uint32(
      /* id 28, wireType 2 =*/
      226).fork()).ldelim();
      if (message.roomCleaning != null && message.hasOwnProperty("roomCleaning")) $root.RoomCleaningData.encode(message.roomCleaning, writer.uint32(
      /* id 29, wireType 2 =*/
      234).fork()).ldelim();
      if (message.linenChange != null && message.hasOwnProperty("linenChange")) $root.LinenChangeData.encode(message.linenChange, writer.uint32(
      /* id 30, wireType 2 =*/
      242).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a ServiceRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ServiceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ServiceRequest} ServiceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ServiceRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ServiceRequest();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.serreqid = reader.string();
            break;

          case 1:
            message.serreqtypid = reader.string();
            break;

          case 2:
            message.tid = reader.string();
            break;

          case 3:
            message.pid = reader.string();
            break;

          case 4:
            message.gid = reader.string();
            break;

          case 5:
            message.bookingNo = reader.string();
            break;

          case 19:
            message.laundry = $root.LaundryData.decode(reader, reader.uint32());
            break;

          case 20:
            message.inRoomAmenity = $root.InRoomAmenityData.decode(reader, reader.uint32());
            break;

          case 21:
            message.reportIssue = $root.ReportIssueData.decode(reader, reader.uint32());
            break;

          case 22:
            message.checkIn = $root.CheckInData.decode(reader, reader.uint32());
            break;

          case 23:
            message.checkOut = $root.CheckOutData.decode(reader, reader.uint32());
            break;

          case 24:
            message.restaurantReservation = $root.RestaurantReservationData.decode(reader, reader.uint32());
            break;

          case 25:
            message.cabBooking = $root.CabBookingData.decode(reader, reader.uint32());
            break;

          case 26:
            message.airportPickup = $root.AirportPickupData.decode(reader, reader.uint32());
            break;

          case 27:
            message.roomBooking = $root.RoomBookingData.decode(reader, reader.uint32());
            break;

          case 28:
            message.banquetInquiry = $root.BanquetInquiryData.decode(reader, reader.uint32());
            break;

          case 29:
            message.roomCleaning = $root.RoomCleaningData.decode(reader, reader.uint32());
            break;

          case 30:
            message.linenChange = $root.LinenChangeData.decode(reader, reader.uint32());
            break;

          case 6:
            message.data = $root.ServiceRequestData.decode(reader, reader.uint32());
            break;

          case 7:
            message.isClosed = reader.bool();
            break;

          case 8:
            message.status = reader.string();
            break;

          case 9:
            message.type = reader.string();
            break;

          case 10:
            message.roomNo = reader.string();
            break;

          case 11:
            message.department = reader.string();
            break;

          case 12:
            message.guestSLATime = reader.uint64();
            break;

          case 13:
            message.l1Time = reader.uint64();
            break;

          case 14:
            if (!(message.staffs && message.staffs.length)) message.staffs = [];
            message.staffs.push($root.AssignedStaffInfo.decode(reader, reader.uint32()));
            break;

          case 15:
            message.createdAt = reader.uint64();
            break;

          case 16:
            message.createdBy = reader.string();
            break;

          case 17:
            message.modifiedAt = reader.uint64();
            break;

          case 18:
            message.modifiedBy = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("tid")) throw $util.ProtocolError("missing required 'tid'", {
        instance: message
      });
      if (!message.hasOwnProperty("pid")) throw $util.ProtocolError("missing required 'pid'", {
        instance: message
      });
      if (!message.hasOwnProperty("gid")) throw $util.ProtocolError("missing required 'gid'", {
        instance: message
      });
      if (!message.hasOwnProperty("bookingNo")) throw $util.ProtocolError("missing required 'bookingNo'", {
        instance: message
      });
      if (!message.hasOwnProperty("data")) throw $util.ProtocolError("missing required 'data'", {
        instance: message
      });
      if (!message.hasOwnProperty("isClosed")) throw $util.ProtocolError("missing required 'isClosed'", {
        instance: message
      });
      if (!message.hasOwnProperty("status")) throw $util.ProtocolError("missing required 'status'", {
        instance: message
      });
      if (!message.hasOwnProperty("type")) throw $util.ProtocolError("missing required 'type'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ServiceRequest message.
     * @function verify
     * @memberof ServiceRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ServiceRequest.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      var properties = {};
      if (message.serreqid != null && message.hasOwnProperty("serreqid")) if (!$util.isString(message.serreqid)) return "serreqid: string expected";
      if (message.serreqtypid != null && message.hasOwnProperty("serreqtypid")) if (!$util.isString(message.serreqtypid)) return "serreqtypid: string expected";
      if (!$util.isString(message.tid)) return "tid: string expected";
      if (!$util.isString(message.pid)) return "pid: string expected";
      if (!$util.isString(message.gid)) return "gid: string expected";
      if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";

      if (message.laundry != null && message.hasOwnProperty("laundry")) {
        properties.requestData = 1;
        {
          var error = $root.LaundryData.verify(message.laundry);
          if (error) return "laundry." + error;
        }
      }

      if (message.inRoomAmenity != null && message.hasOwnProperty("inRoomAmenity")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.InRoomAmenityData.verify(message.inRoomAmenity);
          if (error) return "inRoomAmenity." + error;
        }
      }

      if (message.reportIssue != null && message.hasOwnProperty("reportIssue")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.ReportIssueData.verify(message.reportIssue);
          if (error) return "reportIssue." + error;
        }
      }

      if (message.checkIn != null && message.hasOwnProperty("checkIn")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.CheckInData.verify(message.checkIn);
          if (error) return "checkIn." + error;
        }
      }

      if (message.checkOut != null && message.hasOwnProperty("checkOut")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.CheckOutData.verify(message.checkOut);
          if (error) return "checkOut." + error;
        }
      }

      if (message.restaurantReservation != null && message.hasOwnProperty("restaurantReservation")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.RestaurantReservationData.verify(message.restaurantReservation);
          if (error) return "restaurantReservation." + error;
        }
      }

      if (message.cabBooking != null && message.hasOwnProperty("cabBooking")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.CabBookingData.verify(message.cabBooking);
          if (error) return "cabBooking." + error;
        }
      }

      if (message.airportPickup != null && message.hasOwnProperty("airportPickup")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.AirportPickupData.verify(message.airportPickup);
          if (error) return "airportPickup." + error;
        }
      }

      if (message.roomBooking != null && message.hasOwnProperty("roomBooking")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.RoomBookingData.verify(message.roomBooking);
          if (error) return "roomBooking." + error;
        }
      }

      if (message.banquetInquiry != null && message.hasOwnProperty("banquetInquiry")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.BanquetInquiryData.verify(message.banquetInquiry);
          if (error) return "banquetInquiry." + error;
        }
      }

      if (message.roomCleaning != null && message.hasOwnProperty("roomCleaning")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.RoomCleaningData.verify(message.roomCleaning);
          if (error) return "roomCleaning." + error;
        }
      }

      if (message.linenChange != null && message.hasOwnProperty("linenChange")) {
        if (properties.requestData === 1) return "requestData: multiple values";
        properties.requestData = 1;
        {
          var error = $root.LinenChangeData.verify(message.linenChange);
          if (error) return "linenChange." + error;
        }
      }

      {
        var error = $root.ServiceRequestData.verify(message.data);
        if (error) return "data." + error;
      }
      if (typeof message.isClosed !== "boolean") return "isClosed: boolean expected";
      if (!$util.isString(message.status)) return "status: string expected";
      if (!$util.isString(message.type)) return "type: string expected";
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) if (!$util.isString(message.roomNo)) return "roomNo: string expected";
      if (message.department != null && message.hasOwnProperty("department")) if (!$util.isString(message.department)) return "department: string expected";
      if (message.guestSLATime != null && message.hasOwnProperty("guestSLATime")) if (!$util.isInteger(message.guestSLATime) && !(message.guestSLATime && $util.isInteger(message.guestSLATime.low) && $util.isInteger(message.guestSLATime.high))) return "guestSLATime: integer|Long expected";
      if (message.l1Time != null && message.hasOwnProperty("l1Time")) if (!$util.isInteger(message.l1Time) && !(message.l1Time && $util.isInteger(message.l1Time.low) && $util.isInteger(message.l1Time.high))) return "l1Time: integer|Long expected";

      if (message.staffs != null && message.hasOwnProperty("staffs")) {
        if (!Array.isArray(message.staffs)) return "staffs: array expected";

        for (var i = 0; i < message.staffs.length; ++i) {
          var error = $root.AssignedStaffInfo.verify(message.staffs[i]);
          if (error) return "staffs." + error;
        }
      }

      if (message.createdAt != null && message.hasOwnProperty("createdAt")) if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high))) return "createdAt: integer|Long expected";
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) if (!$util.isString(message.createdBy)) return "createdBy: string expected";
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) if (!$util.isInteger(message.modifiedAt) && !(message.modifiedAt && $util.isInteger(message.modifiedAt.low) && $util.isInteger(message.modifiedAt.high))) return "modifiedAt: integer|Long expected";
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) if (!$util.isString(message.modifiedBy)) return "modifiedBy: string expected";
      return null;
    };

    return ServiceRequest;
  }();

  $root.LaundryData = function () {
    /**
     * Properties of a LaundryData.
     * @exports ILaundryData
     * @interface ILaundryData
     * @property {number} dateTime LaundryData dateTime
     * @property {string} type LaundryData type
     * @property {string|null} [specialInstruction] LaundryData specialInstruction
     */

    /**
     * Constructs a new LaundryData.
     * @exports LaundryData
     * @classdesc Represents a LaundryData.
     * @implements ILaundryData
     * @constructor
     * @param {ILaundryData=} [properties] Properties to set
     */
    function LaundryData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * LaundryData dateTime.
     * @member {number} dateTime
     * @memberof LaundryData
     * @instance
     */


    LaundryData.prototype.dateTime = 0;
    /**
     * LaundryData type.
     * @member {string} type
     * @memberof LaundryData
     * @instance
     */

    LaundryData.prototype.type = "";
    /**
     * LaundryData specialInstruction.
     * @member {string} specialInstruction
     * @memberof LaundryData
     * @instance
     */

    LaundryData.prototype.specialInstruction = "";
    /**
     * Encodes the specified LaundryData message. Does not implicitly {@link LaundryData.verify|verify} messages.
     * @function encode
     * @memberof LaundryData
     * @static
     * @param {LaundryData} message LaundryData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    LaundryData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 5 =*/
      5).float(message.dateTime);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.type);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a LaundryData message from the specified reader or buffer.
     * @function decode
     * @memberof LaundryData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LaundryData} LaundryData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    LaundryData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.LaundryData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.dateTime = reader.float();
            break;

          case 1:
            message.type = reader.string();
            break;

          case 2:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("dateTime")) throw $util.ProtocolError("missing required 'dateTime'", {
        instance: message
      });
      if (!message.hasOwnProperty("type")) throw $util.ProtocolError("missing required 'type'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a LaundryData message.
     * @function verify
     * @memberof LaundryData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    LaundryData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (!$util.isString(message.type)) return "type: string expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return LaundryData;
  }();

  $root.InRoomAmenityData = function () {
    /**
     * Properties of an InRoomAmenityData.
     * @exports IInRoomAmenityData
     * @interface IInRoomAmenityData
     * @property {Array.<Amenity>|null} [amenities] InRoomAmenityData amenities
     * @property {string|null} [specialInstruction] InRoomAmenityData specialInstruction
     */

    /**
     * Constructs a new InRoomAmenityData.
     * @exports InRoomAmenityData
     * @classdesc Represents an InRoomAmenityData.
     * @implements IInRoomAmenityData
     * @constructor
     * @param {IInRoomAmenityData=} [properties] Properties to set
     */
    function InRoomAmenityData(properties) {
      this.amenities = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * InRoomAmenityData amenities.
     * @member {Array.<Amenity>} amenities
     * @memberof InRoomAmenityData
     * @instance
     */


    InRoomAmenityData.prototype.amenities = $util.emptyArray;
    /**
     * InRoomAmenityData specialInstruction.
     * @member {string} specialInstruction
     * @memberof InRoomAmenityData
     * @instance
     */

    InRoomAmenityData.prototype.specialInstruction = "";
    /**
     * Encodes the specified InRoomAmenityData message. Does not implicitly {@link InRoomAmenityData.verify|verify} messages.
     * @function encode
     * @memberof InRoomAmenityData
     * @static
     * @param {InRoomAmenityData} message InRoomAmenityData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    InRoomAmenityData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.amenities != null && message.amenities.length) for (var i = 0; i < message.amenities.length; ++i) {
        $root.Amenity.encode(message.amenities[i], writer.uint32(
        /* id 0, wireType 2 =*/
        2).fork()).ldelim();
      }
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes an InRoomAmenityData message from the specified reader or buffer.
     * @function decode
     * @memberof InRoomAmenityData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InRoomAmenityData} InRoomAmenityData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    InRoomAmenityData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.InRoomAmenityData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            if (!(message.amenities && message.amenities.length)) message.amenities = [];
            message.amenities.push($root.Amenity.decode(reader, reader.uint32()));
            break;

          case 1:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies an InRoomAmenityData message.
     * @function verify
     * @memberof InRoomAmenityData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    InRoomAmenityData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";

      if (message.amenities != null && message.hasOwnProperty("amenities")) {
        if (!Array.isArray(message.amenities)) return "amenities: array expected";

        for (var i = 0; i < message.amenities.length; ++i) {
          var error = $root.Amenity.verify(message.amenities[i]);
          if (error) return "amenities." + error;
        }
      }

      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return InRoomAmenityData;
  }();

  $root.ReportIssueData = function () {
    /**
     * Properties of a ReportIssueData.
     * @exports IReportIssueData
     * @interface IReportIssueData
     * @property {string|null} [issue] ReportIssueData issue
     * @property {Array.<string>|null} [images] ReportIssueData images
     * @property {string|null} [specialInstruction] ReportIssueData specialInstruction
     */

    /**
     * Constructs a new ReportIssueData.
     * @exports ReportIssueData
     * @classdesc Represents a ReportIssueData.
     * @implements IReportIssueData
     * @constructor
     * @param {IReportIssueData=} [properties] Properties to set
     */
    function ReportIssueData(properties) {
      this.images = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ReportIssueData issue.
     * @member {string} issue
     * @memberof ReportIssueData
     * @instance
     */


    ReportIssueData.prototype.issue = "";
    /**
     * ReportIssueData images.
     * @member {Array.<string>} images
     * @memberof ReportIssueData
     * @instance
     */

    ReportIssueData.prototype.images = $util.emptyArray;
    /**
     * ReportIssueData specialInstruction.
     * @member {string} specialInstruction
     * @memberof ReportIssueData
     * @instance
     */

    ReportIssueData.prototype.specialInstruction = "";
    /**
     * Encodes the specified ReportIssueData message. Does not implicitly {@link ReportIssueData.verify|verify} messages.
     * @function encode
     * @memberof ReportIssueData
     * @static
     * @param {ReportIssueData} message ReportIssueData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ReportIssueData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.issue != null && message.hasOwnProperty("issue")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.issue);
      if (message.images != null && message.images.length) for (var i = 0; i < message.images.length; ++i) {
        writer.uint32(
        /* id 1, wireType 2 =*/
        10).string(message.images[i]);
      }
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a ReportIssueData message from the specified reader or buffer.
     * @function decode
     * @memberof ReportIssueData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ReportIssueData} ReportIssueData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ReportIssueData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ReportIssueData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.issue = reader.string();
            break;

          case 1:
            if (!(message.images && message.images.length)) message.images = [];
            message.images.push(reader.string());
            break;

          case 2:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a ReportIssueData message.
     * @function verify
     * @memberof ReportIssueData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ReportIssueData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.issue != null && message.hasOwnProperty("issue")) if (!$util.isString(message.issue)) return "issue: string expected";

      if (message.images != null && message.hasOwnProperty("images")) {
        if (!Array.isArray(message.images)) return "images: array expected";

        for (var i = 0; i < message.images.length; ++i) {
          if (!$util.isString(message.images[i])) return "images: string[] expected";
        }
      }

      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return ReportIssueData;
  }();

  $root.CheckInData = function () {
    /**
     * Properties of a CheckInData.
     * @exports ICheckInData
     * @interface ICheckInData
     * @property {string} chkinid CheckInData chkinid
     */

    /**
     * Constructs a new CheckInData.
     * @exports CheckInData
     * @classdesc Represents a CheckInData.
     * @implements ICheckInData
     * @constructor
     * @param {ICheckInData=} [properties] Properties to set
     */
    function CheckInData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * CheckInData chkinid.
     * @member {string} chkinid
     * @memberof CheckInData
     * @instance
     */


    CheckInData.prototype.chkinid = "";
    /**
     * Encodes the specified CheckInData message. Does not implicitly {@link CheckInData.verify|verify} messages.
     * @function encode
     * @memberof CheckInData
     * @static
     * @param {CheckInData} message CheckInData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    CheckInData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.chkinid);
      return writer;
    };
    /**
     * Decodes a CheckInData message from the specified reader or buffer.
     * @function decode
     * @memberof CheckInData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckInData} CheckInData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    CheckInData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.CheckInData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.chkinid = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("chkinid")) throw $util.ProtocolError("missing required 'chkinid'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a CheckInData message.
     * @function verify
     * @memberof CheckInData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    CheckInData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.chkinid)) return "chkinid: string expected";
      return null;
    };

    return CheckInData;
  }();

  $root.CheckOutData = function () {
    /**
     * Properties of a CheckOutData.
     * @exports ICheckOutData
     * @interface ICheckOutData
     * @property {number} dateTime CheckOutData dateTime
     * @property {boolean|null} [needBaggageAssistance] CheckOutData needBaggageAssistance
     * @property {boolean|null} [needAirportDrop] CheckOutData needAirportDrop
     * @property {boolean|null} [needWakeupCall] CheckOutData needWakeupCall
     * @property {number|null} [wakeupCallTime] CheckOutData wakeupCallTime
     * @property {string|null} [specialInstruction] CheckOutData specialInstruction
     */

    /**
     * Constructs a new CheckOutData.
     * @exports CheckOutData
     * @classdesc Represents a CheckOutData.
     * @implements ICheckOutData
     * @constructor
     * @param {ICheckOutData=} [properties] Properties to set
     */
    function CheckOutData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * CheckOutData dateTime.
     * @member {number} dateTime
     * @memberof CheckOutData
     * @instance
     */


    CheckOutData.prototype.dateTime = 0;
    /**
     * CheckOutData needBaggageAssistance.
     * @member {boolean} needBaggageAssistance
     * @memberof CheckOutData
     * @instance
     */

    CheckOutData.prototype.needBaggageAssistance = false;
    /**
     * CheckOutData needAirportDrop.
     * @member {boolean} needAirportDrop
     * @memberof CheckOutData
     * @instance
     */

    CheckOutData.prototype.needAirportDrop = false;
    /**
     * CheckOutData needWakeupCall.
     * @member {boolean} needWakeupCall
     * @memberof CheckOutData
     * @instance
     */

    CheckOutData.prototype.needWakeupCall = false;
    /**
     * CheckOutData wakeupCallTime.
     * @member {number} wakeupCallTime
     * @memberof CheckOutData
     * @instance
     */

    CheckOutData.prototype.wakeupCallTime = 0;
    /**
     * CheckOutData specialInstruction.
     * @member {string} specialInstruction
     * @memberof CheckOutData
     * @instance
     */

    CheckOutData.prototype.specialInstruction = "";
    /**
     * Encodes the specified CheckOutData message. Does not implicitly {@link CheckOutData.verify|verify} messages.
     * @function encode
     * @memberof CheckOutData
     * @static
     * @param {CheckOutData} message CheckOutData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    CheckOutData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 5 =*/
      5).float(message.dateTime);
      if (message.needBaggageAssistance != null && message.hasOwnProperty("needBaggageAssistance")) writer.uint32(
      /* id 1, wireType 0 =*/
      8).bool(message.needBaggageAssistance);
      if (message.needAirportDrop != null && message.hasOwnProperty("needAirportDrop")) writer.uint32(
      /* id 2, wireType 0 =*/
      16).bool(message.needAirportDrop);
      if (message.needWakeupCall != null && message.hasOwnProperty("needWakeupCall")) writer.uint32(
      /* id 3, wireType 0 =*/
      24).bool(message.needWakeupCall);
      if (message.wakeupCallTime != null && message.hasOwnProperty("wakeupCallTime")) writer.uint32(
      /* id 4, wireType 5 =*/
      37).float(message.wakeupCallTime);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a CheckOutData message from the specified reader or buffer.
     * @function decode
     * @memberof CheckOutData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckOutData} CheckOutData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    CheckOutData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.CheckOutData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.dateTime = reader.float();
            break;

          case 1:
            message.needBaggageAssistance = reader.bool();
            break;

          case 2:
            message.needAirportDrop = reader.bool();
            break;

          case 3:
            message.needWakeupCall = reader.bool();
            break;

          case 4:
            message.wakeupCallTime = reader.float();
            break;

          case 5:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("dateTime")) throw $util.ProtocolError("missing required 'dateTime'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a CheckOutData message.
     * @function verify
     * @memberof CheckOutData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    CheckOutData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (message.needBaggageAssistance != null && message.hasOwnProperty("needBaggageAssistance")) if (typeof message.needBaggageAssistance !== "boolean") return "needBaggageAssistance: boolean expected";
      if (message.needAirportDrop != null && message.hasOwnProperty("needAirportDrop")) if (typeof message.needAirportDrop !== "boolean") return "needAirportDrop: boolean expected";
      if (message.needWakeupCall != null && message.hasOwnProperty("needWakeupCall")) if (typeof message.needWakeupCall !== "boolean") return "needWakeupCall: boolean expected";
      if (message.wakeupCallTime != null && message.hasOwnProperty("wakeupCallTime")) if (typeof message.wakeupCallTime !== "number") return "wakeupCallTime: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return CheckOutData;
  }();

  $root.RestaurantReservationData = function () {
    /**
     * Properties of a RestaurantReservationData.
     * @exports IRestaurantReservationData
     * @interface IRestaurantReservationData
     * @property {string} restaurant RestaurantReservationData restaurant
     * @property {string} name RestaurantReservationData name
     * @property {string} mobile RestaurantReservationData mobile
     * @property {number} dateTime RestaurantReservationData dateTime
     * @property {number} noOfPeople RestaurantReservationData noOfPeople
     * @property {string|null} [specialInstruction] RestaurantReservationData specialInstruction
     */

    /**
     * Constructs a new RestaurantReservationData.
     * @exports RestaurantReservationData
     * @classdesc Represents a RestaurantReservationData.
     * @implements IRestaurantReservationData
     * @constructor
     * @param {IRestaurantReservationData=} [properties] Properties to set
     */
    function RestaurantReservationData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * RestaurantReservationData restaurant.
     * @member {string} restaurant
     * @memberof RestaurantReservationData
     * @instance
     */


    RestaurantReservationData.prototype.restaurant = "";
    /**
     * RestaurantReservationData name.
     * @member {string} name
     * @memberof RestaurantReservationData
     * @instance
     */

    RestaurantReservationData.prototype.name = "";
    /**
     * RestaurantReservationData mobile.
     * @member {string} mobile
     * @memberof RestaurantReservationData
     * @instance
     */

    RestaurantReservationData.prototype.mobile = "";
    /**
     * RestaurantReservationData dateTime.
     * @member {number} dateTime
     * @memberof RestaurantReservationData
     * @instance
     */

    RestaurantReservationData.prototype.dateTime = 0;
    /**
     * RestaurantReservationData noOfPeople.
     * @member {number} noOfPeople
     * @memberof RestaurantReservationData
     * @instance
     */

    RestaurantReservationData.prototype.noOfPeople = 0;
    /**
     * RestaurantReservationData specialInstruction.
     * @member {string} specialInstruction
     * @memberof RestaurantReservationData
     * @instance
     */

    RestaurantReservationData.prototype.specialInstruction = "";
    /**
     * Encodes the specified RestaurantReservationData message. Does not implicitly {@link RestaurantReservationData.verify|verify} messages.
     * @function encode
     * @memberof RestaurantReservationData
     * @static
     * @param {RestaurantReservationData} message RestaurantReservationData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    RestaurantReservationData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.restaurant);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.name);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.mobile);
      writer.uint32(
      /* id 3, wireType 5 =*/
      29).float(message.dateTime);
      writer.uint32(
      /* id 4, wireType 5 =*/
      37).float(message.noOfPeople);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a RestaurantReservationData message from the specified reader or buffer.
     * @function decode
     * @memberof RestaurantReservationData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RestaurantReservationData} RestaurantReservationData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    RestaurantReservationData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.RestaurantReservationData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.restaurant = reader.string();
            break;

          case 1:
            message.name = reader.string();
            break;

          case 2:
            message.mobile = reader.string();
            break;

          case 3:
            message.dateTime = reader.float();
            break;

          case 4:
            message.noOfPeople = reader.float();
            break;

          case 5:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("restaurant")) throw $util.ProtocolError("missing required 'restaurant'", {
        instance: message
      });
      if (!message.hasOwnProperty("name")) throw $util.ProtocolError("missing required 'name'", {
        instance: message
      });
      if (!message.hasOwnProperty("mobile")) throw $util.ProtocolError("missing required 'mobile'", {
        instance: message
      });
      if (!message.hasOwnProperty("dateTime")) throw $util.ProtocolError("missing required 'dateTime'", {
        instance: message
      });
      if (!message.hasOwnProperty("noOfPeople")) throw $util.ProtocolError("missing required 'noOfPeople'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a RestaurantReservationData message.
     * @function verify
     * @memberof RestaurantReservationData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    RestaurantReservationData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.restaurant)) return "restaurant: string expected";
      if (!$util.isString(message.name)) return "name: string expected";
      if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (typeof message.noOfPeople !== "number") return "noOfPeople: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return RestaurantReservationData;
  }();

  $root.CabBookingData = function () {
    /**
     * Properties of a CabBookingData.
     * @exports ICabBookingData
     * @interface ICabBookingData
     * @property {string} destination CabBookingData destination
     * @property {number|null} [dateTime] CabBookingData dateTime
     * @property {string|null} [specialInstruction] CabBookingData specialInstruction
     * @property {number|null} [noOfPeople] CabBookingData noOfPeople
     * @property {string|null} [carType] CabBookingData carType
     */

    /**
     * Constructs a new CabBookingData.
     * @exports CabBookingData
     * @classdesc Represents a CabBookingData.
     * @implements ICabBookingData
     * @constructor
     * @param {ICabBookingData=} [properties] Properties to set
     */
    function CabBookingData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * CabBookingData destination.
     * @member {string} destination
     * @memberof CabBookingData
     * @instance
     */


    CabBookingData.prototype.destination = "";
    /**
     * CabBookingData dateTime.
     * @member {number} dateTime
     * @memberof CabBookingData
     * @instance
     */

    CabBookingData.prototype.dateTime = 0;
    /**
     * CabBookingData specialInstruction.
     * @member {string} specialInstruction
     * @memberof CabBookingData
     * @instance
     */

    CabBookingData.prototype.specialInstruction = "";
    /**
     * CabBookingData noOfPeople.
     * @member {number} noOfPeople
     * @memberof CabBookingData
     * @instance
     */

    CabBookingData.prototype.noOfPeople = 0;
    /**
     * CabBookingData carType.
     * @member {string} carType
     * @memberof CabBookingData
     * @instance
     */

    CabBookingData.prototype.carType = "";
    /**
     * Encodes the specified CabBookingData message. Does not implicitly {@link CabBookingData.verify|verify} messages.
     * @function encode
     * @memberof CabBookingData
     * @static
     * @param {CabBookingData} message CabBookingData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    CabBookingData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.destination);
      if (message.dateTime != null && message.hasOwnProperty("dateTime")) writer.uint32(
      /* id 1, wireType 5 =*/
      13).float(message.dateTime);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.specialInstruction);
      if (message.noOfPeople != null && message.hasOwnProperty("noOfPeople")) writer.uint32(
      /* id 3, wireType 5 =*/
      29).float(message.noOfPeople);
      if (message.carType != null && message.hasOwnProperty("carType")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.carType);
      return writer;
    };
    /**
     * Decodes a CabBookingData message from the specified reader or buffer.
     * @function decode
     * @memberof CabBookingData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CabBookingData} CabBookingData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    CabBookingData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.CabBookingData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.destination = reader.string();
            break;

          case 1:
            message.dateTime = reader.float();
            break;

          case 2:
            message.specialInstruction = reader.string();
            break;

          case 3:
            message.noOfPeople = reader.float();
            break;

          case 4:
            message.carType = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("destination")) throw $util.ProtocolError("missing required 'destination'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a CabBookingData message.
     * @function verify
     * @memberof CabBookingData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    CabBookingData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.destination)) return "destination: string expected";
      if (message.dateTime != null && message.hasOwnProperty("dateTime")) if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      if (message.noOfPeople != null && message.hasOwnProperty("noOfPeople")) if (typeof message.noOfPeople !== "number") return "noOfPeople: number expected";
      if (message.carType != null && message.hasOwnProperty("carType")) if (!$util.isString(message.carType)) return "carType: string expected";
      return null;
    };

    return CabBookingData;
  }();

  $root.AirportPickupData = function () {
    /**
     * Properties of an AirportPickupData.
     * @exports IAirportPickupData
     * @interface IAirportPickupData
     * @property {string} firstName AirportPickupData firstName
     * @property {string} lastName AirportPickupData lastName
     * @property {string} flightNo AirportPickupData flightNo
     * @property {string|null} [mobile] AirportPickupData mobile
     * @property {string|null} [photo] AirportPickupData photo
     * @property {CabBookingData} cabInfo AirportPickupData cabInfo
     */

    /**
     * Constructs a new AirportPickupData.
     * @exports AirportPickupData
     * @classdesc Represents an AirportPickupData.
     * @implements IAirportPickupData
     * @constructor
     * @param {IAirportPickupData=} [properties] Properties to set
     */
    function AirportPickupData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * AirportPickupData firstName.
     * @member {string} firstName
     * @memberof AirportPickupData
     * @instance
     */


    AirportPickupData.prototype.firstName = "";
    /**
     * AirportPickupData lastName.
     * @member {string} lastName
     * @memberof AirportPickupData
     * @instance
     */

    AirportPickupData.prototype.lastName = "";
    /**
     * AirportPickupData flightNo.
     * @member {string} flightNo
     * @memberof AirportPickupData
     * @instance
     */

    AirportPickupData.prototype.flightNo = "";
    /**
     * AirportPickupData mobile.
     * @member {string} mobile
     * @memberof AirportPickupData
     * @instance
     */

    AirportPickupData.prototype.mobile = "";
    /**
     * AirportPickupData photo.
     * @member {string} photo
     * @memberof AirportPickupData
     * @instance
     */

    AirportPickupData.prototype.photo = "";
    /**
     * AirportPickupData cabInfo.
     * @member {CabBookingData} cabInfo
     * @memberof AirportPickupData
     * @instance
     */

    AirportPickupData.prototype.cabInfo = null;
    /**
     * Encodes the specified AirportPickupData message. Does not implicitly {@link AirportPickupData.verify|verify} messages.
     * @function encode
     * @memberof AirportPickupData
     * @static
     * @param {AirportPickupData} message AirportPickupData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    AirportPickupData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.firstName);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.lastName);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.flightNo);
      if (message.mobile != null && message.hasOwnProperty("mobile")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.mobile);
      if (message.photo != null && message.hasOwnProperty("photo")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.photo);
      $root.CabBookingData.encode(message.cabInfo, writer.uint32(
      /* id 5, wireType 2 =*/
      42).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes an AirportPickupData message from the specified reader or buffer.
     * @function decode
     * @memberof AirportPickupData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AirportPickupData} AirportPickupData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    AirportPickupData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.AirportPickupData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.firstName = reader.string();
            break;

          case 1:
            message.lastName = reader.string();
            break;

          case 2:
            message.flightNo = reader.string();
            break;

          case 3:
            message.mobile = reader.string();
            break;

          case 4:
            message.photo = reader.string();
            break;

          case 5:
            message.cabInfo = $root.CabBookingData.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("firstName")) throw $util.ProtocolError("missing required 'firstName'", {
        instance: message
      });
      if (!message.hasOwnProperty("lastName")) throw $util.ProtocolError("missing required 'lastName'", {
        instance: message
      });
      if (!message.hasOwnProperty("flightNo")) throw $util.ProtocolError("missing required 'flightNo'", {
        instance: message
      });
      if (!message.hasOwnProperty("cabInfo")) throw $util.ProtocolError("missing required 'cabInfo'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an AirportPickupData message.
     * @function verify
     * @memberof AirportPickupData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    AirportPickupData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.firstName)) return "firstName: string expected";
      if (!$util.isString(message.lastName)) return "lastName: string expected";
      if (!$util.isString(message.flightNo)) return "flightNo: string expected";
      if (message.mobile != null && message.hasOwnProperty("mobile")) if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (message.photo != null && message.hasOwnProperty("photo")) if (!$util.isString(message.photo)) return "photo: string expected";
      {
        var error = $root.CabBookingData.verify(message.cabInfo);
        if (error) return "cabInfo." + error;
      }
      return null;
    };

    return AirportPickupData;
  }();

  $root.RoomBookingData = function () {
    /**
     * Properties of a RoomBookingData.
     * @exports IRoomBookingData
     * @interface IRoomBookingData
     * @property {string} type RoomBookingData type
     * @property {string|null} [bedType] RoomBookingData bedType
     * @property {number|null} [extraBed] RoomBookingData extraBed
     * @property {string|null} [roomType] RoomBookingData roomType
     * @property {boolean|null} [isSmokingRoom] RoomBookingData isSmokingRoom
     * @property {number} noOfAdults RoomBookingData noOfAdults
     * @property {number} noOfChildren RoomBookingData noOfChildren
     * @property {string} travellingWith RoomBookingData travellingWith
     * @property {number} checkInDate RoomBookingData checkInDate
     * @property {number} checkOutDate RoomBookingData checkOutDate
     * @property {number} noOfRooms RoomBookingData noOfRooms
     * @property {string|null} [specialInstruction] RoomBookingData specialInstruction
     */

    /**
     * Constructs a new RoomBookingData.
     * @exports RoomBookingData
     * @classdesc Represents a RoomBookingData.
     * @implements IRoomBookingData
     * @constructor
     * @param {IRoomBookingData=} [properties] Properties to set
     */
    function RoomBookingData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * RoomBookingData type.
     * @member {string} type
     * @memberof RoomBookingData
     * @instance
     */


    RoomBookingData.prototype.type = "";
    /**
     * RoomBookingData bedType.
     * @member {string} bedType
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.bedType = "";
    /**
     * RoomBookingData extraBed.
     * @member {number} extraBed
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.extraBed = 0;
    /**
     * RoomBookingData roomType.
     * @member {string} roomType
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.roomType = "";
    /**
     * RoomBookingData isSmokingRoom.
     * @member {boolean} isSmokingRoom
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.isSmokingRoom = false;
    /**
     * RoomBookingData noOfAdults.
     * @member {number} noOfAdults
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.noOfAdults = 0;
    /**
     * RoomBookingData noOfChildren.
     * @member {number} noOfChildren
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.noOfChildren = 0;
    /**
     * RoomBookingData travellingWith.
     * @member {string} travellingWith
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.travellingWith = "";
    /**
     * RoomBookingData checkInDate.
     * @member {number} checkInDate
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.checkInDate = 0;
    /**
     * RoomBookingData checkOutDate.
     * @member {number} checkOutDate
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.checkOutDate = 0;
    /**
     * RoomBookingData noOfRooms.
     * @member {number} noOfRooms
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.noOfRooms = 0;
    /**
     * RoomBookingData specialInstruction.
     * @member {string} specialInstruction
     * @memberof RoomBookingData
     * @instance
     */

    RoomBookingData.prototype.specialInstruction = "";
    /**
     * Encodes the specified RoomBookingData message. Does not implicitly {@link RoomBookingData.verify|verify} messages.
     * @function encode
     * @memberof RoomBookingData
     * @static
     * @param {RoomBookingData} message RoomBookingData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    RoomBookingData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.type);
      if (message.bedType != null && message.hasOwnProperty("bedType")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.bedType);
      if (message.extraBed != null && message.hasOwnProperty("extraBed")) writer.uint32(
      /* id 2, wireType 5 =*/
      21).float(message.extraBed);
      if (message.roomType != null && message.hasOwnProperty("roomType")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.roomType);
      if (message.isSmokingRoom != null && message.hasOwnProperty("isSmokingRoom")) writer.uint32(
      /* id 4, wireType 0 =*/
      32).bool(message.isSmokingRoom);
      writer.uint32(
      /* id 5, wireType 5 =*/
      45).float(message.noOfAdults);
      writer.uint32(
      /* id 6, wireType 5 =*/
      53).float(message.noOfChildren);
      writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.travellingWith);
      writer.uint32(
      /* id 8, wireType 5 =*/
      69).float(message.checkInDate);
      writer.uint32(
      /* id 9, wireType 5 =*/
      77).float(message.checkOutDate);
      writer.uint32(
      /* id 10, wireType 5 =*/
      85).float(message.noOfRooms);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 11, wireType 2 =*/
      90).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a RoomBookingData message from the specified reader or buffer.
     * @function decode
     * @memberof RoomBookingData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RoomBookingData} RoomBookingData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    RoomBookingData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.RoomBookingData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.type = reader.string();
            break;

          case 1:
            message.bedType = reader.string();
            break;

          case 2:
            message.extraBed = reader.float();
            break;

          case 3:
            message.roomType = reader.string();
            break;

          case 4:
            message.isSmokingRoom = reader.bool();
            break;

          case 5:
            message.noOfAdults = reader.float();
            break;

          case 6:
            message.noOfChildren = reader.float();
            break;

          case 7:
            message.travellingWith = reader.string();
            break;

          case 8:
            message.checkInDate = reader.float();
            break;

          case 9:
            message.checkOutDate = reader.float();
            break;

          case 10:
            message.noOfRooms = reader.float();
            break;

          case 11:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("type")) throw $util.ProtocolError("missing required 'type'", {
        instance: message
      });
      if (!message.hasOwnProperty("noOfAdults")) throw $util.ProtocolError("missing required 'noOfAdults'", {
        instance: message
      });
      if (!message.hasOwnProperty("noOfChildren")) throw $util.ProtocolError("missing required 'noOfChildren'", {
        instance: message
      });
      if (!message.hasOwnProperty("travellingWith")) throw $util.ProtocolError("missing required 'travellingWith'", {
        instance: message
      });
      if (!message.hasOwnProperty("checkInDate")) throw $util.ProtocolError("missing required 'checkInDate'", {
        instance: message
      });
      if (!message.hasOwnProperty("checkOutDate")) throw $util.ProtocolError("missing required 'checkOutDate'", {
        instance: message
      });
      if (!message.hasOwnProperty("noOfRooms")) throw $util.ProtocolError("missing required 'noOfRooms'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a RoomBookingData message.
     * @function verify
     * @memberof RoomBookingData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    RoomBookingData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.type)) return "type: string expected";
      if (message.bedType != null && message.hasOwnProperty("bedType")) if (!$util.isString(message.bedType)) return "bedType: string expected";
      if (message.extraBed != null && message.hasOwnProperty("extraBed")) if (typeof message.extraBed !== "number") return "extraBed: number expected";
      if (message.roomType != null && message.hasOwnProperty("roomType")) if (!$util.isString(message.roomType)) return "roomType: string expected";
      if (message.isSmokingRoom != null && message.hasOwnProperty("isSmokingRoom")) if (typeof message.isSmokingRoom !== "boolean") return "isSmokingRoom: boolean expected";
      if (typeof message.noOfAdults !== "number") return "noOfAdults: number expected";
      if (typeof message.noOfChildren !== "number") return "noOfChildren: number expected";
      if (!$util.isString(message.travellingWith)) return "travellingWith: string expected";
      if (typeof message.checkInDate !== "number") return "checkInDate: number expected";
      if (typeof message.checkOutDate !== "number") return "checkOutDate: number expected";
      if (typeof message.noOfRooms !== "number") return "noOfRooms: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return RoomBookingData;
  }();

  $root.BanquetInquiryData = function () {
    /**
     * Properties of a BanquetInquiryData.
     * @exports IBanquetInquiryData
     * @interface IBanquetInquiryData
     * @property {string} type BanquetInquiryData type
     * @property {string} name BanquetInquiryData name
     * @property {string} mobile BanquetInquiryData mobile
     * @property {string} email BanquetInquiryData email
     * @property {string|null} [query] BanquetInquiryData query
     */

    /**
     * Constructs a new BanquetInquiryData.
     * @exports BanquetInquiryData
     * @classdesc Represents a BanquetInquiryData.
     * @implements IBanquetInquiryData
     * @constructor
     * @param {IBanquetInquiryData=} [properties] Properties to set
     */
    function BanquetInquiryData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * BanquetInquiryData type.
     * @member {string} type
     * @memberof BanquetInquiryData
     * @instance
     */


    BanquetInquiryData.prototype.type = "";
    /**
     * BanquetInquiryData name.
     * @member {string} name
     * @memberof BanquetInquiryData
     * @instance
     */

    BanquetInquiryData.prototype.name = "";
    /**
     * BanquetInquiryData mobile.
     * @member {string} mobile
     * @memberof BanquetInquiryData
     * @instance
     */

    BanquetInquiryData.prototype.mobile = "";
    /**
     * BanquetInquiryData email.
     * @member {string} email
     * @memberof BanquetInquiryData
     * @instance
     */

    BanquetInquiryData.prototype.email = "";
    /**
     * BanquetInquiryData query.
     * @member {string} query
     * @memberof BanquetInquiryData
     * @instance
     */

    BanquetInquiryData.prototype.query = "";
    /**
     * Encodes the specified BanquetInquiryData message. Does not implicitly {@link BanquetInquiryData.verify|verify} messages.
     * @function encode
     * @memberof BanquetInquiryData
     * @static
     * @param {BanquetInquiryData} message BanquetInquiryData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    BanquetInquiryData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.type);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.name);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.mobile);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.email);
      if (message.query != null && message.hasOwnProperty("query")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.query);
      return writer;
    };
    /**
     * Decodes a BanquetInquiryData message from the specified reader or buffer.
     * @function decode
     * @memberof BanquetInquiryData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BanquetInquiryData} BanquetInquiryData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    BanquetInquiryData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.BanquetInquiryData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.type = reader.string();
            break;

          case 1:
            message.name = reader.string();
            break;

          case 2:
            message.mobile = reader.string();
            break;

          case 3:
            message.email = reader.string();
            break;

          case 4:
            message.query = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("type")) throw $util.ProtocolError("missing required 'type'", {
        instance: message
      });
      if (!message.hasOwnProperty("name")) throw $util.ProtocolError("missing required 'name'", {
        instance: message
      });
      if (!message.hasOwnProperty("mobile")) throw $util.ProtocolError("missing required 'mobile'", {
        instance: message
      });
      if (!message.hasOwnProperty("email")) throw $util.ProtocolError("missing required 'email'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a BanquetInquiryData message.
     * @function verify
     * @memberof BanquetInquiryData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    BanquetInquiryData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.type)) return "type: string expected";
      if (!$util.isString(message.name)) return "name: string expected";
      if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (!$util.isString(message.email)) return "email: string expected";
      if (message.query != null && message.hasOwnProperty("query")) if (!$util.isString(message.query)) return "query: string expected";
      return null;
    };

    return BanquetInquiryData;
  }();

  $root.RoomCleaningData = function () {
    /**
     * Properties of a RoomCleaningData.
     * @exports IRoomCleaningData
     * @interface IRoomCleaningData
     * @property {number} dateTime RoomCleaningData dateTime
     * @property {string|null} [specialInstruction] RoomCleaningData specialInstruction
     */

    /**
     * Constructs a new RoomCleaningData.
     * @exports RoomCleaningData
     * @classdesc Represents a RoomCleaningData.
     * @implements IRoomCleaningData
     * @constructor
     * @param {IRoomCleaningData=} [properties] Properties to set
     */
    function RoomCleaningData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * RoomCleaningData dateTime.
     * @member {number} dateTime
     * @memberof RoomCleaningData
     * @instance
     */


    RoomCleaningData.prototype.dateTime = 0;
    /**
     * RoomCleaningData specialInstruction.
     * @member {string} specialInstruction
     * @memberof RoomCleaningData
     * @instance
     */

    RoomCleaningData.prototype.specialInstruction = "";
    /**
     * Encodes the specified RoomCleaningData message. Does not implicitly {@link RoomCleaningData.verify|verify} messages.
     * @function encode
     * @memberof RoomCleaningData
     * @static
     * @param {RoomCleaningData} message RoomCleaningData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    RoomCleaningData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 5 =*/
      5).float(message.dateTime);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a RoomCleaningData message from the specified reader or buffer.
     * @function decode
     * @memberof RoomCleaningData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RoomCleaningData} RoomCleaningData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    RoomCleaningData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.RoomCleaningData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.dateTime = reader.float();
            break;

          case 1:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("dateTime")) throw $util.ProtocolError("missing required 'dateTime'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a RoomCleaningData message.
     * @function verify
     * @memberof RoomCleaningData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    RoomCleaningData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return RoomCleaningData;
  }();

  $root.LinenChangeData = function () {
    /**
     * Properties of a LinenChangeData.
     * @exports ILinenChangeData
     * @interface ILinenChangeData
     * @property {number} dateTime LinenChangeData dateTime
     * @property {string|null} [specialInstruction] LinenChangeData specialInstruction
     */

    /**
     * Constructs a new LinenChangeData.
     * @exports LinenChangeData
     * @classdesc Represents a LinenChangeData.
     * @implements ILinenChangeData
     * @constructor
     * @param {ILinenChangeData=} [properties] Properties to set
     */
    function LinenChangeData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * LinenChangeData dateTime.
     * @member {number} dateTime
     * @memberof LinenChangeData
     * @instance
     */


    LinenChangeData.prototype.dateTime = 0;
    /**
     * LinenChangeData specialInstruction.
     * @member {string} specialInstruction
     * @memberof LinenChangeData
     * @instance
     */

    LinenChangeData.prototype.specialInstruction = "";
    /**
     * Encodes the specified LinenChangeData message. Does not implicitly {@link LinenChangeData.verify|verify} messages.
     * @function encode
     * @memberof LinenChangeData
     * @static
     * @param {LinenChangeData} message LinenChangeData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    LinenChangeData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 5 =*/
      5).float(message.dateTime);
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a LinenChangeData message from the specified reader or buffer.
     * @function decode
     * @memberof LinenChangeData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LinenChangeData} LinenChangeData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    LinenChangeData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.LinenChangeData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.dateTime = reader.float();
            break;

          case 1:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("dateTime")) throw $util.ProtocolError("missing required 'dateTime'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a LinenChangeData message.
     * @function verify
     * @memberof LinenChangeData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    LinenChangeData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (typeof message.dateTime !== "number") return "dateTime: number expected";
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return LinenChangeData;
  }();

  $root.ServiceRequestData = function () {
    /**
     * Properties of a ServiceRequestData.
     * @exports IServiceRequestData
     * @interface IServiceRequestData
     * @property {number|Long|null} [date] ServiceRequestData date
     * @property {boolean|null} [folded] ServiceRequestData folded
     * @property {string|null} [items] ServiceRequestData items
     * @property {boolean|null} [onHanger] ServiceRequestData onHanger
     * @property {string|null} [spec] ServiceRequestData spec
     * @property {Array.<Amenity>|null} [amenities] ServiceRequestData amenities
     * @property {string|null} [issue] ServiceRequestData issue
     * @property {Array.<string>|null} [images] ServiceRequestData images
     * @property {string|null} [specialInstruction] ServiceRequestData specialInstruction
     */

    /**
     * Constructs a new ServiceRequestData.
     * @exports ServiceRequestData
     * @classdesc Represents a ServiceRequestData.
     * @implements IServiceRequestData
     * @constructor
     * @param {IServiceRequestData=} [properties] Properties to set
     */
    function ServiceRequestData(properties) {
      this.amenities = [];
      this.images = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ServiceRequestData date.
     * @member {number|Long} date
     * @memberof ServiceRequestData
     * @instance
     */


    ServiceRequestData.prototype.date = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestData folded.
     * @member {boolean} folded
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.folded = false;
    /**
     * ServiceRequestData items.
     * @member {string} items
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.items = "";
    /**
     * ServiceRequestData onHanger.
     * @member {boolean} onHanger
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.onHanger = false;
    /**
     * ServiceRequestData spec.
     * @member {string} spec
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.spec = "";
    /**
     * ServiceRequestData amenities.
     * @member {Array.<Amenity>} amenities
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.amenities = $util.emptyArray;
    /**
     * ServiceRequestData issue.
     * @member {string} issue
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.issue = "";
    /**
     * ServiceRequestData images.
     * @member {Array.<string>} images
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.images = $util.emptyArray;
    /**
     * ServiceRequestData specialInstruction.
     * @member {string} specialInstruction
     * @memberof ServiceRequestData
     * @instance
     */

    ServiceRequestData.prototype.specialInstruction = "";
    /**
     * Encodes the specified ServiceRequestData message. Does not implicitly {@link ServiceRequestData.verify|verify} messages.
     * @function encode
     * @memberof ServiceRequestData
     * @static
     * @param {ServiceRequestData} message ServiceRequestData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ServiceRequestData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.date != null && message.hasOwnProperty("date")) writer.uint32(
      /* id 0, wireType 0 =*/
      0).uint64(message.date);
      if (message.folded != null && message.hasOwnProperty("folded")) writer.uint32(
      /* id 1, wireType 0 =*/
      8).bool(message.folded);
      if (message.items != null && message.hasOwnProperty("items")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.items);
      if (message.onHanger != null && message.hasOwnProperty("onHanger")) writer.uint32(
      /* id 3, wireType 0 =*/
      24).bool(message.onHanger);
      if (message.spec != null && message.hasOwnProperty("spec")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.spec);
      if (message.amenities != null && message.amenities.length) for (var i = 0; i < message.amenities.length; ++i) {
        $root.Amenity.encode(message.amenities[i], writer.uint32(
        /* id 5, wireType 2 =*/
        42).fork()).ldelim();
      }
      if (message.issue != null && message.hasOwnProperty("issue")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.issue);
      if (message.images != null && message.images.length) for (var i = 0; i < message.images.length; ++i) {
        writer.uint32(
        /* id 7, wireType 2 =*/
        58).string(message.images[i]);
      }
      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) writer.uint32(
      /* id 8, wireType 2 =*/
      66).string(message.specialInstruction);
      return writer;
    };
    /**
     * Decodes a ServiceRequestData message from the specified reader or buffer.
     * @function decode
     * @memberof ServiceRequestData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ServiceRequestData} ServiceRequestData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ServiceRequestData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ServiceRequestData();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.date = reader.uint64();
            break;

          case 1:
            message.folded = reader.bool();
            break;

          case 2:
            message.items = reader.string();
            break;

          case 3:
            message.onHanger = reader.bool();
            break;

          case 4:
            message.spec = reader.string();
            break;

          case 5:
            if (!(message.amenities && message.amenities.length)) message.amenities = [];
            message.amenities.push($root.Amenity.decode(reader, reader.uint32()));
            break;

          case 6:
            message.issue = reader.string();
            break;

          case 7:
            if (!(message.images && message.images.length)) message.images = [];
            message.images.push(reader.string());
            break;

          case 8:
            message.specialInstruction = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a ServiceRequestData message.
     * @function verify
     * @memberof ServiceRequestData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ServiceRequestData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.date != null && message.hasOwnProperty("date")) if (!$util.isInteger(message.date) && !(message.date && $util.isInteger(message.date.low) && $util.isInteger(message.date.high))) return "date: integer|Long expected";
      if (message.folded != null && message.hasOwnProperty("folded")) if (typeof message.folded !== "boolean") return "folded: boolean expected";
      if (message.items != null && message.hasOwnProperty("items")) if (!$util.isString(message.items)) return "items: string expected";
      if (message.onHanger != null && message.hasOwnProperty("onHanger")) if (typeof message.onHanger !== "boolean") return "onHanger: boolean expected";
      if (message.spec != null && message.hasOwnProperty("spec")) if (!$util.isString(message.spec)) return "spec: string expected";

      if (message.amenities != null && message.hasOwnProperty("amenities")) {
        if (!Array.isArray(message.amenities)) return "amenities: array expected";

        for (var i = 0; i < message.amenities.length; ++i) {
          var error = $root.Amenity.verify(message.amenities[i]);
          if (error) return "amenities." + error;
        }
      }

      if (message.issue != null && message.hasOwnProperty("issue")) if (!$util.isString(message.issue)) return "issue: string expected";

      if (message.images != null && message.hasOwnProperty("images")) {
        if (!Array.isArray(message.images)) return "images: array expected";

        for (var i = 0; i < message.images.length; ++i) {
          if (!$util.isString(message.images[i])) return "images: string[] expected";
        }
      }

      if (message.specialInstruction != null && message.hasOwnProperty("specialInstruction")) if (!$util.isString(message.specialInstruction)) return "specialInstruction: string expected";
      return null;
    };

    return ServiceRequestData;
  }();

  $root.Amenity = function () {
    /**
     * Properties of an Amenity.
     * @exports IAmenity
     * @interface IAmenity
     * @property {string} item Amenity item
     * @property {string} quantity Amenity quantity
     */

    /**
     * Constructs a new Amenity.
     * @exports Amenity
     * @classdesc Represents an Amenity.
     * @implements IAmenity
     * @constructor
     * @param {IAmenity=} [properties] Properties to set
     */
    function Amenity(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * Amenity item.
     * @member {string} item
     * @memberof Amenity
     * @instance
     */


    Amenity.prototype.item = "";
    /**
     * Amenity quantity.
     * @member {string} quantity
     * @memberof Amenity
     * @instance
     */

    Amenity.prototype.quantity = "";
    /**
     * Encodes the specified Amenity message. Does not implicitly {@link Amenity.verify|verify} messages.
     * @function encode
     * @memberof Amenity
     * @static
     * @param {Amenity} message Amenity message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    Amenity.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.item);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.quantity);
      return writer;
    };
    /**
     * Decodes an Amenity message from the specified reader or buffer.
     * @function decode
     * @memberof Amenity
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Amenity} Amenity
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    Amenity.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.Amenity();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.item = reader.string();
            break;

          case 1:
            message.quantity = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("item")) throw $util.ProtocolError("missing required 'item'", {
        instance: message
      });
      if (!message.hasOwnProperty("quantity")) throw $util.ProtocolError("missing required 'quantity'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an Amenity message.
     * @function verify
     * @memberof Amenity
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    Amenity.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.item)) return "item: string expected";
      if (!$util.isString(message.quantity)) return "quantity: string expected";
      return null;
    };

    return Amenity;
  }();

  $root.AssignedStaffInfo = function () {
    /**
     * Properties of an AssignedStaffInfo.
     * @exports IAssignedStaffInfo
     * @interface IAssignedStaffInfo
     * @property {string} sfid AssignedStaffInfo sfid
     * @property {string} tid AssignedStaffInfo tid
     * @property {string} pid AssignedStaffInfo pid
     * @property {string} firstName AssignedStaffInfo firstName
     * @property {string} lastName AssignedStaffInfo lastName
     * @property {string|null} [imgURL] AssignedStaffInfo imgURL
     * @property {string|null} [department] AssignedStaffInfo department
     */

    /**
     * Constructs a new AssignedStaffInfo.
     * @exports AssignedStaffInfo
     * @classdesc Represents an AssignedStaffInfo.
     * @implements IAssignedStaffInfo
     * @constructor
     * @param {IAssignedStaffInfo=} [properties] Properties to set
     */
    function AssignedStaffInfo(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * AssignedStaffInfo sfid.
     * @member {string} sfid
     * @memberof AssignedStaffInfo
     * @instance
     */


    AssignedStaffInfo.prototype.sfid = "";
    /**
     * AssignedStaffInfo tid.
     * @member {string} tid
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.tid = "";
    /**
     * AssignedStaffInfo pid.
     * @member {string} pid
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.pid = "";
    /**
     * AssignedStaffInfo firstName.
     * @member {string} firstName
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.firstName = "";
    /**
     * AssignedStaffInfo lastName.
     * @member {string} lastName
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.lastName = "";
    /**
     * AssignedStaffInfo imgURL.
     * @member {string} imgURL
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.imgURL = "";
    /**
     * AssignedStaffInfo department.
     * @member {string} department
     * @memberof AssignedStaffInfo
     * @instance
     */

    AssignedStaffInfo.prototype.department = "";
    /**
     * Encodes the specified AssignedStaffInfo message. Does not implicitly {@link AssignedStaffInfo.verify|verify} messages.
     * @function encode
     * @memberof AssignedStaffInfo
     * @static
     * @param {AssignedStaffInfo} message AssignedStaffInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    AssignedStaffInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.sfid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.tid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.pid);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.firstName);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.lastName);
      if (message.imgURL != null && message.hasOwnProperty("imgURL")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.imgURL);
      if (message.department != null && message.hasOwnProperty("department")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.department);
      return writer;
    };
    /**
     * Decodes an AssignedStaffInfo message from the specified reader or buffer.
     * @function decode
     * @memberof AssignedStaffInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AssignedStaffInfo} AssignedStaffInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    AssignedStaffInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.AssignedStaffInfo();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.sfid = reader.string();
            break;

          case 1:
            message.tid = reader.string();
            break;

          case 2:
            message.pid = reader.string();
            break;

          case 3:
            message.firstName = reader.string();
            break;

          case 4:
            message.lastName = reader.string();
            break;

          case 5:
            message.imgURL = reader.string();
            break;

          case 6:
            message.department = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("sfid")) throw $util.ProtocolError("missing required 'sfid'", {
        instance: message
      });
      if (!message.hasOwnProperty("tid")) throw $util.ProtocolError("missing required 'tid'", {
        instance: message
      });
      if (!message.hasOwnProperty("pid")) throw $util.ProtocolError("missing required 'pid'", {
        instance: message
      });
      if (!message.hasOwnProperty("firstName")) throw $util.ProtocolError("missing required 'firstName'", {
        instance: message
      });
      if (!message.hasOwnProperty("lastName")) throw $util.ProtocolError("missing required 'lastName'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an AssignedStaffInfo message.
     * @function verify
     * @memberof AssignedStaffInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    AssignedStaffInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.sfid)) return "sfid: string expected";
      if (!$util.isString(message.tid)) return "tid: string expected";
      if (!$util.isString(message.pid)) return "pid: string expected";
      if (!$util.isString(message.firstName)) return "firstName: string expected";
      if (!$util.isString(message.lastName)) return "lastName: string expected";
      if (message.imgURL != null && message.hasOwnProperty("imgURL")) if (!$util.isString(message.imgURL)) return "imgURL: string expected";
      if (message.department != null && message.hasOwnProperty("department")) if (!$util.isString(message.department)) return "department: string expected";
      return null;
    };

    return AssignedStaffInfo;
  }();

  $root.ServiceRequestEscalation = function () {
    /**
     * Properties of a ServiceRequestEscalation.
     * @exports IServiceRequestEscalation
     * @interface IServiceRequestEscalation
     * @property {string|null} [serreqesclid] ServiceRequestEscalation serreqesclid
     * @property {string} serreqid ServiceRequestEscalation serreqid
     * @property {string} esclpolid ServiceRequestEscalation esclpolid
     * @property {string} tid ServiceRequestEscalation tid
     * @property {string} pid ServiceRequestEscalation pid
     * @property {number|Long|null} [autoAssignTime] ServiceRequestEscalation autoAssignTime
     * @property {number|Long|null} [inProgressTime] ServiceRequestEscalation inProgressTime
     * @property {number|Long|null} [l1Time] ServiceRequestEscalation l1Time
     * @property {number|Long|null} [l2Time] ServiceRequestEscalation l2Time
     * @property {number|Long|null} [l3Time] ServiceRequestEscalation l3Time
     * @property {number|Long|null} [l4Time] ServiceRequestEscalation l4Time
     * @property {boolean} isEscalated ServiceRequestEscalation isEscalated
     * @property {string} status ServiceRequestEscalation status
     * @property {string} escalationStatus ServiceRequestEscalation escalationStatus
     * @property {string} priority ServiceRequestEscalation priority
     * @property {string|null} [department] ServiceRequestEscalation department
     * @property {string|null} [assignStaffRole] ServiceRequestEscalation assignStaffRole
     * @property {boolean} shouldSendSMS ServiceRequestEscalation shouldSendSMS
     * @property {boolean} shouldSendEmail ServiceRequestEscalation shouldSendEmail
     * @property {string|null} [runnerStaff] ServiceRequestEscalation runnerStaff
     * @property {number|Long|null} [runnerAssignedAt] ServiceRequestEscalation runnerAssignedAt
     * @property {string|null} [l1Staff] ServiceRequestEscalation l1Staff
     * @property {number|Long|null} [l1AssignedAt] ServiceRequestEscalation l1AssignedAt
     * @property {string|null} [l2Staff] ServiceRequestEscalation l2Staff
     * @property {number|Long|null} [l2AssignedAt] ServiceRequestEscalation l2AssignedAt
     * @property {string|null} [l3Staff] ServiceRequestEscalation l3Staff
     * @property {number|Long|null} [l3AssignedAt] ServiceRequestEscalation l3AssignedAt
     * @property {string|null} [l4Staff] ServiceRequestEscalation l4Staff
     * @property {number|Long|null} [l4AssignedAt] ServiceRequestEscalation l4AssignedAt
     * @property {number|Long|null} [createdAt] ServiceRequestEscalation createdAt
     * @property {string|null} [createdBy] ServiceRequestEscalation createdBy
     * @property {number|Long|null} [modifiedAt] ServiceRequestEscalation modifiedAt
     * @property {string|null} [modifiedBy] ServiceRequestEscalation modifiedBy
     */

    /**
     * Constructs a new ServiceRequestEscalation.
     * @exports ServiceRequestEscalation
     * @classdesc Represents a ServiceRequestEscalation.
     * @implements IServiceRequestEscalation
     * @constructor
     * @param {IServiceRequestEscalation=} [properties] Properties to set
     */
    function ServiceRequestEscalation(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ServiceRequestEscalation serreqesclid.
     * @member {string} serreqesclid
     * @memberof ServiceRequestEscalation
     * @instance
     */


    ServiceRequestEscalation.prototype.serreqesclid = "";
    /**
     * ServiceRequestEscalation serreqid.
     * @member {string} serreqid
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.serreqid = "";
    /**
     * ServiceRequestEscalation esclpolid.
     * @member {string} esclpolid
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.esclpolid = "";
    /**
     * ServiceRequestEscalation tid.
     * @member {string} tid
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.tid = "";
    /**
     * ServiceRequestEscalation pid.
     * @member {string} pid
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.pid = "";
    /**
     * ServiceRequestEscalation autoAssignTime.
     * @member {number|Long} autoAssignTime
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.autoAssignTime = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation inProgressTime.
     * @member {number|Long} inProgressTime
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.inProgressTime = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l1Time.
     * @member {number|Long} l1Time
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l1Time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l2Time.
     * @member {number|Long} l2Time
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l2Time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l3Time.
     * @member {number|Long} l3Time
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l3Time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l4Time.
     * @member {number|Long} l4Time
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l4Time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation isEscalated.
     * @member {boolean} isEscalated
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.isEscalated = false;
    /**
     * ServiceRequestEscalation status.
     * @member {string} status
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.status = "";
    /**
     * ServiceRequestEscalation escalationStatus.
     * @member {string} escalationStatus
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.escalationStatus = "";
    /**
     * ServiceRequestEscalation priority.
     * @member {string} priority
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.priority = "";
    /**
     * ServiceRequestEscalation department.
     * @member {string} department
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.department = "";
    /**
     * ServiceRequestEscalation assignStaffRole.
     * @member {string} assignStaffRole
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.assignStaffRole = "";
    /**
     * ServiceRequestEscalation shouldSendSMS.
     * @member {boolean} shouldSendSMS
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.shouldSendSMS = false;
    /**
     * ServiceRequestEscalation shouldSendEmail.
     * @member {boolean} shouldSendEmail
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.shouldSendEmail = false;
    /**
     * ServiceRequestEscalation runnerStaff.
     * @member {string} runnerStaff
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.runnerStaff = "";
    /**
     * ServiceRequestEscalation runnerAssignedAt.
     * @member {number|Long} runnerAssignedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.runnerAssignedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l1Staff.
     * @member {string} l1Staff
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l1Staff = "";
    /**
     * ServiceRequestEscalation l1AssignedAt.
     * @member {number|Long} l1AssignedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l1AssignedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l2Staff.
     * @member {string} l2Staff
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l2Staff = "";
    /**
     * ServiceRequestEscalation l2AssignedAt.
     * @member {number|Long} l2AssignedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l2AssignedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l3Staff.
     * @member {string} l3Staff
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l3Staff = "";
    /**
     * ServiceRequestEscalation l3AssignedAt.
     * @member {number|Long} l3AssignedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l3AssignedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation l4Staff.
     * @member {string} l4Staff
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l4Staff = "";
    /**
     * ServiceRequestEscalation l4AssignedAt.
     * @member {number|Long} l4AssignedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.l4AssignedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation createdAt.
     * @member {number|Long} createdAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation createdBy.
     * @member {string} createdBy
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.createdBy = "";
    /**
     * ServiceRequestEscalation modifiedAt.
     * @member {number|Long} modifiedAt
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.modifiedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * ServiceRequestEscalation modifiedBy.
     * @member {string} modifiedBy
     * @memberof ServiceRequestEscalation
     * @instance
     */

    ServiceRequestEscalation.prototype.modifiedBy = "";
    /**
     * Encodes the specified ServiceRequestEscalation message. Does not implicitly {@link ServiceRequestEscalation.verify|verify} messages.
     * @function encode
     * @memberof ServiceRequestEscalation
     * @static
     * @param {ServiceRequestEscalation} message ServiceRequestEscalation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ServiceRequestEscalation.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.serreqesclid != null && message.hasOwnProperty("serreqesclid")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.serreqesclid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.serreqid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.esclpolid);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.tid);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.pid);
      if (message.autoAssignTime != null && message.hasOwnProperty("autoAssignTime")) writer.uint32(
      /* id 5, wireType 0 =*/
      40).uint64(message.autoAssignTime);
      if (message.inProgressTime != null && message.hasOwnProperty("inProgressTime")) writer.uint32(
      /* id 6, wireType 0 =*/
      48).uint64(message.inProgressTime);
      if (message.l1Time != null && message.hasOwnProperty("l1Time")) writer.uint32(
      /* id 7, wireType 0 =*/
      56).uint64(message.l1Time);
      if (message.l2Time != null && message.hasOwnProperty("l2Time")) writer.uint32(
      /* id 8, wireType 0 =*/
      64).uint64(message.l2Time);
      if (message.l3Time != null && message.hasOwnProperty("l3Time")) writer.uint32(
      /* id 9, wireType 0 =*/
      72).uint64(message.l3Time);
      if (message.l4Time != null && message.hasOwnProperty("l4Time")) writer.uint32(
      /* id 10, wireType 0 =*/
      80).uint64(message.l4Time);
      writer.uint32(
      /* id 11, wireType 0 =*/
      88).bool(message.isEscalated);
      writer.uint32(
      /* id 12, wireType 2 =*/
      98).string(message.status);
      writer.uint32(
      /* id 13, wireType 2 =*/
      106).string(message.escalationStatus);
      writer.uint32(
      /* id 14, wireType 2 =*/
      114).string(message.priority);
      if (message.department != null && message.hasOwnProperty("department")) writer.uint32(
      /* id 15, wireType 2 =*/
      122).string(message.department);
      if (message.assignStaffRole != null && message.hasOwnProperty("assignStaffRole")) writer.uint32(
      /* id 16, wireType 2 =*/
      130).string(message.assignStaffRole);
      writer.uint32(
      /* id 17, wireType 0 =*/
      136).bool(message.shouldSendSMS);
      writer.uint32(
      /* id 18, wireType 0 =*/
      144).bool(message.shouldSendEmail);
      if (message.runnerStaff != null && message.hasOwnProperty("runnerStaff")) writer.uint32(
      /* id 19, wireType 2 =*/
      154).string(message.runnerStaff);
      if (message.runnerAssignedAt != null && message.hasOwnProperty("runnerAssignedAt")) writer.uint32(
      /* id 20, wireType 0 =*/
      160).uint64(message.runnerAssignedAt);
      if (message.l1Staff != null && message.hasOwnProperty("l1Staff")) writer.uint32(
      /* id 21, wireType 2 =*/
      170).string(message.l1Staff);
      if (message.l1AssignedAt != null && message.hasOwnProperty("l1AssignedAt")) writer.uint32(
      /* id 22, wireType 0 =*/
      176).uint64(message.l1AssignedAt);
      if (message.l2Staff != null && message.hasOwnProperty("l2Staff")) writer.uint32(
      /* id 23, wireType 2 =*/
      186).string(message.l2Staff);
      if (message.l2AssignedAt != null && message.hasOwnProperty("l2AssignedAt")) writer.uint32(
      /* id 24, wireType 0 =*/
      192).uint64(message.l2AssignedAt);
      if (message.l3Staff != null && message.hasOwnProperty("l3Staff")) writer.uint32(
      /* id 25, wireType 2 =*/
      202).string(message.l3Staff);
      if (message.l3AssignedAt != null && message.hasOwnProperty("l3AssignedAt")) writer.uint32(
      /* id 26, wireType 0 =*/
      208).uint64(message.l3AssignedAt);
      if (message.l4Staff != null && message.hasOwnProperty("l4Staff")) writer.uint32(
      /* id 27, wireType 2 =*/
      218).string(message.l4Staff);
      if (message.l4AssignedAt != null && message.hasOwnProperty("l4AssignedAt")) writer.uint32(
      /* id 28, wireType 0 =*/
      224).uint64(message.l4AssignedAt);
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) writer.uint32(
      /* id 29, wireType 0 =*/
      232).uint64(message.createdAt);
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) writer.uint32(
      /* id 30, wireType 2 =*/
      242).string(message.createdBy);
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) writer.uint32(
      /* id 31, wireType 0 =*/
      248).uint64(message.modifiedAt);
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) writer.uint32(
      /* id 32, wireType 2 =*/
      258).string(message.modifiedBy);
      return writer;
    };
    /**
     * Decodes a ServiceRequestEscalation message from the specified reader or buffer.
     * @function decode
     * @memberof ServiceRequestEscalation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ServiceRequestEscalation} ServiceRequestEscalation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ServiceRequestEscalation.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ServiceRequestEscalation();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.serreqesclid = reader.string();
            break;

          case 1:
            message.serreqid = reader.string();
            break;

          case 2:
            message.esclpolid = reader.string();
            break;

          case 3:
            message.tid = reader.string();
            break;

          case 4:
            message.pid = reader.string();
            break;

          case 5:
            message.autoAssignTime = reader.uint64();
            break;

          case 6:
            message.inProgressTime = reader.uint64();
            break;

          case 7:
            message.l1Time = reader.uint64();
            break;

          case 8:
            message.l2Time = reader.uint64();
            break;

          case 9:
            message.l3Time = reader.uint64();
            break;

          case 10:
            message.l4Time = reader.uint64();
            break;

          case 11:
            message.isEscalated = reader.bool();
            break;

          case 12:
            message.status = reader.string();
            break;

          case 13:
            message.escalationStatus = reader.string();
            break;

          case 14:
            message.priority = reader.string();
            break;

          case 15:
            message.department = reader.string();
            break;

          case 16:
            message.assignStaffRole = reader.string();
            break;

          case 17:
            message.shouldSendSMS = reader.bool();
            break;

          case 18:
            message.shouldSendEmail = reader.bool();
            break;

          case 19:
            message.runnerStaff = reader.string();
            break;

          case 20:
            message.runnerAssignedAt = reader.uint64();
            break;

          case 21:
            message.l1Staff = reader.string();
            break;

          case 22:
            message.l1AssignedAt = reader.uint64();
            break;

          case 23:
            message.l2Staff = reader.string();
            break;

          case 24:
            message.l2AssignedAt = reader.uint64();
            break;

          case 25:
            message.l3Staff = reader.string();
            break;

          case 26:
            message.l3AssignedAt = reader.uint64();
            break;

          case 27:
            message.l4Staff = reader.string();
            break;

          case 28:
            message.l4AssignedAt = reader.uint64();
            break;

          case 29:
            message.createdAt = reader.uint64();
            break;

          case 30:
            message.createdBy = reader.string();
            break;

          case 31:
            message.modifiedAt = reader.uint64();
            break;

          case 32:
            message.modifiedBy = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("serreqid")) throw $util.ProtocolError("missing required 'serreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("esclpolid")) throw $util.ProtocolError("missing required 'esclpolid'", {
        instance: message
      });
      if (!message.hasOwnProperty("tid")) throw $util.ProtocolError("missing required 'tid'", {
        instance: message
      });
      if (!message.hasOwnProperty("pid")) throw $util.ProtocolError("missing required 'pid'", {
        instance: message
      });
      if (!message.hasOwnProperty("isEscalated")) throw $util.ProtocolError("missing required 'isEscalated'", {
        instance: message
      });
      if (!message.hasOwnProperty("status")) throw $util.ProtocolError("missing required 'status'", {
        instance: message
      });
      if (!message.hasOwnProperty("escalationStatus")) throw $util.ProtocolError("missing required 'escalationStatus'", {
        instance: message
      });
      if (!message.hasOwnProperty("priority")) throw $util.ProtocolError("missing required 'priority'", {
        instance: message
      });
      if (!message.hasOwnProperty("shouldSendSMS")) throw $util.ProtocolError("missing required 'shouldSendSMS'", {
        instance: message
      });
      if (!message.hasOwnProperty("shouldSendEmail")) throw $util.ProtocolError("missing required 'shouldSendEmail'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ServiceRequestEscalation message.
     * @function verify
     * @memberof ServiceRequestEscalation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ServiceRequestEscalation.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.serreqesclid != null && message.hasOwnProperty("serreqesclid")) if (!$util.isString(message.serreqesclid)) return "serreqesclid: string expected";
      if (!$util.isString(message.serreqid)) return "serreqid: string expected";
      if (!$util.isString(message.esclpolid)) return "esclpolid: string expected";
      if (!$util.isString(message.tid)) return "tid: string expected";
      if (!$util.isString(message.pid)) return "pid: string expected";
      if (message.autoAssignTime != null && message.hasOwnProperty("autoAssignTime")) if (!$util.isInteger(message.autoAssignTime) && !(message.autoAssignTime && $util.isInteger(message.autoAssignTime.low) && $util.isInteger(message.autoAssignTime.high))) return "autoAssignTime: integer|Long expected";
      if (message.inProgressTime != null && message.hasOwnProperty("inProgressTime")) if (!$util.isInteger(message.inProgressTime) && !(message.inProgressTime && $util.isInteger(message.inProgressTime.low) && $util.isInteger(message.inProgressTime.high))) return "inProgressTime: integer|Long expected";
      if (message.l1Time != null && message.hasOwnProperty("l1Time")) if (!$util.isInteger(message.l1Time) && !(message.l1Time && $util.isInteger(message.l1Time.low) && $util.isInteger(message.l1Time.high))) return "l1Time: integer|Long expected";
      if (message.l2Time != null && message.hasOwnProperty("l2Time")) if (!$util.isInteger(message.l2Time) && !(message.l2Time && $util.isInteger(message.l2Time.low) && $util.isInteger(message.l2Time.high))) return "l2Time: integer|Long expected";
      if (message.l3Time != null && message.hasOwnProperty("l3Time")) if (!$util.isInteger(message.l3Time) && !(message.l3Time && $util.isInteger(message.l3Time.low) && $util.isInteger(message.l3Time.high))) return "l3Time: integer|Long expected";
      if (message.l4Time != null && message.hasOwnProperty("l4Time")) if (!$util.isInteger(message.l4Time) && !(message.l4Time && $util.isInteger(message.l4Time.low) && $util.isInteger(message.l4Time.high))) return "l4Time: integer|Long expected";
      if (typeof message.isEscalated !== "boolean") return "isEscalated: boolean expected";
      if (!$util.isString(message.status)) return "status: string expected";
      if (!$util.isString(message.escalationStatus)) return "escalationStatus: string expected";
      if (!$util.isString(message.priority)) return "priority: string expected";
      if (message.department != null && message.hasOwnProperty("department")) if (!$util.isString(message.department)) return "department: string expected";
      if (message.assignStaffRole != null && message.hasOwnProperty("assignStaffRole")) if (!$util.isString(message.assignStaffRole)) return "assignStaffRole: string expected";
      if (typeof message.shouldSendSMS !== "boolean") return "shouldSendSMS: boolean expected";
      if (typeof message.shouldSendEmail !== "boolean") return "shouldSendEmail: boolean expected";
      if (message.runnerStaff != null && message.hasOwnProperty("runnerStaff")) if (!$util.isString(message.runnerStaff)) return "runnerStaff: string expected";
      if (message.runnerAssignedAt != null && message.hasOwnProperty("runnerAssignedAt")) if (!$util.isInteger(message.runnerAssignedAt) && !(message.runnerAssignedAt && $util.isInteger(message.runnerAssignedAt.low) && $util.isInteger(message.runnerAssignedAt.high))) return "runnerAssignedAt: integer|Long expected";
      if (message.l1Staff != null && message.hasOwnProperty("l1Staff")) if (!$util.isString(message.l1Staff)) return "l1Staff: string expected";
      if (message.l1AssignedAt != null && message.hasOwnProperty("l1AssignedAt")) if (!$util.isInteger(message.l1AssignedAt) && !(message.l1AssignedAt && $util.isInteger(message.l1AssignedAt.low) && $util.isInteger(message.l1AssignedAt.high))) return "l1AssignedAt: integer|Long expected";
      if (message.l2Staff != null && message.hasOwnProperty("l2Staff")) if (!$util.isString(message.l2Staff)) return "l2Staff: string expected";
      if (message.l2AssignedAt != null && message.hasOwnProperty("l2AssignedAt")) if (!$util.isInteger(message.l2AssignedAt) && !(message.l2AssignedAt && $util.isInteger(message.l2AssignedAt.low) && $util.isInteger(message.l2AssignedAt.high))) return "l2AssignedAt: integer|Long expected";
      if (message.l3Staff != null && message.hasOwnProperty("l3Staff")) if (!$util.isString(message.l3Staff)) return "l3Staff: string expected";
      if (message.l3AssignedAt != null && message.hasOwnProperty("l3AssignedAt")) if (!$util.isInteger(message.l3AssignedAt) && !(message.l3AssignedAt && $util.isInteger(message.l3AssignedAt.low) && $util.isInteger(message.l3AssignedAt.high))) return "l3AssignedAt: integer|Long expected";
      if (message.l4Staff != null && message.hasOwnProperty("l4Staff")) if (!$util.isString(message.l4Staff)) return "l4Staff: string expected";
      if (message.l4AssignedAt != null && message.hasOwnProperty("l4AssignedAt")) if (!$util.isInteger(message.l4AssignedAt) && !(message.l4AssignedAt && $util.isInteger(message.l4AssignedAt.low) && $util.isInteger(message.l4AssignedAt.high))) return "l4AssignedAt: integer|Long expected";
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high))) return "createdAt: integer|Long expected";
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) if (!$util.isString(message.createdBy)) return "createdBy: string expected";
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) if (!$util.isInteger(message.modifiedAt) && !(message.modifiedAt && $util.isInteger(message.modifiedAt.low) && $util.isInteger(message.modifiedAt.high))) return "modifiedAt: integer|Long expected";
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) if (!$util.isString(message.modifiedBy)) return "modifiedBy: string expected";
      return null;
    };

    return ServiceRequestEscalation;
  }();

  $root.HotelBooking = function () {
    /**
     * Properties of a HotelBooking.
     * @exports IHotelBooking
     * @interface IHotelBooking
     * @property {string|null} [hbkid] HotelBooking hbkid
     * @property {string} bookingNo HotelBooking bookingNo
     * @property {string} tid HotelBooking tid
     * @property {string} pid HotelBooking pid
     * @property {number|Long} checkInDate HotelBooking checkInDate
     * @property {number|Long} checkOutDate HotelBooking checkOutDate
     * @property {string} firstName HotelBooking firstName
     * @property {string|null} [lastName] HotelBooking lastName
     * @property {boolean|null} [isForeignNational] HotelBooking isForeignNational
     * @property {string|null} [additionalInfo] HotelBooking additionalInfo
     * @property {string|null} [email] HotelBooking email
     * @property {string|null} [mobile] HotelBooking mobile
     * @property {string|null} [roomNo] HotelBooking roomNo
     * @property {Array.<string>|null} [guests] HotelBooking guests
     * @property {boolean|null} [isCancelled] HotelBooking isCancelled
     * @property {boolean|null} [isExpired] HotelBooking isExpired
     * @property {number|Long|null} [createdAt] HotelBooking createdAt
     * @property {string|null} [createdBy] HotelBooking createdBy
     * @property {number|Long|null} [modifiedAt] HotelBooking modifiedAt
     * @property {string|null} [modifiedBy] HotelBooking modifiedBy
     */

    /**
     * Constructs a new HotelBooking.
     * @exports HotelBooking
     * @classdesc Represents a HotelBooking.
     * @implements IHotelBooking
     * @constructor
     * @param {IHotelBooking=} [properties] Properties to set
     */
    function HotelBooking(properties) {
      this.guests = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * HotelBooking hbkid.
     * @member {string} hbkid
     * @memberof HotelBooking
     * @instance
     */


    HotelBooking.prototype.hbkid = "";
    /**
     * HotelBooking bookingNo.
     * @member {string} bookingNo
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.bookingNo = "";
    /**
     * HotelBooking tid.
     * @member {string} tid
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.tid = "";
    /**
     * HotelBooking pid.
     * @member {string} pid
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.pid = "";
    /**
     * HotelBooking checkInDate.
     * @member {number|Long} checkInDate
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.checkInDate = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * HotelBooking checkOutDate.
     * @member {number|Long} checkOutDate
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.checkOutDate = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * HotelBooking firstName.
     * @member {string} firstName
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.firstName = "";
    /**
     * HotelBooking lastName.
     * @member {string} lastName
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.lastName = "";
    /**
     * HotelBooking isForeignNational.
     * @member {boolean} isForeignNational
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.isForeignNational = false;
    /**
     * HotelBooking additionalInfo.
     * @member {string} additionalInfo
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.additionalInfo = "";
    /**
     * HotelBooking email.
     * @member {string} email
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.email = "";
    /**
     * HotelBooking mobile.
     * @member {string} mobile
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.mobile = "";
    /**
     * HotelBooking roomNo.
     * @member {string} roomNo
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.roomNo = "";
    /**
     * HotelBooking guests.
     * @member {Array.<string>} guests
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.guests = $util.emptyArray;
    /**
     * HotelBooking isCancelled.
     * @member {boolean} isCancelled
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.isCancelled = false;
    /**
     * HotelBooking isExpired.
     * @member {boolean} isExpired
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.isExpired = false;
    /**
     * HotelBooking createdAt.
     * @member {number|Long} createdAt
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * HotelBooking createdBy.
     * @member {string} createdBy
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.createdBy = "";
    /**
     * HotelBooking modifiedAt.
     * @member {number|Long} modifiedAt
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.modifiedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * HotelBooking modifiedBy.
     * @member {string} modifiedBy
     * @memberof HotelBooking
     * @instance
     */

    HotelBooking.prototype.modifiedBy = "";
    /**
     * Encodes the specified HotelBooking message. Does not implicitly {@link HotelBooking.verify|verify} messages.
     * @function encode
     * @memberof HotelBooking
     * @static
     * @param {HotelBooking} message HotelBooking message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    HotelBooking.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.hbkid != null && message.hasOwnProperty("hbkid")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.hbkid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.bookingNo);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.tid);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.pid);
      writer.uint32(
      /* id 4, wireType 0 =*/
      32).uint64(message.checkInDate);
      writer.uint32(
      /* id 5, wireType 0 =*/
      40).uint64(message.checkOutDate);
      writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.firstName);
      if (message.lastName != null && message.hasOwnProperty("lastName")) writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.lastName);
      if (message.isForeignNational != null && message.hasOwnProperty("isForeignNational")) writer.uint32(
      /* id 8, wireType 0 =*/
      64).bool(message.isForeignNational);
      if (message.additionalInfo != null && message.hasOwnProperty("additionalInfo")) writer.uint32(
      /* id 9, wireType 2 =*/
      74).string(message.additionalInfo);
      if (message.email != null && message.hasOwnProperty("email")) writer.uint32(
      /* id 10, wireType 2 =*/
      82).string(message.email);
      if (message.mobile != null && message.hasOwnProperty("mobile")) writer.uint32(
      /* id 11, wireType 2 =*/
      90).string(message.mobile);
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) writer.uint32(
      /* id 12, wireType 2 =*/
      98).string(message.roomNo);
      if (message.guests != null && message.guests.length) for (var i = 0; i < message.guests.length; ++i) {
        writer.uint32(
        /* id 13, wireType 2 =*/
        106).string(message.guests[i]);
      }
      if (message.isCancelled != null && message.hasOwnProperty("isCancelled")) writer.uint32(
      /* id 14, wireType 0 =*/
      112).bool(message.isCancelled);
      if (message.isExpired != null && message.hasOwnProperty("isExpired")) writer.uint32(
      /* id 15, wireType 0 =*/
      120).bool(message.isExpired);
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) writer.uint32(
      /* id 16, wireType 0 =*/
      128).uint64(message.createdAt);
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) writer.uint32(
      /* id 17, wireType 2 =*/
      138).string(message.createdBy);
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) writer.uint32(
      /* id 18, wireType 0 =*/
      144).uint64(message.modifiedAt);
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) writer.uint32(
      /* id 19, wireType 2 =*/
      154).string(message.modifiedBy);
      return writer;
    };
    /**
     * Decodes a HotelBooking message from the specified reader or buffer.
     * @function decode
     * @memberof HotelBooking
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {HotelBooking} HotelBooking
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    HotelBooking.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.HotelBooking();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.hbkid = reader.string();
            break;

          case 1:
            message.bookingNo = reader.string();
            break;

          case 2:
            message.tid = reader.string();
            break;

          case 3:
            message.pid = reader.string();
            break;

          case 4:
            message.checkInDate = reader.uint64();
            break;

          case 5:
            message.checkOutDate = reader.uint64();
            break;

          case 6:
            message.firstName = reader.string();
            break;

          case 7:
            message.lastName = reader.string();
            break;

          case 8:
            message.isForeignNational = reader.bool();
            break;

          case 9:
            message.additionalInfo = reader.string();
            break;

          case 10:
            message.email = reader.string();
            break;

          case 11:
            message.mobile = reader.string();
            break;

          case 12:
            message.roomNo = reader.string();
            break;

          case 13:
            if (!(message.guests && message.guests.length)) message.guests = [];
            message.guests.push(reader.string());
            break;

          case 14:
            message.isCancelled = reader.bool();
            break;

          case 15:
            message.isExpired = reader.bool();
            break;

          case 16:
            message.createdAt = reader.uint64();
            break;

          case 17:
            message.createdBy = reader.string();
            break;

          case 18:
            message.modifiedAt = reader.uint64();
            break;

          case 19:
            message.modifiedBy = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("bookingNo")) throw $util.ProtocolError("missing required 'bookingNo'", {
        instance: message
      });
      if (!message.hasOwnProperty("tid")) throw $util.ProtocolError("missing required 'tid'", {
        instance: message
      });
      if (!message.hasOwnProperty("pid")) throw $util.ProtocolError("missing required 'pid'", {
        instance: message
      });
      if (!message.hasOwnProperty("checkInDate")) throw $util.ProtocolError("missing required 'checkInDate'", {
        instance: message
      });
      if (!message.hasOwnProperty("checkOutDate")) throw $util.ProtocolError("missing required 'checkOutDate'", {
        instance: message
      });
      if (!message.hasOwnProperty("firstName")) throw $util.ProtocolError("missing required 'firstName'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a HotelBooking message.
     * @function verify
     * @memberof HotelBooking
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    HotelBooking.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.hbkid != null && message.hasOwnProperty("hbkid")) if (!$util.isString(message.hbkid)) return "hbkid: string expected";
      if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";
      if (!$util.isString(message.tid)) return "tid: string expected";
      if (!$util.isString(message.pid)) return "pid: string expected";
      if (!$util.isInteger(message.checkInDate) && !(message.checkInDate && $util.isInteger(message.checkInDate.low) && $util.isInteger(message.checkInDate.high))) return "checkInDate: integer|Long expected";
      if (!$util.isInteger(message.checkOutDate) && !(message.checkOutDate && $util.isInteger(message.checkOutDate.low) && $util.isInteger(message.checkOutDate.high))) return "checkOutDate: integer|Long expected";
      if (!$util.isString(message.firstName)) return "firstName: string expected";
      if (message.lastName != null && message.hasOwnProperty("lastName")) if (!$util.isString(message.lastName)) return "lastName: string expected";
      if (message.isForeignNational != null && message.hasOwnProperty("isForeignNational")) if (typeof message.isForeignNational !== "boolean") return "isForeignNational: boolean expected";
      if (message.additionalInfo != null && message.hasOwnProperty("additionalInfo")) if (!$util.isString(message.additionalInfo)) return "additionalInfo: string expected";
      if (message.email != null && message.hasOwnProperty("email")) if (!$util.isString(message.email)) return "email: string expected";
      if (message.mobile != null && message.hasOwnProperty("mobile")) if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) if (!$util.isString(message.roomNo)) return "roomNo: string expected";

      if (message.guests != null && message.hasOwnProperty("guests")) {
        if (!Array.isArray(message.guests)) return "guests: array expected";

        for (var i = 0; i < message.guests.length; ++i) {
          if (!$util.isString(message.guests[i])) return "guests: string[] expected";
        }
      }

      if (message.isCancelled != null && message.hasOwnProperty("isCancelled")) if (typeof message.isCancelled !== "boolean") return "isCancelled: boolean expected";
      if (message.isExpired != null && message.hasOwnProperty("isExpired")) if (typeof message.isExpired !== "boolean") return "isExpired: boolean expected";
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high))) return "createdAt: integer|Long expected";
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) if (!$util.isString(message.createdBy)) return "createdBy: string expected";
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) if (!$util.isInteger(message.modifiedAt) && !(message.modifiedAt && $util.isInteger(message.modifiedAt.low) && $util.isInteger(message.modifiedAt.high))) return "modifiedAt: integer|Long expected";
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) if (!$util.isString(message.modifiedBy)) return "modifiedBy: string expected";
      return null;
    };

    return HotelBooking;
  }();

  $root.RestaurantOrders = function () {
    /**
     * Properties of a RestaurantOrders.
     * @exports IRestaurantOrders
     * @interface IRestaurantOrders
     * @property {string} roid RestaurantOrders roid
     * @property {string} tid RestaurantOrders tid
     * @property {string} pid RestaurantOrders pid
     * @property {string} rid RestaurantOrders rid
     * @property {string} oid RestaurantOrders oid
     * @property {string} sid RestaurantOrders sid
     * @property {string|null} [bookingNo] RestaurantOrders bookingNo
     * @property {string|null} [message] RestaurantOrders message
     * @property {number|Long|null} [createdAt] RestaurantOrders createdAt
     * @property {string|null} [createdBy] RestaurantOrders createdBy
     * @property {number|Long|null} [modifiedAt] RestaurantOrders modifiedAt
     * @property {string|null} [modifiedBy] RestaurantOrders modifiedBy
     */

    /**
     * Constructs a new RestaurantOrders.
     * @exports RestaurantOrders
     * @classdesc Represents a RestaurantOrders.
     * @implements IRestaurantOrders
     * @constructor
     * @param {IRestaurantOrders=} [properties] Properties to set
     */
    function RestaurantOrders(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * RestaurantOrders roid.
     * @member {string} roid
     * @memberof RestaurantOrders
     * @instance
     */


    RestaurantOrders.prototype.roid = "";
    /**
     * RestaurantOrders tid.
     * @member {string} tid
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.tid = "";
    /**
     * RestaurantOrders pid.
     * @member {string} pid
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.pid = "";
    /**
     * RestaurantOrders rid.
     * @member {string} rid
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.rid = "";
    /**
     * RestaurantOrders oid.
     * @member {string} oid
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.oid = "";
    /**
     * RestaurantOrders sid.
     * @member {string} sid
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.sid = "";
    /**
     * RestaurantOrders bookingNo.
     * @member {string} bookingNo
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.bookingNo = "";
    /**
     * RestaurantOrders message.
     * @member {string} message
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.message = "";
    /**
     * RestaurantOrders createdAt.
     * @member {number|Long} createdAt
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.createdAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * RestaurantOrders createdBy.
     * @member {string} createdBy
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.createdBy = "";
    /**
     * RestaurantOrders modifiedAt.
     * @member {number|Long} modifiedAt
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.modifiedAt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * RestaurantOrders modifiedBy.
     * @member {string} modifiedBy
     * @memberof RestaurantOrders
     * @instance
     */

    RestaurantOrders.prototype.modifiedBy = "";
    /**
     * Encodes the specified RestaurantOrders message. Does not implicitly {@link RestaurantOrders.verify|verify} messages.
     * @function encode
     * @memberof RestaurantOrders
     * @static
     * @param {RestaurantOrders} message RestaurantOrders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    RestaurantOrders.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.roid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.tid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.pid);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.rid);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.oid);
      writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.sid);
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.bookingNo);
      if (message.message != null && message.hasOwnProperty("message")) writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.message);
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) writer.uint32(
      /* id 8, wireType 0 =*/
      64).uint64(message.createdAt);
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) writer.uint32(
      /* id 9, wireType 2 =*/
      74).string(message.createdBy);
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) writer.uint32(
      /* id 10, wireType 0 =*/
      80).uint64(message.modifiedAt);
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) writer.uint32(
      /* id 11, wireType 2 =*/
      90).string(message.modifiedBy);
      return writer;
    };
    /**
     * Decodes a RestaurantOrders message from the specified reader or buffer.
     * @function decode
     * @memberof RestaurantOrders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RestaurantOrders} RestaurantOrders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    RestaurantOrders.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.RestaurantOrders();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.roid = reader.string();
            break;

          case 1:
            message.tid = reader.string();
            break;

          case 2:
            message.pid = reader.string();
            break;

          case 3:
            message.rid = reader.string();
            break;

          case 4:
            message.oid = reader.string();
            break;

          case 5:
            message.sid = reader.string();
            break;

          case 6:
            message.bookingNo = reader.string();
            break;

          case 7:
            message.message = reader.string();
            break;

          case 8:
            message.createdAt = reader.uint64();
            break;

          case 9:
            message.createdBy = reader.string();
            break;

          case 10:
            message.modifiedAt = reader.uint64();
            break;

          case 11:
            message.modifiedBy = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("roid")) throw $util.ProtocolError("missing required 'roid'", {
        instance: message
      });
      if (!message.hasOwnProperty("tid")) throw $util.ProtocolError("missing required 'tid'", {
        instance: message
      });
      if (!message.hasOwnProperty("pid")) throw $util.ProtocolError("missing required 'pid'", {
        instance: message
      });
      if (!message.hasOwnProperty("rid")) throw $util.ProtocolError("missing required 'rid'", {
        instance: message
      });
      if (!message.hasOwnProperty("oid")) throw $util.ProtocolError("missing required 'oid'", {
        instance: message
      });
      if (!message.hasOwnProperty("sid")) throw $util.ProtocolError("missing required 'sid'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a RestaurantOrders message.
     * @function verify
     * @memberof RestaurantOrders
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    RestaurantOrders.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.roid)) return "roid: string expected";
      if (!$util.isString(message.tid)) return "tid: string expected";
      if (!$util.isString(message.pid)) return "pid: string expected";
      if (!$util.isString(message.rid)) return "rid: string expected";
      if (!$util.isString(message.oid)) return "oid: string expected";
      if (!$util.isString(message.sid)) return "sid: string expected";
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";
      if (message.message != null && message.hasOwnProperty("message")) if (!$util.isString(message.message)) return "message: string expected";
      if (message.createdAt != null && message.hasOwnProperty("createdAt")) if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high))) return "createdAt: integer|Long expected";
      if (message.createdBy != null && message.hasOwnProperty("createdBy")) if (!$util.isString(message.createdBy)) return "createdBy: string expected";
      if (message.modifiedAt != null && message.hasOwnProperty("modifiedAt")) if (!$util.isInteger(message.modifiedAt) && !(message.modifiedAt && $util.isInteger(message.modifiedAt.low) && $util.isInteger(message.modifiedAt.high))) return "modifiedAt: integer|Long expected";
      if (message.modifiedBy != null && message.hasOwnProperty("modifiedBy")) if (!$util.isString(message.modifiedBy)) return "modifiedBy: string expected";
      return null;
    };

    return RestaurantOrders;
  }();

  return $root;
});