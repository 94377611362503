import {
  Button,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  createStyles,
  Grow,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { ICard } from "messaging";
import React, { FunctionComponent } from "react";

const styles = createStyles({
  card: {
    maxWidth: 345,
    borderRadius: 10,
    marginRight: 8,
    marginBottom: 4,
    minWidth: 250
  },
  content: {
    whiteSpace: "normal"
  },
  media: {
    height: "150px",
    objectFit: "cover"
  }
});

export interface ICardUIProps extends WithStyles<typeof styles> {
  card: ICard;
  index: number;
  transitionDelay: number;
  handleClick: (reply: string) => void;
}

const CardUI: FunctionComponent<ICardUIProps> = (props: ICardUIProps) => {
  const { card, classes, handleClick, index, transitionDelay } = props;
  return (
    <Grow in={true} timeout={transitionDelay + index * 200} key={`card-view#${index}`}>
      <MuiCard className={classes.card}>
        <CardActionArea disabled={true}>
          <CardMedia component="img" className={classes.media} image={card.backgroundImage} title={card.title} />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h5" component="h2">
              {card.title}
            </Typography>
            <Typography component="pre">{card.desc}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {card.primaryButton && (
            <Button size="small" color="primary" onClick={() => handleClick(card.primaryButtonValue!)}>
              {card.primaryButton}
            </Button>
          )}
          {card.secondaryButton && (
            <Button size="small" color="primary" onClick={() => handleClick(card.secondaryButtonValue!)}>
              {card.secondaryButton}
            </Button>
          )}
        </CardActions>
      </MuiCard>
    </Grow>
  );
};

export default withStyles(styles)(CardUI);
