import { IHotel, PatchedTypes } from "messages";

export type IUpdateMissedConversationArgs = {
  chreqid: string;
  pid: string;
  reason: string;
  tid: string;
};

export class ApiService {
  public static hostName: string = process.env.REACT_APP_API_URL as string;

  public static async confirmHotelBooking(tid: string, pid: string, bookingNo: string) {
    const response = await fetch(`${this.hostName}/hotel-bookings/${tid}/${pid}/${bookingNo}`);
    return (await response.json()) as PatchedTypes.IHotelBooking;
  }

  public static async getServiceRequests(
    tid: string,
    pid: string,
    bookingNo: string
  ): Promise<PatchedTypes.IServiceRequest[]> {
    const response = await fetch(`${this.hostName}/service-requests/${tid}/${pid}?bookingNo=${bookingNo}`);
    return await response.json();
  }

  public static async getHotelInformation(tid: string, pid: string) {
    const response = await fetch(`${this.hostName}/hotels/${tid}/${pid}`);
    return (await response.json()) as IHotel;
  }

  public static async updateMissedConversation({ tid, pid, chreqid, ...rest }: IUpdateMissedConversationArgs) {
    const response = await fetch(`${this.hostName}/missed-conversations/${tid}/${pid}/${chreqid}/additional-info`, {
      body: JSON.stringify(rest),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    return await response.json();
  }
}
