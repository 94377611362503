import { BotMessage } from "messaging";
import { action, observable } from "mobx";
import { ContextAndIdStore } from "../context-and-id";
import { ONBOARDING } from "../../containers/StateContext";
import { NotificationToneService } from "../../services/index";

export class BotStore {
  @observable public messages: BotMessage[] = [];

  private onNewMessage?: () => void;
  private onRehydrate?: () => void;

  constructor(private contextAndIdStore: ContextAndIdStore, private notificationToneService: NotificationToneService) {}

  public setOnNewMessage(callback: () => void) {
    this.onNewMessage = callback;
  }

  public setOnRehydrate(callback: () => void) {
    this.onRehydrate = callback;
  }

  @action public rehydrate() {
    const { pid, tid } = this.contextAndIdStore;
    if (!pid || !tid) {
      throw new Error("Can't rehydrate BotStore, first set all contexts and IDs");
    }

    const json = localStorage.getItem(`${tid}/${pid}/BOT_CHAT`);

    if (!json) {
      return;
    }

    const botStore: BotStore = JSON.parse(json);
    if (this.invalidBotStore(botStore)) {
      return;
    }

    this.messages = botStore.messages;

    this.onRehydrate && this.onRehydrate();
  }

  private invalidBotStore(botStore: BotStore) {
    return !(botStore && botStore.messages && Array.isArray(botStore.messages));
  }

  private async persist() {
    const { pid, tid } = this.contextAndIdStore;
    if (!pid || !tid) {
      throw new Error("Can't persist BotStore, first set all contexts and IDs");
    }

    localStorage.setItem(`${tid}/${pid}/BOT_CHAT`, JSON.stringify(this));
  }

  @action public addNewMessageToChat = (message: BotMessage) => {
    const { msg, sender, text } = message;
    if (
      text === ONBOARDING.WELCOME ||
      text === ONBOARDING.WELCOME_BACK ||
      text === ONBOARDING.SERVICE_REQUEST_TIMEOUT
    ) {
      return;
    }

    if (this.messages.length && this.messages[this.messages.length - 1].text === text) {
      return;
    }

    if (msg === "serviceRequestNotification" && this.messages.length > 0) {
      const lastMessage = this.messages[this.messages.length - 1];
      this.messages.push(message);
      this.messages.push(lastMessage);
      this.playNotificationTone(sender);
    } else {
      this.messages.push(message);
      this.playNotificationTone(sender);
    }

    this.persist();

    this.onNewMessage && this.onNewMessage();
  };

  private playNotificationTone(sender: string) {
    const { guest } = this.contextAndIdStore;

    if (guest && sender !== `${guest}`) {
      this.notificationToneService.play("bot.message.receive");
    } else if (guest && sender === `${guest}`) {
      this.notificationToneService.play("bot.message.send");
    }
  }
}
