import { IInputElementMessage } from "messaging";
import { action, observable } from "mobx";
import { ChangeEvent } from "react";
import { storage } from "../services";
import { ContextAndIdStore } from "./context-and-id";

export class DynamicFormStore {
  @observable public formData: any = {};
  @observable public isUploadingFiles: boolean = false;
  private uploadInputs: Set<string> = new Set();
  private inputs: IInputElementMessage[] = [];

  constructor(private contextAndIdStore: ContextAndIdStore) {}

  @action public initFormData = (inputs: IInputElementMessage[]) => {
    this.inputs = inputs;
    inputs.forEach((input: IInputElementMessage) => {
      const { name, value } = input;
      this.formData[name!] = value || "";
    });
  };

  @action public clearForm = () => {
    this.isUploadingFiles = false;
    this.uploadInputs = new Set();
    this.inputs = [];
    this.formData = {};
  };

  @action public setProperty = (
    change: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    name: string
  ) => {
    this.formData[name] = change.target.value;
  };

  @action public setFilesUpload = (change: ChangeEvent<HTMLInputElement>, name: string) => {
    this.formData[name] = change.target.files;
    if (change.target.files) {
      this.uploadInputs.add(name);
    } else {
      this.uploadInputs.delete(name);
    }
  };

  public uploadFiles = async () => {
    if (this.uploadInputs.size === 0) {
      return;
    }
    const promises: Promise<string[]>[] = [];
    this.isUploadingFiles = true;
    this.uploadInputs.forEach(async (name: string) => {
      const files = this.formData[name];
      if (files) {
        const uploadPromises: Array<Promise<string>> = [];
        for (let index = 0; index < files.length; index++) {
          uploadPromises.push(this.uploadAsAPromise(files[index], index, name));
        }

        promises.push(Promise.all(uploadPromises));

        try {
          const images = await Promise.all(uploadPromises);
          this.formData[name] = images;
        } catch (error) {
          this.formData[name] = [`Upload failed with ${error && error.message ? error.message : "an unknown error"}`];
        }
      }
    });
    await Promise.all(promises);
    this.isUploadingFiles = false;
  };

  private uploadAsAPromise = async (imageFile: File, index: number, name: string): Promise<string> => {
    const { property } = this.contextAndIdStore;
    if (!property) {
      throw new Error("Can't find property context for uploading files");
    }

    const storageRef = storage.ref(`${property}/${name}/${new Date().getTime()}-${index}`);
    const task = storageRef.put(imageFile);

    try {
      const taskSnapshot = await task;
      const url = await taskSnapshot.ref.getDownloadURL();
      return url;
    } catch (error) {
      throw error;
    }
  };

  public getFormDataAsText = () => {
    const formDataKeys = Object.keys(this.formData);
    const formDataAsText = formDataKeys.reduce((acc: string, currKey: string, currIndex) => {
      if (this.inputs[currIndex].type !== "file") {
        return acc + `${this.inputs[currIndex].label}: ${this.formData[currKey] || "-"}\n`;
      }

      if (typeof this.formData[currKey][0] === "string" && this.formData[currKey][0].includes("Upload failed")) {
        return acc + `${this.inputs[currIndex].label}: Upload failed\n`;
      } else {
        return acc + `${this.inputs[currIndex].label}: Uploaded\n`;
      }
    }, "");

    return formDataAsText;
  };
}
