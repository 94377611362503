import { EventEmitter } from "events";
import { BotMessage } from "messaging";
import { IAddMessageToChat, IChat, ICompleteChat, IUnreadMessages } from "./interfaces";

export type BotChatEvents = "new-message" | "error";

export class BotChat extends EventEmitter
  implements IChat<BotMessage>, ICompleteChat, IAddMessageToChat<BotMessage>, IUnreadMessages<BotMessage> {
  public static type: string = "BotChat";

  public messages: BotMessage[] = [];
  public readonly type: string = BotChat.type;

  public unread: number = 0;

  private completed: boolean = false;

  constructor() {
    super();
    this.rehydrate();
  }

  public addMessage(message: BotMessage) {
    if (!this.completed) {
      this.addNewMessageToChat(message).then(() => this.emit("new-message"));
    } else {
      this.emit("error", new Error("Chat closed already, cannot add new messages"));
    }
  }

  public addUnreadMessage(message: BotMessage) {
    if (!this.completed) {
      this.unread += 1;
      this.addNewMessageToChat(message);
    } else {
      this.emit("error", new Error("Chat closed already, cannot add new unread messages"));
    }
  }

  public complete() {
    if (!this.completed) {
      this.completed = true;
    }
  }

  public readUnreadMessages() {
    this.unread = 0;
  }

  private persist() {
    localStorage.setItem("BOT_CHAT", JSON.stringify(this));
  }

  private addNewMessageToChat(message: BotMessage) {
    /* return new Promise((resolve, reject) => {
      this.messages.push(message);
      resolve();
      this.persist();
    });*/
    return new Promise((resolve, reject) => {
      if (message.msg === "serviceRequestNotification" && this.messages.length > 0) {
        console.log(message);
        const lastMessage = this.messages[this.messages.length - 1];
        this.messages.push(message);
        this.messages.push(lastMessage);
      } else {
        this.messages.push(message);
      }
      resolve();
      // TODO VAIDHY
      console.log("BEFORE PERSISIT", message.property);
      this.persist();
    });
  }

  private rehydrate() {
    const json = localStorage.getItem("BOT_CHAT");
    if (json) {
      const chat: BotChat = JSON.parse(json);
      if (chat && chat.type === BotChat.type) {
        this.messages = chat.messages;
      }
    }
  }
}
