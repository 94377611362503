import React, { Component, Context, createContext } from "react";

import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { ThemedStyledProps } from "styled-components";

import { PatchedTypes } from "messages";
import { ApiService } from "../services";
import styled from "../styles/styled-components";
import { ITheme } from "../styles/theme";

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed} !important;
`;

const AuthContext: Context<IAuth> = createContext({} as IAuth);
const { Consumer, Provider } = AuthContext;

export type IAuth = IAuthProviderState & {
  authenticate: (bookingNo: string, pid: string, tid: string) => Promise<{ guest: string; property: string }>;
  logout: () => void;
};

interface IAuthProviderState {
  loading: boolean;
  isAuthenticated?: boolean;
  booking?: PatchedTypes.IHotelBooking;
}

class AuthProvider extends Component<{}, IAuthProviderState> {
  public state: IAuthProviderState;
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true
    };
  }

  public componentDidMount = async () => {
    const authenticated = await this.checkLocalAuthentication();
    if (authenticated) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ isAuthenticated: false });
    }
    this.setState({ loading: false });
  };

  public checkLocalAuthentication = async (): Promise<boolean> => {
    const bookingNo = localStorage.getItem("BOOKING_NO");
    const tid = localStorage.getItem("TID");
    const pid = localStorage.getItem("PID");
    const lastLoginDate = localStorage.getItem("LAST_LOGIN_DATE");
    if (!(bookingNo && lastLoginDate && pid && tid)) {
      // localStorage.clear();
      return false;
    }
    const booking: PatchedTypes.IHotelBooking = await ApiService.confirmHotelBooking(tid, pid, bookingNo);
    if (booking && booking.tid && !booking.isCancelled) {
      this.setState({ booking });
      const checkOutDate = booking.checkOutDate;
      if (moment().diff(checkOutDate as number, "days") < 1 && moment().diff(lastLoginDate, "days") < 1) {
        return true;
      }
    }
    // localStorage.clear();
    return false;
  };

  public authenticate = async (
    bookingNo: string,
    pid: string,
    tid: string
  ): Promise<{ guest: string; property: string }> => {
    const booking = await ApiService.confirmHotelBooking(tid, pid, bookingNo);
    // NOTE: For now gid === bookingNo
    const gid: string = bookingNo;
    if (booking && booking.tid && !booking.isCancelled) {
      const property: string = `t/${tid}/p/${pid}`;
      const guest: string = `t/${tid}/p/${pid}/g/${gid}`;
      localStorage.setItem("BOOKING_NO", bookingNo);
      localStorage.setItem("LAST_LOGIN_DATE", moment().toISOString());
      localStorage.setItem("PID", pid);
      localStorage.setItem("TID", tid);
      this.setState({ booking, isAuthenticated: true });
      return { guest, property };
    } else {
      this.logout();
      throw new Error("Check booking number");
    }
  };

  public logout = () => {
    const pid = localStorage.getItem("PID");
    const tid = localStorage.getItem("TID");
    if (tid && pid) {
      window.location.pathname = `/${tid}/${pid}`;
    }
    // localStorage.clear();
    this.setState({ isAuthenticated: false });
  };

  public render = () => {
    const { loading } = this.state;
    return (
      <Provider
        value={{
          ...this.state,
          authenticate: this.authenticate,
          logout: this.logout
        }}
      >
        {loading && (
          <ProgressWrapper>
            <CircularProgress color="inherit" />
          </ProgressWrapper>
        )}
        {!loading && this.props.children}
      </Provider>
    );
  };
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
