import { BotMessage, Guest, Property } from "messaging";
import { action, observable } from "mobx";
import { GuestClientService } from "../../services/guest-client-service";

export class WebsiteBotStore {
  @observable public isSubscribedToWebsiteBotTopics: boolean = false;
  @observable public tempGuest?: Guest;
  @observable public property?: Property;

  @action public subscribeToWebsiteBotTopics = async (
    property: Property,
    setGuestClientServiceError: (error: Error) => void,
    handleBotMessage: (message: BotMessage) => void
  ) => {
    this.setProperty(`${property}`);
    const storageId = property.tenant.id + "/" + property.id;
    let tempGuest = localStorage.getItem(storageId);
    if (!tempGuest || tempGuest === "") {
      tempGuest =
        "wb-" +
        Math.random()
          .toString(36)
          .substr(2);
      localStorage.setItem(storageId, tempGuest);
    }

    this.setTempGuest(`${property}/g/${tempGuest}`);
    const guestClient = await GuestClientService.getInstance(setGuestClientServiceError);
    await guestClient.acceptBotMessage(property, handleBotMessage, `/${tempGuest}`);
    this.isSubscribedToWebsiteBotTopics = true;
  };

  @action public setTempGuest = (guestStrCtx: string) => {
    this.tempGuest = Guest.fromString(guestStrCtx);
  };

  @action public setProperty = (propertyStrCtx: string) => {
    this.property = Property.fromString(propertyStrCtx);
  };
}
