import { Provider } from "mobx-react";
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  BotManagerStore,
  BotStore,
  ChatManagerStore,
  ChatStore,
  ChatWithUsStore,
  ClientStore,
  ContextAndIdStore,
  DynamicFormStore,
  HotelStore,
  LocaleStore,
  WebsiteBotStore
} from "../stores";
import { ThemeWrapper } from "../styles/theme";
import { AuthProvider } from "./AuthContext";
import { HomeScreen } from "./HomeScreen";
import { MessagingPage } from "./MessagingPage";
import { StateProvider } from "./StateContext";
// import { MessagingScreen } from "./MessagingScreen";
import { WebsiteBotMessagingScreen } from "./WebsiteBotMessagingScreen";
import { withWebsiteBotURLPersistence } from "./WithWebsiteBotURLPersistence";
import { NotificationToneService } from "../services/index";
import { DEFAULT_NOTIFICATION_TONE_OPTIONS } from "../constants/index";
import { NotificationToneStore } from "../stores/notification-tone";

// type PrivateRouteProps = RouteProps & any;

// const PrivateRoute = (props: PrivateRouteProps) => {
//   const { component: RenderComponent, ...rest } = props;
//   return (
//     <Route
//       {...rest}
//       render={(renderProps: RouteComponentProps) => (
//         <AuthConsumer>
//           {(auth: IAuth) => (auth.isAuthenticated ? <RenderComponent {...renderProps} /> : <Redirect to="/" />)}
//         </AuthConsumer>
//       )}
//     />
//   );
// };

const notificationToneService = new NotificationToneService(DEFAULT_NOTIFICATION_TONE_OPTIONS);
const chatWithUsStore = new ChatWithUsStore();
const clientStore = new ClientStore();
const localeStore = new LocaleStore();
const contextAndIdStore = new ContextAndIdStore();
const chatStore = new ChatStore(contextAndIdStore, notificationToneService);
const hotelStore = new HotelStore(contextAndIdStore, localeStore);
const chatManagerStore = new ChatManagerStore(chatStore, clientStore, contextAndIdStore);
const botStore = new BotStore(contextAndIdStore, notificationToneService);
const botManagerStore = new BotManagerStore(botStore, clientStore, contextAndIdStore, localeStore);
const websiteBotStore = new WebsiteBotStore();
const dynamicFormStore = new DynamicFormStore(contextAndIdStore);
const notificationToneStore = new NotificationToneStore(notificationToneService);

const stores = {
  botStore,
  botManagerStore,
  chatStore,
  chatManagerStore,
  chatWithUsStore,
  clientStore,
  contextAndIdStore,
  dynamicFormStore,
  hotelStore,
  localeStore,
  websiteBotStore,
  notificationToneStore
};

export let isAppLoaded: boolean = false;
class App extends Component {
  public componentDidMount = () => {
    isAppLoaded = true;
  };
  public render() {
    return (
      <AuthProvider>
        <Provider {...stores}>
          <StateProvider>
            <Router>
              <Switch>
                {/* NOTE: There is a pattern to follow whenever we create a internal route it should have /<gcp | website-bot>/:tid/:pid as prefix */}
                <Route path="/gcp/:tid/:pid/chat" component={MessagingPage} />
                {/* NOTE: always and only use withURLPersistence HOC with website bot urls */}
                <Route path="/website-bot/:tid/:pid/chat" component={withWebsiteBotURLPersistence(MessagingPage)} />
                {/* <PrivateRoute path="/:tid/:pid/chat/:sesid" component={MessagingPage} /> */}
                {/* <PrivateRoute path="/botchat" component={withAppBar("Ordaap Bot", BotMessagingScreen)} /> */}
                {/* <Route
                  path="/:tid/:pid/chat-with-us/:status"
                  component={withAppBar("Chat with us", LandingScreen, undefined, true)}
                />
                <Route
                  path="/:tid/:pid/chat-with-us"
                  component={withAppBar("Chat with us", LandingScreen, undefined, true)}
                /> */}
                <Route path="/gcp/:tid/:pid" component={WebsiteBotMessagingScreen} />
                {/* NOTE: always and only use withURLPersistence HOC with website bot urls */}
                <Route
                  path="/website-bot/:tid/:pid"
                  component={withWebsiteBotURLPersistence(WebsiteBotMessagingScreen)}
                />
                {/* <Route path="/:tid/:pid" component={LoginScreen} /> */}
                <Route exact path="/" component={HomeScreen} />
                <Route render={() => <h3>Page not found!</h3>} />
              </Switch>
            </Router>
          </StateProvider>
        </Provider>
      </AuthProvider>
    );
  }
}

export default ThemeWrapper(App);
