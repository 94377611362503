import { action } from "mobx";
import { NotificationToneService } from "../services/index";

export interface ILocaleSelect {
  localeLabel: string;
  localeValue: string;
}

export class NotificationToneStore {
  constructor(private notificationToneService: NotificationToneService) {}

  @action public setMuteConf(mute: boolean) {
    if (mute) {
      this.notificationToneService.muteTone();
    } else {
      this.notificationToneService.unMuteTone();
    }
  }
}
