"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function AcceptMessageFactory(messageType, qos) {
  return function AcceptMessage(context, handler, variableInContext) {
    return this.subscribe(variableInContext ? "".concat(context).concat(messageType.context).concat(variableInContext) : "".concat(context).concat(messageType.context), messageType, qos, handler);
  };
}

exports.AcceptMessageFactory = AcceptMessageFactory;

function SendMessageFactory() {
  return function SendMessage(message) {
    return this.publish(message);
  };
}

exports.SendMessageFactory = SendMessageFactory;

function UnacceptMessageFactory(messageType) {
  return function UnacceptMessage(context, variableInContext) {
    return this.unsubscribe(variableInContext ? "".concat(context).concat(messageType.context).concat(variableInContext) : "".concat(context).concat(messageType.context));
  };
}

exports.UnacceptMessageFactory = UnacceptMessageFactory;