import React, { FunctionComponent } from "react";

import styled from "../../styles/styled-components";
import { IOptionMessage, IOption } from "messaging";
import TileButton from "./tile-button";
import PillButton from "./pill-button";

export interface IOptionButtonProps {
  baseUrl: string;
  option: IOption;
  index: number;
  transitionDelay: number;
  handleClick: (value: string) => void;
}

interface IOptionButtonsProps {
  baseUrl: string;
  optionMessage: IOptionMessage;
  transitionDelay: number;
  variant: "pill" | "tile";
  handleClick: (value: string) => void;
}

const OptionButtonsWrapper = styled.div<Pick<IOptionButtonsProps, "variant">>`
  padding-top: 8px;
  padding-left: 50px;
  ${props =>
    props.variant === "tile" &&
    `
    width: 90vw;
    overflow: auto;
    white-space: nowrap;
  `}
`;

const OptionButtons: FunctionComponent<IOptionButtonsProps> = (props: IOptionButtonsProps) => {
  const {
    baseUrl,
    variant,
    optionMessage: { options },
    transitionDelay,
    handleClick
  } = props;
  return (
    <OptionButtonsWrapper variant={variant}>
      {Array.isArray(options) &&
        options.length > 0 &&
        options.map((option: IOption, index) =>
          variant === "tile" ? (
            <TileButton
              {...{ baseUrl, option, transitionDelay, index, handleClick }}
              key={`service-request-tile-button#${index}`}
            />
          ) : (
            <PillButton
              {...{ baseUrl, option, transitionDelay, index, handleClick }}
              key={`service-request-tile-button#${index}`}
            />
          )
        )}
    </OptionButtonsWrapper>
  );
};

export default OptionButtons;
