import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import ChatWithUsForm, { IChatWithUsFormValues } from "./chat-with-us-form";

export interface IChatWithUsDialogProps {
  initialchatWithUsFormValues: IChatWithUsFormValues;
  onSubmit: (values: IChatWithUsFormValues) => void;
  open: boolean;
}

const ChatWithUsDialog: FunctionComponent<IChatWithUsDialogProps> = (props: IChatWithUsDialogProps) => {
  const { initialchatWithUsFormValues, onSubmit, open } = props;
  return (
    <Dialog open={open} onClose={() => {}} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Tell us about you</DialogTitle>
      <DialogContent>
        <DialogContentText>Tell us your booking # or name and email. It helps us in identifying you.</DialogContentText>
        <ChatWithUsForm initialchatWithUsFormValues={initialchatWithUsFormValues} onSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default ChatWithUsDialog;
