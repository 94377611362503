/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */
  if (typeof define === 'function' && define.amd) define(["protobufjs/minimal"], factory);
  /* CommonJS */
  else if (typeof require === 'function' && typeof module === 'object' && module && module.exports) module.exports = factory(require("protobufjs/minimal"));
})(this, function ($protobuf) {
  "use strict"; // Common aliases

  var $Reader = $protobuf.Reader,
      $Writer = $protobuf.Writer,
      $util = $protobuf.util; // Exported root namespace

  var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

  $root.BookingInformation = function () {
    /**
     * Properties of a BookingInformation.
     * @exports IBookingInformation
     * @interface IBookingInformation
     * @property {string|null} [firstName] BookingInformation firstName
     * @property {string|null} [lastName] BookingInformation lastName
     * @property {string|null} [roomNo] BookingInformation roomNo
     * @property {string|null} [email] BookingInformation email
     */

    /**
     * Constructs a new BookingInformation.
     * @exports BookingInformation
     * @classdesc Represents a BookingInformation.
     * @implements IBookingInformation
     * @constructor
     * @param {IBookingInformation=} [properties] Properties to set
     */
    function BookingInformation(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * BookingInformation firstName.
     * @member {string} firstName
     * @memberof BookingInformation
     * @instance
     */


    BookingInformation.prototype.firstName = "";
    /**
     * BookingInformation lastName.
     * @member {string} lastName
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.lastName = "";
    /**
     * BookingInformation roomNo.
     * @member {string} roomNo
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.roomNo = "";
    /**
     * BookingInformation email.
     * @member {string} email
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.email = "";
    /**
     * Encodes the specified BookingInformation message. Does not implicitly {@link BookingInformation.verify|verify} messages.
     * @function encode
     * @memberof BookingInformation
     * @static
     * @param {BookingInformation} message BookingInformation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    BookingInformation.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.firstName != null && message.hasOwnProperty("firstName")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.firstName);
      if (message.lastName != null && message.hasOwnProperty("lastName")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.lastName);
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.roomNo);
      if (message.email != null && message.hasOwnProperty("email")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.email);
      return writer;
    };
    /**
     * Decodes a BookingInformation message from the specified reader or buffer.
     * @function decode
     * @memberof BookingInformation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookingInformation} BookingInformation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    BookingInformation.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.BookingInformation();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.firstName = reader.string();
            break;

          case 1:
            message.lastName = reader.string();
            break;

          case 2:
            message.roomNo = reader.string();
            break;

          case 3:
            message.email = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a BookingInformation message.
     * @function verify
     * @memberof BookingInformation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    BookingInformation.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.firstName != null && message.hasOwnProperty("firstName")) if (!$util.isString(message.firstName)) return "firstName: string expected";
      if (message.lastName != null && message.hasOwnProperty("lastName")) if (!$util.isString(message.lastName)) return "lastName: string expected";
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) if (!$util.isString(message.roomNo)) return "roomNo: string expected";
      if (message.email != null && message.hasOwnProperty("email")) if (!$util.isString(message.email)) return "email: string expected";
      return null;
    };

    return BookingInformation;
  }();

  $root.GuestChatSession = function () {
    /**
     * Properties of a GuestChatSession.
     * @exports IGuestChatSession
     * @interface IGuestChatSession
     * @property {string} chreqid GuestChatSession chreqid
     * @property {string} sesid GuestChatSession sesid
     * @property {string} property GuestChatSession property
     * @property {string} guest GuestChatSession guest
     * @property {string} staff GuestChatSession staff
     * @property {string} bookingNo GuestChatSession bookingNo
     * @property {BookingInformation|null} [booking] GuestChatSession booking
     */

    /**
     * Constructs a new GuestChatSession.
     * @exports GuestChatSession
     * @classdesc Represents a GuestChatSession.
     * @implements IGuestChatSession
     * @constructor
     * @param {IGuestChatSession=} [properties] Properties to set
     */
    function GuestChatSession(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatSession chreqid.
     * @member {string} chreqid
     * @memberof GuestChatSession
     * @instance
     */


    GuestChatSession.prototype.chreqid = "";
    /**
     * GuestChatSession sesid.
     * @member {string} sesid
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.sesid = "";
    /**
     * GuestChatSession property.
     * @member {string} property
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.property = "";
    /**
     * GuestChatSession guest.
     * @member {string} guest
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.guest = "";
    /**
     * GuestChatSession staff.
     * @member {string} staff
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.staff = "";
    /**
     * GuestChatSession bookingNo.
     * @member {string} bookingNo
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.bookingNo = "";
    /**
     * GuestChatSession booking.
     * @member {BookingInformation|null|undefined} booking
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.booking = null;
    /**
     * Encodes the specified GuestChatSession message. Does not implicitly {@link GuestChatSession.verify|verify} messages.
     * @function encode
     * @memberof GuestChatSession
     * @static
     * @param {GuestChatSession} message GuestChatSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatSession.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.chreqid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.sesid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.guest);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.staff);
      writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.bookingNo);
      if (message.booking != null && message.hasOwnProperty("booking")) $root.BookingInformation.encode(message.booking, writer.uint32(
      /* id 6, wireType 2 =*/
      50).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestChatSession message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatSession} GuestChatSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatSession.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatSession();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.chreqid = reader.string();
            break;

          case 1:
            message.sesid = reader.string();
            break;

          case 2:
            message.property = reader.string();
            break;

          case 3:
            message.guest = reader.string();
            break;

          case 4:
            message.staff = reader.string();
            break;

          case 5:
            message.bookingNo = reader.string();
            break;

          case 6:
            message.booking = $root.BookingInformation.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("chreqid")) throw $util.ProtocolError("missing required 'chreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("sesid")) throw $util.ProtocolError("missing required 'sesid'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      if (!message.hasOwnProperty("bookingNo")) throw $util.ProtocolError("missing required 'bookingNo'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatSession message.
     * @function verify
     * @memberof GuestChatSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatSession.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.chreqid)) return "chreqid: string expected";
      if (!$util.isString(message.sesid)) return "sesid: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      if (!$util.isString(message.guest)) return "guest: string expected";
      if (!$util.isString(message.staff)) return "staff: string expected";
      if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";

      if (message.booking != null && message.hasOwnProperty("booking")) {
        var error = $root.BookingInformation.verify(message.booking);
        if (error) return "booking." + error;
      }

      return null;
    };

    return GuestChatSession;
  }();

  $root.GuestRoster = function () {
    /**
     * Properties of a GuestRoster.
     * @exports IGuestRoster
     * @interface IGuestRoster
     * @property {string} guest GuestRoster guest
     * @property {GuestChatSession|null} [session] GuestRoster session
     */

    /**
     * Constructs a new GuestRoster.
     * @exports GuestRoster
     * @classdesc Represents a GuestRoster.
     * @implements IGuestRoster
     * @constructor
     * @param {IGuestRoster=} [properties] Properties to set
     */
    function GuestRoster(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestRoster guest.
     * @member {string} guest
     * @memberof GuestRoster
     * @instance
     */


    GuestRoster.prototype.guest = "";
    /**
     * GuestRoster session.
     * @member {GuestChatSession|null|undefined} session
     * @memberof GuestRoster
     * @instance
     */

    GuestRoster.prototype.session = null;
    /**
     * Encodes the specified GuestRoster message. Does not implicitly {@link GuestRoster.verify|verify} messages.
     * @function encode
     * @memberof GuestRoster
     * @static
     * @param {GuestRoster} message GuestRoster message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestRoster.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.guest);
      if (message.session != null && message.hasOwnProperty("session")) $root.GuestChatSession.encode(message.session, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestRoster message from the specified reader or buffer.
     * @function decode
     * @memberof GuestRoster
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestRoster} GuestRoster
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestRoster.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestRoster();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.guest = reader.string();
            break;

          case 1:
            message.session = $root.GuestChatSession.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestRoster message.
     * @function verify
     * @memberof GuestRoster
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestRoster.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.guest)) return "guest: string expected";

      if (message.session != null && message.hasOwnProperty("session")) {
        var error = $root.GuestChatSession.verify(message.session);
        if (error) return "session." + error;
      }

      return null;
    };

    return GuestRoster;
  }();

  $root.StaffRoster = function () {
    /**
     * Properties of a StaffRoster.
     * @exports IStaffRoster
     * @interface IStaffRoster
     * @property {string} staff StaffRoster staff
     * @property {Array.<GuestChatSession>|null} [sessions] StaffRoster sessions
     */

    /**
     * Constructs a new StaffRoster.
     * @exports StaffRoster
     * @classdesc Represents a StaffRoster.
     * @implements IStaffRoster
     * @constructor
     * @param {IStaffRoster=} [properties] Properties to set
     */
    function StaffRoster(properties) {
      this.sessions = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * StaffRoster staff.
     * @member {string} staff
     * @memberof StaffRoster
     * @instance
     */


    StaffRoster.prototype.staff = "";
    /**
     * StaffRoster sessions.
     * @member {Array.<GuestChatSession>} sessions
     * @memberof StaffRoster
     * @instance
     */

    StaffRoster.prototype.sessions = $util.emptyArray;
    /**
     * Encodes the specified StaffRoster message. Does not implicitly {@link StaffRoster.verify|verify} messages.
     * @function encode
     * @memberof StaffRoster
     * @static
     * @param {StaffRoster} message StaffRoster message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    StaffRoster.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.staff);
      if (message.sessions != null && message.sessions.length) for (var i = 0; i < message.sessions.length; ++i) {
        $root.GuestChatSession.encode(message.sessions[i], writer.uint32(
        /* id 1, wireType 2 =*/
        10).fork()).ldelim();
      }
      return writer;
    };
    /**
     * Decodes a StaffRoster message from the specified reader or buffer.
     * @function decode
     * @memberof StaffRoster
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StaffRoster} StaffRoster
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    StaffRoster.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.StaffRoster();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.staff = reader.string();
            break;

          case 1:
            if (!(message.sessions && message.sessions.length)) message.sessions = [];
            message.sessions.push($root.GuestChatSession.decode(reader, reader.uint32()));
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a StaffRoster message.
     * @function verify
     * @memberof StaffRoster
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    StaffRoster.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.staff)) return "staff: string expected";

      if (message.sessions != null && message.hasOwnProperty("sessions")) {
        if (!Array.isArray(message.sessions)) return "sessions: array expected";

        for (var i = 0; i < message.sessions.length; ++i) {
          var error = $root.GuestChatSession.verify(message.sessions[i]);
          if (error) return "sessions." + error;
        }
      }

      return null;
    };

    return StaffRoster;
  }();

  return $root;
});