/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */
  if (typeof define === 'function' && define.amd) define(["protobufjs/minimal"], factory);
  /* CommonJS */
  else if (typeof require === 'function' && typeof module === 'object' && module && module.exports) module.exports = factory(require("protobufjs/minimal"));
})(this, function ($protobuf) {
  "use strict"; // Common aliases

  var $Reader = $protobuf.Reader,
      $Writer = $protobuf.Writer,
      $util = $protobuf.util; // Exported root namespace

  var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

  $root.StaffOnline = function () {
    /**
     * Properties of a StaffOnline.
     * @exports IStaffOnline
     * @interface IStaffOnline
     * @property {boolean} isOnline StaffOnline isOnline
     * @property {string} staff StaffOnline staff
     * @property {string} property StaffOnline property
     */

    /**
     * Constructs a new StaffOnline.
     * @exports StaffOnline
     * @classdesc Represents a StaffOnline.
     * @implements IStaffOnline
     * @constructor
     * @param {IStaffOnline=} [properties] Properties to set
     */
    function StaffOnline(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * StaffOnline isOnline.
     * @member {boolean} isOnline
     * @memberof StaffOnline
     * @instance
     */


    StaffOnline.prototype.isOnline = false;
    /**
     * StaffOnline staff.
     * @member {string} staff
     * @memberof StaffOnline
     * @instance
     */

    StaffOnline.prototype.staff = "";
    /**
     * StaffOnline property.
     * @member {string} property
     * @memberof StaffOnline
     * @instance
     */

    StaffOnline.prototype.property = "";
    /**
     * Encodes the specified StaffOnline message. Does not implicitly {@link StaffOnline.verify|verify} messages.
     * @function encode
     * @memberof StaffOnline
     * @static
     * @param {StaffOnline} message StaffOnline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    StaffOnline.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 0 =*/
      0).bool(message.isOnline);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.staff);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      return writer;
    };
    /**
     * Decodes a StaffOnline message from the specified reader or buffer.
     * @function decode
     * @memberof StaffOnline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StaffOnline} StaffOnline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    StaffOnline.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.StaffOnline();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.isOnline = reader.bool();
            break;

          case 1:
            message.staff = reader.string();
            break;

          case 2:
            message.property = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("isOnline")) throw $util.ProtocolError("missing required 'isOnline'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a StaffOnline message.
     * @function verify
     * @memberof StaffOnline
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    StaffOnline.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (typeof message.isOnline !== "boolean") return "isOnline: boolean expected";
      if (!$util.isString(message.staff)) return "staff: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      return null;
    };

    return StaffOnline;
  }();

  return $root;
});