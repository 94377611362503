import { GuestClient } from "messaging";
import { getConnectionOptions } from "../utils";

export class GuestClientService {
  public static getInstance = async (errorHandler: (error: Error) => void): Promise<GuestClient> => {
    if (!GuestClientService.instance) {
      const guestClient = new GuestClient(getConnectionOptions());
      try {
        await guestClient.connect();
        GuestClientService.instance = guestClient;
      } catch (error) {
        errorHandler(error);
      }
      guestClient.on("error", errorHandler);
    }
    return GuestClientService.instance;
  };
  private static instance: GuestClient;
  private constructor() {}
}
