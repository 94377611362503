import { action, observable } from "mobx";
import { ILocaleConfiguration } from "messages";

export class LocaleStore {
  @observable public locale: string = "en";
  @observable public localeEnabled: boolean = false;
  public localeList: ILocaleConfiguration[] = [];

  @action public setLocale = (locale: string) => {
    this.locale = locale;
  };

  @action public setLocaleList = (localeList: ILocaleConfiguration[]) => {
    if (localeList && localeList.length > 0) {
      this.localeList = localeList;
      this.localeEnabled = true;
    }
  };
}
