import {
  GuestChatComplete,
  GuestChatDecline,
  GuestChatRequestTimeout,
  GuestChatSession,
  GuestRoster,
  NoStaffOnline,
  OptionMessage,
  PeerMessage,
  Property,
  ProxyGuestChatRequest
} from "messaging";
import { Option } from "messaging/dist/messages/peer/pb";
import { action, autorun, observable } from "mobx";
import { IChatWithUsFormValues } from "../../components/ChatWithUsDialog";
import { ApiService } from "../../services/index";
import { rand } from "../../utils/rand";
import { ClientStore } from "../client";
import { ContextAndIdStore } from "../context-and-id";
import { ChatStore } from "./chat";

export enum INTERNAL_CHAT_CONTROL_MESSAGES {
  INTERNAL_BACK = "INTERNAL_BACK",
  INTERNAL_LOADING = "INTERNAL_LOADING",
  INTERNAL_RETRY = "INTERNAL_RETRY",
  INTERNAL_RETRY_AFTER_COMPLETION = "INTERNAL_RETRY_AFTER_COMPLETION"
}

export class ChatManagerStore {
  @observable public prevChatWithUsFormValues: IChatWithUsFormValues = {
    bookingNo: "",
    name: "",
    email: ""
  };
  @observable public session?: GuestChatSession;
  @observable public showChatWithUsDialog: boolean = true;
  @observable public showReasonInput: boolean = false;

  private chreqid?: string;

  constructor(
    private chatStore: ChatStore,
    private clientStore: ClientStore,
    private contextAndIdStore: ContextAndIdStore
  ) {
    this.complete = this.complete.bind(this);
    this.processRequestFailure = this.processRequestFailure.bind(this);
    this.processRoster = this.processRoster.bind(this);
    this.reply = this.reply.bind(this);
    this.internalReply = this.internalReply.bind(this);
    this.start = this.start.bind(this);

    autorun(() => {
      if (!this.clientStore.isConnectedToMQTTBroker) {
        return;
      }

      if (!this.contextAndIdStore.guest) {
        return;
      }

      this.setup();
    });
  }

  public async complete() {
    const complete = new GuestChatComplete({
      session: this.session as GuestChatSession
    });

    const client = await this.clientStore.getClient();
    client && client.sendGuestChatComplete(complete);
  }

  public async reply(text: string) {
    if (!this.session) {
      throw new Error("Can't reply to peer, no active session found");
    }

    const { guest, property, sesid } = this.session;
    const message = new PeerMessage({
      text,
      msgid: rand(),
      property,
      sender: guest,
      sesid
    });

    const client = await this.clientStore.getClient();
    client && client.sendMessage(message);
  }

  public internalReply(text: string) {
    switch (text) {
      case INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_BACK: {
        this.chatStore.clear();
        this.showChatWithUsDialog = true;
        break;
      }
      case INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_RETRY: {
        this.chatStore.addPreConnectionMessage(
          this.createInternalPeerMessage({ self: true, text: "Ok. I wanna retry!" })
        );
        this.showChatWithUsDialog = true;
        break;
      }
      case INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_RETRY_AFTER_COMPLETION: {
        this.chatStore.clear();
        this.chatStore.addPreConnectionMessage(
          this.createInternalPeerMessage({ self: true, text: "Ok. I wanna retry!" })
        );
        this.showChatWithUsDialog = true;
        break;
      }
      default: {
        this.chatStore.addPreConnectionMessage(this.createInternalPeerMessage({ text }));
        break;
      }
    }
  }

  private createInternalPeerMessage({
    optionMessage,
    self,
    text
  }: {
    optionMessage?: OptionMessage;
    self?: boolean;
    text: string;
  }): PeerMessage {
    const { gid, guest, property } = this.contextAndIdStore;
    return new PeerMessage({
      msgid: rand(),
      optionMessage: optionMessage,
      property: `${property}`,
      sender: self ? `${guest}` : `${property}/sf/ordaap-internal`,
      sesid: `ordaap-internal#${gid}`,
      text
    });
  }

  @action public async start(values: IChatWithUsFormValues) {
    this.showChatWithUsDialog = false;

    this.chatStore.addPreConnectionMessage(
      this.createInternalPeerMessage({ text: "Please wait while we are trying to connect you with someone." })
    );
    this.chatStore.addPreConnectionMessage(
      this.createInternalPeerMessage({ text: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_LOADING })
    );

    this.prevChatWithUsFormValues = values;

    const { guest, property } = this.contextAndIdStore;
    const { bookingNo, email, name } = values;
    const chreqid = rand();
    const proxy = new ProxyGuestChatRequest({
      bookingNo: bookingNo ? bookingNo : undefined,
      chreqid,
      email: email ? email : undefined,
      guest: `${guest}`,
      property: `${property}`,
      name: name ? name : undefined
    });

    const client = await this.clientStore.getClient();
    client && client.sendProxyGuestChatRequest(proxy);
  }

  public async updateReason(reason: string) {
    this.showReasonInput = false;

    this.chatStore.addPreConnectionMessage(this.createInternalPeerMessage({ text: reason, self: true }));

    this.chatStore.addPreConnectionMessage(
      this.createInternalPeerMessage({ text: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_LOADING })
    );

    const { pid, tid } = this.contextAndIdStore;

    await ApiService.updateMissedConversation({
      chreqid: this.chreqid!,
      pid: pid!,
      reason,
      tid: tid!
    });

    this.chatStore.addPreConnectionMessage(
      this.createInternalPeerMessage({
        text: "Thanks for sharing your query. Someone would get back to you at the earliest.",
        optionMessage: {
          options: [
            {
              // NOTE: be careful with the "/" @technical-debt @future-improvement
              internalRoute: "",
              label: "TAKE ME BACK",
              value: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_BACK
            } as Option
            // NOTE: For now we don't need a retry option
            // {
            //   // NOTE: be careful with the "/" @technical-debt @future-improvement
            //   internalRoute: "/chat",
            //   label: "RETRY",
            //   value: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_RETRY
            // } as Option
          ]
        }
      })
    );
  }

  @action private async processRoster(roster: GuestRoster) {
    const { session } = roster;
    if (!session && this.session) {
      this.chatStore.addPostConnectionMessage(this.createInternalPeerMessage({ text: "Your chat session is ended." }));
      this.chatStore.addPostConnectionMessage(
        this.createInternalPeerMessage({
          optionMessage: {
            options: [
              {
                // NOTE: be careful with the "/" @technical-debt @future-improvement
                internalRoute: "",
                label: "TAKE ME BACK",
                value: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_BACK
              } as Option
              // NOTE: For now we don't need a retry option
              // {
              //   // NOTE: be careful with the "/" @technical-debt @future-improvement
              //   internalRoute: "/chat",
              //   label: "RETRY",
              //   value: INTERNAL_CHAT_CONTROL_MESSAGES.INTERNAL_RETRY_AFTER_COMPLETION
              // } as Option
            ]
          },
          text:
            "Did you miss something? You have any other queries? You want other updates? Don't worry you can always start a new chat."
        })
      );

      this.disconnect();
      return;
    }

    // NOTE: will be useful when we handle mqtt connection failure with a retry connection mechanism
    if (session && this.session && session === this.session) {
      this.connect();
    }

    if (session && !this.session) {
      this.showReasonInput = false;
      this.showChatWithUsDialog = false;
      // NOTE: We can show the staff info here if we want to but will take a bit of work
      this.chatStore.addPreConnectionMessage(
        this.createInternalPeerMessage({
          text: "You have been successfully connected to one of our staff. You can start chatting from here."
        })
      );
      this.session = session;
      // NOTE: No need to await here, chatStore will fetch the persisted messages till this async fuction completes
      this.connect();
      this.chatStore.init(session.sesid);
      return;
    }
  }

  @action private async disconnect() {
    const { property, sesid } = this.session!;
    this.session = undefined;

    const client = await this.clientStore.getClient();
    client && client.unacceptMessage(Property.fromString(property), `/${sesid}${PeerMessage.suffix}`);
  }

  @action private async connect() {
    const { property, sesid } = this.session!;

    const client = await this.clientStore.getClient();
    client &&
      (await client.acceptMessage(
        Property.fromString(property),
        this.chatStore.addMessage,
        `/${sesid}${PeerMessage.suffix}`
      ));
  }

  @action private processRequestFailure(message: NoStaffOnline | GuestChatDecline | GuestChatRequestTimeout) {
    this.showReasonInput = true;

    // NOTE: For now we are not showing specific message for different failures
    // switch (message.type) {
    //   case NoStaffOnline.type: {
    //     this.chatStore.addPreConnectionMessage(
    //       this.createInternalPeerMessage({ text: "Looks like there's no staff online to chat with you at the moment." })
    //     );
    //     break;
    //   }
    //   case ProxyGuestChatDecline.type: {
    //     this.chatStore.addPreConnectionMessage(
    //       this.createInternalPeerMessage({
    //         text: "Looks like all our staffs are busy and can't chat with you at the moment."
    //       })
    //     );
    //     break;
    //   }
    //   case ProxyGuestChatRequestTimeout.type: {
    //     this.chatStore.addPreConnectionMessage(
    //       this.createInternalPeerMessage({
    //         text: "Looks like all our staffs are busy and can't chat with you at the moment."
    //       })
    //     );
    //     break;
    //   }
    // }

    this.chatStore.addPreConnectionMessage(
      this.createInternalPeerMessage({
        text:
          "Looks like our staffs are currently engaged. Could you please share your query? Our staffs would get back to you at the earliest."
      })
    );

    const { chreqid } = message;
    this.chreqid = chreqid;
  }

  @action private async setup() {
    const { guest } = this.contextAndIdStore;
    if (!guest) {
      throw new Error("Can't setup chat manager, first set all contexts and IDs");
    }

    const client = await this.clientStore.getClient();
    await Promise.all([
      client && client.acceptGuestRoster(guest, this.processRoster),
      client && client.acceptNoStaffOnline(guest, this.processRequestFailure),
      client && client.acceptProxyGuestChatDecline(guest, this.processRequestFailure),
      client && client.acceptProxyGuestChatRequestTimeout(guest, this.processRequestFailure)
    ]);
  }
}
