import { Avatar, createStyles, Grow, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import StaffIcon from "@material-ui/icons/Person";
import { Guest, IBotMessage, IPeerMessage } from "messaging";
import React, { Fragment, FunctionComponent, ReactNode } from "react";
import { Gallery } from "../../components/Gallery";
import styled from "../../styles/styled-components";

type MyPaperProps = { self: boolean } & PaperProps;
const MyPaper = ({ self, ...rest }: MyPaperProps) => <Paper {...rest} />;
export const StyledTextBubble = styled(MyPaper)`
  && {
    border-radius: 10px;
    padding: 12px 10px;
    height: fit-content;
    box-shadow: none;
    ${props => props.self && "margin: 12px 0"};
    ${props =>
      props.self ? "background-image: linear-gradient(279deg, #ffd273, #ffde3b);" : "backgound-color: #ffffff"};
  }
`;

interface IMessageWrapper {
  self?: boolean;
}

export const MessageWrapper = styled.div<IMessageWrapper>`
  display: flex;
  margin-bottom: 4px;
  justify-content: ${(props: any) => (props.self ? "flex-end" : "flex-start")};
`;

const Logo = styled.img`
  display: inline-block;
  min-width: 40px;
  height: 40px;
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 4px;
  border-radius: 30px;
`;

export const EmptyDiv = styled.div`
  display: inline-block;
  min-width: 48px;
  height: 40px;
`;

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      minWidth: 40,
      height: 40,
      margin: "0 8px 4px 0",
      backgroundColor: theme.palette.secondary.main
    }
  });

export enum MESSAGE_VARIANT {
  BOT_MESSAGE = "BOT_MESSAGE",
  PEER_MESSAGE = "PEER_MESSAGE",
  STAFF_MESSAGE = "STAFF_MESSAGE",
  PEER_MESSAGE_WITH_CHILDREN_PROP = "PEER_MESSAGE_WITH_CHILDREN_PROP"
}

type IShareMessageProps = {
  index: number;
  guest: Guest;
  logo?: string;
};

type IMessageProps =
  | IShareMessageProps & {
      message: IBotMessage;
      previousMessage: undefined | IBotMessage;
      handleGalleryImageClick: (imgUrls: string | string[], clickedStep: number) => void;
      variant: MESSAGE_VARIANT.BOT_MESSAGE;
    }
  | IShareMessageProps & {
      message: IPeerMessage;
      previousMessage: IPeerMessage | undefined;
      variant: MESSAGE_VARIANT.PEER_MESSAGE;
    }
  | IShareMessageProps & {
      children: string | ReactNode;
      message: IPeerMessage;
      previousMessage: IPeerMessage | undefined;
      variant: MESSAGE_VARIANT.PEER_MESSAGE_WITH_CHILDREN_PROP;
    }
  | IShareMessageProps & {
      message: IPeerMessage;
      previousMessage: IPeerMessage | undefined;
      variant: MESSAGE_VARIANT.STAFF_MESSAGE;
    };

const Message: FunctionComponent<IMessageProps & WithStyles<typeof styles>> = (
  props: IMessageProps & WithStyles<typeof styles>
) => {
  const { logo, classes } = props;
  const LogoOrAvatar =
    props.variant === MESSAGE_VARIANT.STAFF_MESSAGE ? (
      <Avatar className={classes.avatar}>
        <StaffIcon />
      </Avatar>
    ) : (
      <Logo src={logo || "/logo-circle-180x180.png"} />
    );
  return (
    <Fragment>
      <Grow in={true}>
        <MessageWrapper self={props.message.sender === `${props.guest}`}>
          {props.index === 0 && props.message.sender !== `${props.guest}` && LogoOrAvatar}
          {props.message.sender !== `${props.guest}` &&
          props.previousMessage &&
          props.previousMessage.sender === `${props.guest}`
            ? LogoOrAvatar
            : props.index !== 0 && <EmptyDiv />}
          <StyledTextBubble self={props.message.sender === `${props.guest}`}>
            {props.variant === MESSAGE_VARIANT.BOT_MESSAGE && props.message.gallery && (
              <Gallery imgUrls={props.message.text} handleGalleryImgClick={props.handleGalleryImageClick} />
            )}
            {props.variant === MESSAGE_VARIANT.PEER_MESSAGE_WITH_CHILDREN_PROP && props.children}
            {props.variant !== MESSAGE_VARIANT.PEER_MESSAGE_WITH_CHILDREN_PROP && (
              <Typography component="pre">{props.message.text}</Typography>
            )}
          </StyledTextBubble>
        </MessageWrapper>
      </Grow>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(Message);
