import { IPeerMessage } from "messaging";
export class GuestChatPersistenceService {
  public static hostName: string = process.env.REACT_APP_API_URL as string;
  public static async fetchMessages(sesid: string, page: number, noOfRecords: number): Promise<IPeerMessage[]> {
    const response = await fetch(
      `${this.hostName}/guest-chat-messages/${sesid}?page=${page}&noOfRecords=${noOfRecords}`
    );
    return await response.json();
  }
}
