import { Link } from "react-router-dom";
import React, { FunctionComponent } from "react";
import { Grow, Typography, createStyles, WithStyles, withStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";

import { IOptionButtonProps } from "./option-buttons";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "36px",
      margin: "0 8px 8px 0",
      backgroundColor: "#ffffff",
      color: theme.palette.getContrastText("#ffffff"),
      "&:hover": {
        backgroundColor: grey[50]
      }
    },
    cancel: {
      boxShadow: "none"
    }
  });

interface IPillButtonProps extends IOptionButtonProps, WithStyles<typeof styles> {}

const PillButton: FunctionComponent<IPillButtonProps> = (props: IPillButtonProps) => {
  const {
    baseUrl,
    classes,
    index,
    transitionDelay,
    option: { externalUrl, internalRoute, label, value },
    handleClick
  } = props;

  return (
    <Grow in={true} timeout={transitionDelay + index * 200}>
      <Fab
        classes={{ root: classes.root }}
        className={value.toLowerCase() === "cancel" ? classes.cancel : ""}
        {...(externalUrl && { component: "a", href: externalUrl, target: "_blank" })}
        {...(internalRoute !== undefined &&
          internalRoute !== null && {
            component: ({ innerRef, ...props }) => <Link {...props} to={`${baseUrl}${internalRoute}`} />
          })}
        variant="extended"
        onClick={() => handleClick(value)}
      >
        <Typography color={value.toLowerCase() === "cancel" ? "default" : "primary"}>{label}</Typography>
      </Fab>
    </Grow>
  );
};

export default withStyles(styles)(PillButton);
