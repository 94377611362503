import {
  Button,
  CircularProgress,
  createMuiTheme,
  createStyles,
  Grow,
  IconButton,
  InputBase,
  MuiThemeProvider,
  Paper,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { cyan } from "@material-ui/core/colors";
import { PaperProps } from "@material-ui/core/Paper";
import SendIcon from "@material-ui/icons/Send";
import { GuestChatSession, IOption, IOptionMessage, PeerMessage, Property } from "messaging";
import { inject, observer } from "mobx-react";
import React, { ChangeEvent, Component, Context, createRef, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link, RouteComponentProps } from "react-router-dom";
import { OptionButtons } from "../../components/OptionButtons";
import { ApiService, GuestClientService } from "../../services";
import { ChatWithUsStore, ContextAndIdStore } from "../../stores/index";
import styled from "../../styles/styled-components";
import { rand } from "../../utils";
import { IState, StateContext } from "../StateContext";

export const Body = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  background-image: url("/img/bg.png");
  background-blend-mode: exclusion;
  overflow-x: hidden;
`;

export const Content = styled.div`
  padding: 16px 16px 100px 16px;
  height: 100%;
  overflow: auto;
`;

export const MessageWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
  justify-content: ${(props: any) => (props.self ? "flex-end" : "flex-start")};
`;

type MyPaperProps = { self: boolean } & PaperProps;
const MyPaper = ({ self, ...rest }: MyPaperProps) => <Paper {...rest} />;
export const StyledTextBubble = styled(MyPaper)`
  && {
    border-radius: 10px;
    padding: 12px 10px;
    height: fit-content;
    box-shadow: none;
    ${props => props.self && "margin: 11.8px 0"};
    ${props =>
      props.self ? "background-image: linear-gradient(279deg, #ffd273, #ffde3b);" : "backgound-color: #ffffff"};
  }
`;

const StyledPaper = styled(Paper as React.SFC<PaperProps>)`
  && {
    padding: 16px 24px;
  }
`;

const StyledFullButton = styled(Button as React.SFC<ButtonProps>)`
  && {
    margin-bottom: 8px;
    width: 100%;
    > a {
      text-decoration: none !important;
    }
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
`;

export const MessagesEnd = styled.div`
  float: "left";
  clear: "both";
`;

export const TextBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
`;

const Logo = styled.img`
  display: inline-block;
  min-width: 40px;
  height: 40px;
  margin-left: 0px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const EmptyDiv = styled.div`
  display: inline-block;
  min-width: 48px;
  height: 40px;
`;

const styles = createStyles({
  iconButton: {
    padding: 10
  },
  input: {
    flex: 1,
    marginLeft: 8
  },
  root: {
    alignItems: "center",
    borderRadius: "10px",
    bottom: "16px",
    display: "flex",
    left: "16px",
    padding: "2px 4px",
    position: "fixed",
    right: "16px"
  }
});

const theme = createMuiTheme({
  palette: {
    primary: cyan
  }
});

export interface IMessagingScreenRouteProps {
  sesid: string;
}
export interface IMessagingScreenState {
  textBoxContent: string;
  reasonForConnecting?: string;
  isChatRequestFailureHandled?: boolean;
}

export type IMessagingScreenWithRouteProps = {
  chatWithUsStore: ChatWithUsStore;
  contextAndIdStore: ContextAndIdStore;
} & RouteComponentProps<IMessagingScreenRouteProps>;

@inject("chatWithUsStore", "contextAndIdStore")
@observer
class MessagingScreen extends Component<
  IMessagingScreenWithRouteProps & WithStyles<typeof styles>,
  IMessagingScreenState
> {
  public static contextType: Context<IState> = StateContext;

  public messagesEnd = createRef<HTMLDivElement>();

  public state: IMessagingScreenState = {
    textBoxContent: ""
  };

  public isWaitingForLocalInput?: boolean;

  private isChatCompleted: boolean = false;

  public componentDidMount = () => {
    const { activeChat, setChatOpenOnUI }: IState = this.context;
    setChatOpenOnUI("STAFF_CHAT");
    this.scrollToBottom();
    if (activeChat) {
      activeChat.on("new-message", this.scrollToBottom);
    }
  };

  public componentWillUpdate = (
    nextProps: IMessagingScreenWithRouteProps,
    nextState: IMessagingScreenState,
    nextContext: IState
  ) => {
    const { activeChat, session }: IState = this.context;
    if (activeChat === undefined && nextContext.activeChat) {
      nextContext.activeChat.on("new-message", this.scrollToBottom);
    }
    if (session && !nextContext.session) {
      this.isChatCompleted = true;
    }
  };

  public componentWillUnmount = () => {
    const { activeChat, resetActiveChat, setChatOpenOnUI }: IState = this.context;
    setChatOpenOnUI("");
    if (activeChat) {
      activeChat.removeAllListeners();
    }
    if (this.isChatCompleted) {
      resetActiveChat();
    }
  };

  public scrollToBottom = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView();
    }
  };

  public handleSendMessage = async () => {
    const { textBoxContent } = this.state;
    if (this.isWaitingForLocalInput) {
      this.isWaitingForLocalInput = false;
      this.setState({ reasonForConnecting: textBoxContent, textBoxContent: "" });
      this.handleChatFailure(textBoxContent);
      return;
    }
    const { session, setGuestClientServiceError }: IState = this.context;
    if (!session) {
      return;
    }
    const { guest, property, sesid } = session as GuestChatSession;
    const message = new PeerMessage({
      text: textBoxContent,
      msgid: rand(),
      property,
      sender: guest,
      sesid
    });
    const guestClient = await GuestClientService.getInstance(setGuestClientServiceError);
    guestClient.sendMessage(message);
    this.setState({ textBoxContent: "" });
  };

  public handleChatFailure = async (reason: string) => {
    const { chatWithUsStore, contextAndIdStore } = this.props;
    const { chreqid } = chatWithUsStore;
    const { pid, tid } = contextAndIdStore;

    await ApiService.updateMissedConversation({
      chreqid,
      pid: pid!,
      reason,
      tid: tid!
    });
    this.setState({ isChatRequestFailureHandled: true });
  };

  public handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ textBoxContent: event.currentTarget.value });
  };

  public render() {
    const { activeChat, session, status }: IState = this.context;
    const messages: PeerMessage[] = activeChat ? activeChat.messages : [];
    const {
      classes,
      contextAndIdStore: { guest, property }
    } = this.props;
    const { isChatRequestFailureHandled, reasonForConnecting } = this.state;
    if (
      status &&
      !reasonForConnecting &&
      (status === "no-staff-online" || status === "decline" || status === "timeout")
    ) {
      this.isWaitingForLocalInput = true;
    }
    const {
      id: pid,
      tenant: { id: tid }
    } = property as Property;
    return (
      <Body>
        <MuiThemeProvider theme={theme}>
          <Content>
            {status &&
              (status === "connecting" ||
                status === "re-requesting" ||
                status === "no-staff-online" ||
                status === "decline" ||
                status === "timeout") && (
                <Fragment>
                  <Grow in={true}>
                    <MessageWrapper {...{ self: false }}>
                      <Logo src="/logo-circle-180x180.png" />
                      <StyledTextBubble self={false}>
                        <Typography component="pre">
                          Please wait while we are trying to connect you with someone.
                        </Typography>
                      </StyledTextBubble>
                    </MessageWrapper>
                  </Grow>
                  {(status === "connecting" || status === "re-requesting") && (
                    <Fragment>
                      <Grow in={true}>
                        <MessageWrapper {...{ self: false }}>
                          <EmptyDiv />
                          <StyledTextBubble self={false}>
                            <Typography component="pre">
                              <ProgressWrapper>
                                <CircularProgress color="inherit" />
                              </ProgressWrapper>
                            </Typography>
                          </StyledTextBubble>
                        </MessageWrapper>
                      </Grow>
                    </Fragment>
                  )}
                  {(status === "no-staff-online" || status === "decline" || status === "timeout") && (
                    <Grow in={true}>
                      <MessageWrapper {...{ self: false }}>
                        <EmptyDiv />
                        <StyledTextBubble self={false}>
                          <Typography component="pre">Connection failed.</Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    </Grow>
                  )}
                  {status === "no-staff-online" && (
                    <Grow in={true}>
                      <MessageWrapper {...{ self: false }}>
                        <EmptyDiv />
                        <StyledTextBubble self={false}>
                          <Typography component="pre">
                            Looks like there's no staff online to chat with you at the moment.
                          </Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    </Grow>
                  )}
                  {(status === "decline" || status === "timeout") && (
                    <Grow in={true}>
                      <MessageWrapper {...{ self: false }}>
                        <EmptyDiv />
                        <StyledTextBubble self={false}>
                          <Typography component="pre">
                            Looks like all our staffs are busy and can't chat with you at the moment.
                          </Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    </Grow>
                  )}
                  {(status === "no-staff-online" || status === "decline" || status === "timeout") && (
                    <Grow in={true}>
                      <MessageWrapper {...{ self: false }}>
                        <EmptyDiv />
                        <StyledTextBubble self={false}>
                          <Typography component="pre">
                            Could you share your reason for connecting with a staff? We'll get back to you ASAP about
                            the same.
                          </Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    </Grow>
                  )}

                  {reasonForConnecting && (
                    <Grow in={true}>
                      <MessageWrapper {...{ self: true }}>
                        <StyledTextBubble self={true}>
                          <Typography component="pre">{reasonForConnecting}</Typography>
                        </StyledTextBubble>
                      </MessageWrapper>
                    </Grow>
                  )}
                  {isChatRequestFailureHandled && (
                    <Fragment>
                      <Grow in={true}>
                        <MessageWrapper {...{ self: false }}>
                          <EmptyDiv />
                          <StyledTextBubble self={false}>
                            <Typography component="pre">
                              {`We have brought your request to chat with us to all of our staffs attention. Someone might get back to you anytime soon.`}
                            </Typography>
                          </StyledTextBubble>
                        </MessageWrapper>
                      </Grow>
                      <OptionButtons
                        // NOTE: If anyone using this screen handle the baseUrl properly
                        baseUrl=""
                        optionMessage={
                          {
                            options: [
                              {
                                internalRoute: `/gcp/${tid}/${pid}`,
                                label: "TAKE ME BACK",
                                value: "Cool! Take me back."
                              } as IOption,
                              {
                                internalRoute: "/chat-with-us/retry",
                                label: "RETRY",
                                value: "Ok. I wanna retry."
                              }
                            ]
                          } as IOptionMessage
                        }
                        variant="pill"
                        transitionDelay={1000}
                        handleClick={() => {}}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
            {activeChat && (
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={async (page: number) => {
                  await activeChat.fetchNextPage(page);
                  this.forceUpdate();
                }}
                hasMore={activeChat.noOfLastLoadRecords === activeChat.noOfRecords}
                loader={
                  <ProgressWrapper key={0}>
                    <CircularProgress color="primary" />
                  </ProgressWrapper>
                }
                isReverse={true}
                threshold={50}
                useWindow={false}
              >
                {messages &&
                  messages.length > 0 &&
                  messages.map(
                    (message: PeerMessage, index: number) =>
                      message.type === "PeerMessage" && (
                        <Grow in={true}>
                          <MessageWrapper key={`${message.sesid}${index}`} {...{ self: message.sender === `${guest}` }}>
                            <StyledTextBubble self={message.sender === `${guest}`}>
                              <Typography component="pre">{message.text}</Typography>
                            </StyledTextBubble>
                          </MessageWrapper>
                        </Grow>
                      )
                  )}
              </InfiniteScroll>
            )}

            <MessagesEnd ref={this.messagesEnd} />
            {(session || this.isWaitingForLocalInput) && (
              <Paper className={classes.root} elevation={1}>
                <InputBase
                  className={classes.input}
                  placeholder="Enter your message here"
                  {...(this.isWaitingForLocalInput && { multiline: true, rows: 3, rowsMax: 3 })}
                  fullWidth
                  value={this.state.textBoxContent}
                  onChange={this.handleTyping}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="Message box"
                  disabled={this.state.textBoxContent.trim().length === 0}
                  color="primary"
                  onClick={this.handleSendMessage}
                >
                  <SendIcon />
                </IconButton>
              </Paper>
            )}
            {this.isChatCompleted && (
              <Fragment>
                <br />
                <StyledPaper elevation={1}>
                  <Typography variant="h5" component="h3">
                    Your chat session is closed by the staff.
                  </Typography>
                  <Typography component="p">
                    Did you miss something? You have any other queries? You want other updates? Don't worry you can
                    always start a new chat.
                  </Typography>
                </StyledPaper>
                <br />
                <StyledFullButton
                  variant="contained"
                  color="secondary"
                  component={({ innerRef, ...props }) => <Link {...props} to={`/gcp/${tid}/${pid}`} />}
                >
                  TAKE ME BACK
                </StyledFullButton>
                <br />
                <StyledFullButton
                  variant="contained"
                  color="secondary"
                  component={({ innerRef, ...props }) => <Link {...props} to="/chat-with-us/retry" />}
                >
                  CHAT WITH US
                </StyledFullButton>
              </Fragment>
            )}
          </Content>
        </MuiThemeProvider>
      </Body>
    );
  }
}

export default withStyles(styles)(MessagingScreen);
