import React, { Component, ErrorInfo } from "react";

import { Button, Paper, Typography } from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";

import styled from "../../styles/styled-components";

const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const StyledPaper = styled(Paper as React.SFC<PaperProps>)`
  && {
    padding: 16px 24px;
  }
`;

export interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    // TODO: log the error to our error logging service
  }

  public handleRefresh = () => {
    window.location.reload();
  };

  public render() {
    return this.state.hasError ? (
      <div>
        <Body>
          <br />
          <StyledPaper elevation={1}>
            <Typography variant="h5" component="h3">
              Something went wrong!
            </Typography>
            <Typography component="p">When life gives you problems, click the damn refresh button.</Typography>
          </StyledPaper>
          <br />
          <Button onClick={this.handleRefresh} variant="contained" color="secondary" fullWidth>
            REFRESH
          </Button>
        </Body>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
