import React, { Component } from "react";

import { Paper, Typography } from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";
import { ThemedStyledProps } from "styled-components";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";

import { PatchedTypes } from "messages";

import moment from "moment";

const Logo = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.background};
`;

const StyledPaper = styled(Paper as React.SFC<PaperProps>)`
  padding: 20px;
`;

export interface IHomeScreenState {
  url: string;
}

class HomeScreen extends Component<{}, IHomeScreenState> {
  public state: IHomeScreenState = {
    url: ""
  };
  public componentWillMount() {
    const bookingString = localStorage.getItem("BOOKING");

    if (!bookingString) {
      return;
    }
    try {
      const booking: PatchedTypes.IHotelBooking = JSON.parse(bookingString) as PatchedTypes.IHotelBooking;
      const checkOutDate = booking.checkOutDate;
      if (moment().diff(checkOutDate as number, "days") <= 1 && booking.tid && booking.pid) {
        // Navigate the user to the correct URL by setting up the state
        this.setState({ url: `/${booking.tid}/${booking.pid}` });
      }
    } catch (error) {
      return;
    }
  }

  public render() {
    return (
      <Body>
        {this.state.url && this.state.url !== "" && <Redirect to={this.state.url} />}
        <Logo src="/logo-circle-180x180.png" />
        <StyledPaper>
          <Typography variant="headline">Oops! You shouldn't be here!</Typography>
          <Typography variant="subheading">
            For using this app properly you should click on the link mentioned on your welcome email/SMS.
          </Typography>
          <Typography variant="subtitle2">Hope to see you around.</Typography>
        </StyledPaper>
      </Body>
    );
  }
}

export default HomeScreen;
