import { PatchedTypes } from "messages";
import {
  BotMessage,
  Guest,
  GuestChatSession,
  GuestNotification,
  GuestRoster,
  NoStaffOnline,
  Option,
  OptionMessage,
  PeerMessage,
  Property,
  ProxyGuestChatDecline,
  ProxyGuestChatRequestTimeout
} from "messaging";
import React, { Component, Context, createContext } from "react";
import { ActiveChat, BotChat } from "../models";
import { ApiService, GuestClientService } from "../services";
import { AuthContext, IAuth } from "./AuthContext";

export enum ONBOARDING {
  WELCOME = "welcome_user",
  WELCOME_BACK = "welcome_back",
  SERVICE_REQUEST_TIMEOUT = "welcome_after_timeout"
}

const StateContext: Context<IState> = createContext({} as IState);
const { Consumer, Provider } = StateContext;

export type IState = IStateProviderState & {
  createActiveChat: (session: GuestChatSession) => void;
  // handleBotMessage: (message: BotMessage) => void;
  initStateContext: (guestStrCtx: string, propertyStrCtx: string, bookingNo: string) => void;
  markAllNotificationsAsRead: () => void;
  resetActiveChat: () => void;
  setChatOpenOnUI: (chat: string) => void;
  setGuest: (guest: string) => void;
  setGuestClientServiceError: (error: Error) => void;
  setProperty: (property: string) => void;
  subscribeToTopics: (guest: Guest, property: Property) => void;
  setStatus: (status: string) => void;
};

interface IStateProviderState {
  activeChat?: ActiveChat;
  botChat: BotChat;
  chatOpenOnUI: string;
  guest?: Guest;
  isSubscribedToBotTopics: boolean;
  notification: number;
  property?: Property;
  session?: GuestChatSession | null;
  serviceRequests: Map<string, PatchedTypes.IServiceRequest>;
  status?: string;
  guestClientServiceError?: Error;
  bookingNo?: string;
  roomNo?: string;
}

interface IStateProviderProps {}

class StateProvider extends Component<IStateProviderProps, IStateProviderState> {
  public static contextType: Context<IAuth> = AuthContext;
  public state: IStateProviderState = {
    botChat: new BotChat(),
    chatOpenOnUI: "",
    isSubscribedToBotTopics: false,
    notification: 0,
    serviceRequests: new Map()
  };

  public notificationTone = new Audio("/media/plucky.mp3");

  constructor(props: IStateProviderProps) {
    super(props);
    const pid = localStorage.getItem("PID");
    const tid = localStorage.getItem("TID");
    const bookingNo = localStorage.getItem("BOOKING_NO");
    if (pid && tid && bookingNo) {
      // NOTE: for now gid === bookingNo
      const gid = bookingNo;
      const propertyStrCtx = `t/${tid}/p/${pid}`;
      const guestStrCtx = `t/${tid}/p/${pid}/g/${gid}`;
      this.initStateContext(guestStrCtx, propertyStrCtx, bookingNo);
    }
  }

  public initStateContext = (guestStrCtx: string, propertyStrCtx: string, bookingNo: string) => {
    const guest = Guest.fromString(guestStrCtx);
    const property = Property.fromString(propertyStrCtx);
    this.subscribeToTopics(guest, property).then(() => {
      this.setState({ bookingNo, guest, property });
    });
    const {
      id: pid,
      tenant: { id: tid }
    } = property;
    ApiService.getServiceRequests(tid, pid, bookingNo).then((serviceRequests: PatchedTypes.IServiceRequest[]) => {
      const requests = new Map();
      serviceRequests.map((serviceRequest: PatchedTypes.IServiceRequest) =>
        requests.set(serviceRequest.serreqid, serviceRequest)
      );
      this.setState({ serviceRequests: requests });
    });
  };

  public shouldComponentUpdate(nextProps: {}, nextState: IStateProviderState) {
    const { guestClientServiceError } = nextState;
    if (guestClientServiceError) {
      throw guestClientServiceError;
    }

    return true;
  }

  public createActiveChat = (session: GuestChatSession) => {
    const { sesid } = session;
    this.setState({ activeChat: new ActiveChat(sesid) });
  };

  public handleBotMessage = (message: BotMessage) => {
    const { booking }: IAuth = this.context;
    const { text, serviceListMessage } = message;
    if (serviceListMessage && booking && !booking.roomNo) {
      const filteredOptions = serviceListMessage.options.filter((option: Option) => option.label === "Talk to Staff");
      message.serviceListMessage = new OptionMessage({ options: filteredOptions });
    }
    if (
      text !== ONBOARDING.WELCOME &&
      text !== ONBOARDING.WELCOME_BACK &&
      text !== ONBOARDING.SERVICE_REQUEST_TIMEOUT
    ) {
      const { botChat } = this.state;
      const chat = botChat;
      chat.addMessage(message);
      this.setState({ botChat: chat });
    }
  };

  public markAllNotificationsAsRead = () => {
    this.setState({ notification: 0 });
  };

  public resetActiveChat = () => {
    localStorage.removeItem("ACTIVE_CHAT");
    this.setState({ activeChat: undefined });
  };

  public setChatOpenOnUI = (chat: string) => {
    this.setState({ chatOpenOnUI: chat });
  };

  public setGuest = (guest: string) => {
    this.setState({ guest: Guest.fromString(guest) });
  };

  public setGuestClientServiceError = (error: Error) => {
    this.setState({ guestClientServiceError: error });
  };

  public setProperty = (property: string) => {
    this.setState({ property: Property.fromString(property) });
  };

  public subscribeToTopics = async (guest: Guest, property: Property) => {
    const guestClient = await GuestClientService.getInstance((error: Error) => {
      this.setState({ guestClientServiceError: error });
    });
    guestClient.acceptNoStaffOnline(guest, this.handleNoStaffOnline);
    guestClient.acceptNotification(guest, this.handleNotification);
    guestClient.acceptGuestRoster(guest, this.handleGuestRoster);
    guestClient.acceptProxyGuestChatDecline(guest, this.handleDecline);
    guestClient.acceptProxyGuestChatRequestTimeout(guest, this.handleTimeout);
  };

  public setStatus = (status: string) => {
    this.setState({ status });
  };

  public render() {
    return (
      <Provider
        value={{
          ...this.state,
          createActiveChat: this.createActiveChat,
          // handleBotMessage: this.handleBotMessage,
          initStateContext: this.initStateContext,
          markAllNotificationsAsRead: this.markAllNotificationsAsRead,
          resetActiveChat: this.resetActiveChat,
          setChatOpenOnUI: this.setChatOpenOnUI,
          setGuest: this.setGuest,
          setGuestClientServiceError: this.setGuestClientServiceError,
          setProperty: this.setProperty,
          setStatus: this.setStatus,
          subscribeToTopics: this.subscribeToTopics
        }}
      >
        {this.props.children}
      </Provider>
    );
  }

  private acceptMessage = async ({ sesid, property }: GuestChatSession) => {
    const guestClient = await GuestClientService.getInstance((error: Error) =>
      this.setState({ guestClientServiceError: error })
    );
    guestClient.acceptMessage(Property.fromString(property), this.handleMessage, `/${sesid}${PeerMessage.suffix}`);
    this.setState({ status: "success" });
  };

  private unacceptMessage = async ({ sesid, property }: GuestChatSession) => {
    const guestClient = await GuestClientService.getInstance((error: Error) => {
      this.setState({ guestClientServiceError: error });
    });
    guestClient.unacceptMessage(Property.fromString(property), `/${sesid}${PeerMessage.suffix}`);
  };

  private handleMessage = (message: PeerMessage) => {
    const { activeChat, chatOpenOnUI } = this.state;
    const { sesid } = message;
    const chat = activeChat || new ActiveChat(sesid);
    if (chatOpenOnUI === "STAFF_CHAT") {
      chat.addMessage(message);
    } else {
      chat.addUnreadMessage(message);
    }
    this.setState({ activeChat: chat });
  };

  private handleNotification = (message: GuestNotification) => {
    const {
      data: { request }
    } = message;
    if (request) {
      const { notification, serviceRequests } = this.state;
      this.setState({ notification: notification + 1 });
      this.notificationTone.play();
      const { serreqid } = request;
      serviceRequests.set(serreqid, request as PatchedTypes.IServiceRequest);
      this.setState({ serviceRequests });
    }
  };

  private handleDecline = (message: ProxyGuestChatDecline) => {
    this.setState({ status: "decline" });
  };

  private handleGuestRoster = (roster: GuestRoster) => {
    const { session } = roster;
    if (!session && this.state.session) {
      this.unacceptMessage(this.state.session);
    } else if (session) {
      this.acceptMessage(session);
      this.createActiveChat(session);
    }
    this.setState({ session });
  };

  private handleNoStaffOnline = (message: NoStaffOnline) => {
    this.setState({ status: "no-staff-online" });
  };

  private handleTimeout = (message: ProxyGuestChatRequestTimeout) => {
    this.setState({ status: "timeout" });
  };
}

export { StateProvider, Consumer as StateConsumer, StateContext };
