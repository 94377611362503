export type NotificationToneType =
  | "bot.message.send"
  | "bot.message.receive"
  | "chat.message.send"
  | "chat.message.receive";

export type NotificationToneOptions = {
  [k in NotificationToneType]: string | undefined;
};

export const DEFAULT_NOTIFICATION_TONE_OPTIONS: NotificationToneOptions = {
  "bot.message.receive": "intuition",
  "bot.message.send": "your-turn",
  "chat.message.receive": "for-sure",
  "chat.message.send": "pull-out"
};
