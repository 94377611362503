import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  createStyles,
  MobileStepper,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import NextIcon from "@material-ui/icons/KeyboardArrowRight";
import { IFormView, IInputElementMessage } from "messaging";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import SwipeableViews from "react-swipeable-views";
import { ThemedStyledProps } from "styled-components";
import { FormControlButton } from "./index";
import { DynamicFormStore } from "../../stores/index";
import styled from "../../styles/styled-components";
import { ITheme } from "../../styles/theme";

const styles = ({ palette: { background }, spacing: { unit } }: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: unit * 2,
      paddingRight: unit * 2,
      backgroundColor: background.default
    },
    root: {
      borderRadius: 10,
      marginLeft: 48,
      maxWidth: 500
    }
  });

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: ThemedStyledProps<{}, ITheme>) => props.theme.colorPalette.ordaapRed};
`;

export interface IFormProps extends WithStyles<typeof styles, true> {
  dynamicFormStore?: DynamicFormStore;
  formView: IFormView;
  onFormSubmitHandler(text: string, gallery?: boolean, data?: string): void;
}

export interface IFormState {
  activeStep: number;
}

@inject("dynamicFormStore")
@observer
class Form extends Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }

  public componentDidMount = () => {
    const {
      dynamicFormStore,
      formView: { formControls }
    } = this.props;
    const { clearForm, initFormData } = dynamicFormStore!;
    clearForm();
    initFormData(formControls!);
  };

  public handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  public handleStepChange = (activeStep: number) => {
    this.setState({ activeStep });
  };

  public onFormSubmit = async () => {
    const { dynamicFormStore, onFormSubmitHandler } = this.props;
    const { clearForm, formData, getFormDataAsText, uploadFiles } = dynamicFormStore!;
    await uploadFiles();
    onFormSubmitHandler(getFormDataAsText(), undefined, formData);
    clearForm();
  };

  public render() {
    const {
      classes,
      dynamicFormStore,
      formView: { formControls, title }
    } = this.props;
    const { isUploadingFiles } = dynamicFormStore!;
    const { theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = formControls && formControls.length ? formControls.length : 0;
    return (
      <Fragment>
        <Dialog open={isUploadingFiles}>
          <DialogTitle>Uploading</DialogTitle>
          <DialogContent>
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          </DialogContent>
        </Dialog>
        <Card className={classes.root}>
          <Paper square elevation={0} className={classes.header}>
            <Typography>{title}</Typography>
          </Paper>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
          >
            {formControls &&
              formControls.length > 0 &&
              formControls.map((control: IInputElementMessage, index: number) => (
                <Fragment key={`FormControl#${index}`}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <FormControlButton key={`form-control-${index}`} input={control} />
                  ) : null}
                  {index === formControls.length - 1 && (
                    <Paper square elevation={0} className={classes.header}>
                      <Button fullWidth variant="outlined" color="primary" onClick={this.onFormSubmit}>
                        SUBMIT
                      </Button>
                    </Paper>
                  )}
                </Fragment>
              ))}
          </SwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                {theme.direction === "rtl" ? <BackIcon /> : <NextIcon />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? <NextIcon /> : <BackIcon />}
                Back
              </Button>
            }
          />
        </Card>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Form);
