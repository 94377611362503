/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */
  if (typeof define === 'function' && define.amd) define(["protobufjs/minimal"], factory);
  /* CommonJS */
  else if (typeof require === 'function' && typeof module === 'object' && module && module.exports) module.exports = factory(require("protobufjs/minimal"));
})(this, function ($protobuf) {
  "use strict"; // Common aliases

  var $Reader = $protobuf.Reader,
      $Writer = $protobuf.Writer,
      $util = $protobuf.util; // Exported root namespace

  var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

  $root.Peers = function () {
    /**
     * Properties of a Peers.
     * @exports IPeers
     * @interface IPeers
     * @property {string|null} [guest] Peers guest
     * @property {string|null} [staff] Peers staff
     */

    /**
     * Constructs a new Peers.
     * @exports Peers
     * @classdesc Represents a Peers.
     * @implements IPeers
     * @constructor
     * @param {IPeers=} [properties] Properties to set
     */
    function Peers(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * Peers guest.
     * @member {string} guest
     * @memberof Peers
     * @instance
     */


    Peers.prototype.guest = "";
    /**
     * Peers staff.
     * @member {string} staff
     * @memberof Peers
     * @instance
     */

    Peers.prototype.staff = "";
    /**
     * Encodes the specified Peers message. Does not implicitly {@link Peers.verify|verify} messages.
     * @function encode
     * @memberof Peers
     * @static
     * @param {Peers} message Peers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    Peers.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.guest != null && message.hasOwnProperty("guest")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.guest);
      if (message.staff != null && message.hasOwnProperty("staff")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.staff);
      return writer;
    };
    /**
     * Decodes a Peers message from the specified reader or buffer.
     * @function decode
     * @memberof Peers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Peers} Peers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    Peers.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.Peers();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 1:
            message.guest = reader.string();
            break;

          case 2:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a Peers message.
     * @function verify
     * @memberof Peers
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    Peers.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.guest != null && message.hasOwnProperty("guest")) if (!$util.isString(message.guest)) return "guest: string expected";
      if (message.staff != null && message.hasOwnProperty("staff")) if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return Peers;
  }();

  $root.PeerHello = function () {
    /**
     * Properties of a PeerHello.
     * @exports IPeerHello
     * @interface IPeerHello
     * @property {Peers} peers PeerHello peers
     * @property {string} property PeerHello property
     * @property {string} sender PeerHello sender
     * @property {string} sesid PeerHello sesid
     */

    /**
     * Constructs a new PeerHello.
     * @exports PeerHello
     * @classdesc Represents a PeerHello.
     * @implements IPeerHello
     * @constructor
     * @param {IPeerHello=} [properties] Properties to set
     */
    function PeerHello(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * PeerHello peers.
     * @member {Peers} peers
     * @memberof PeerHello
     * @instance
     */


    PeerHello.prototype.peers = null;
    /**
     * PeerHello property.
     * @member {string} property
     * @memberof PeerHello
     * @instance
     */

    PeerHello.prototype.property = "";
    /**
     * PeerHello sender.
     * @member {string} sender
     * @memberof PeerHello
     * @instance
     */

    PeerHello.prototype.sender = "";
    /**
     * PeerHello sesid.
     * @member {string} sesid
     * @memberof PeerHello
     * @instance
     */

    PeerHello.prototype.sesid = "";
    /**
     * Encodes the specified PeerHello message. Does not implicitly {@link PeerHello.verify|verify} messages.
     * @function encode
     * @memberof PeerHello
     * @static
     * @param {PeerHello} message PeerHello message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    PeerHello.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.Peers.encode(message.peers, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.property);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.sender);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.sesid);
      return writer;
    };
    /**
     * Decodes a PeerHello message from the specified reader or buffer.
     * @function decode
     * @memberof PeerHello
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PeerHello} PeerHello
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    PeerHello.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.PeerHello();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.peers = $root.Peers.decode(reader, reader.uint32());
            break;

          case 1:
            message.property = reader.string();
            break;

          case 2:
            message.sender = reader.string();
            break;

          case 3:
            message.sesid = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("peers")) throw $util.ProtocolError("missing required 'peers'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      if (!message.hasOwnProperty("sender")) throw $util.ProtocolError("missing required 'sender'", {
        instance: message
      });
      if (!message.hasOwnProperty("sesid")) throw $util.ProtocolError("missing required 'sesid'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a PeerHello message.
     * @function verify
     * @memberof PeerHello
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    PeerHello.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.Peers.verify(message.peers);
        if (error) return "peers." + error;
      }
      if (!$util.isString(message.property)) return "property: string expected";
      if (!$util.isString(message.sender)) return "sender: string expected";
      if (!$util.isString(message.sesid)) return "sesid: string expected";
      return null;
    };

    return PeerHello;
  }();

  $root.PeerMessage = function () {
    /**
     * Properties of a PeerMessage.
     * @exports IPeerMessage
     * @interface IPeerMessage
     * @property {string} text PeerMessage text
     * @property {string} property PeerMessage property
     * @property {string} sender PeerMessage sender
     * @property {string} sesid PeerMessage sesid
     * @property {string} msgid PeerMessage msgid
     * @property {OptionMessage|null} [optionMessage] PeerMessage optionMessage
     */

    /**
     * Constructs a new PeerMessage.
     * @exports PeerMessage
     * @classdesc Represents a PeerMessage.
     * @implements IPeerMessage
     * @constructor
     * @param {IPeerMessage=} [properties] Properties to set
     */
    function PeerMessage(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * PeerMessage text.
     * @member {string} text
     * @memberof PeerMessage
     * @instance
     */


    PeerMessage.prototype.text = "";
    /**
     * PeerMessage property.
     * @member {string} property
     * @memberof PeerMessage
     * @instance
     */

    PeerMessage.prototype.property = "";
    /**
     * PeerMessage sender.
     * @member {string} sender
     * @memberof PeerMessage
     * @instance
     */

    PeerMessage.prototype.sender = "";
    /**
     * PeerMessage sesid.
     * @member {string} sesid
     * @memberof PeerMessage
     * @instance
     */

    PeerMessage.prototype.sesid = "";
    /**
     * PeerMessage msgid.
     * @member {string} msgid
     * @memberof PeerMessage
     * @instance
     */

    PeerMessage.prototype.msgid = "";
    /**
     * PeerMessage optionMessage.
     * @member {OptionMessage|null|undefined} optionMessage
     * @memberof PeerMessage
     * @instance
     */

    PeerMessage.prototype.optionMessage = null;
    /**
     * Encodes the specified PeerMessage message. Does not implicitly {@link PeerMessage.verify|verify} messages.
     * @function encode
     * @memberof PeerMessage
     * @static
     * @param {PeerMessage} message PeerMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    PeerMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.text);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.property);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.sender);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.sesid);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.msgid);
      if (message.optionMessage != null && message.hasOwnProperty("optionMessage")) $root.OptionMessage.encode(message.optionMessage, writer.uint32(
      /* id 5, wireType 2 =*/
      42).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a PeerMessage message from the specified reader or buffer.
     * @function decode
     * @memberof PeerMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PeerMessage} PeerMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    PeerMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.PeerMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.text = reader.string();
            break;

          case 1:
            message.property = reader.string();
            break;

          case 2:
            message.sender = reader.string();
            break;

          case 3:
            message.sesid = reader.string();
            break;

          case 4:
            message.msgid = reader.string();
            break;

          case 5:
            message.optionMessage = $root.OptionMessage.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("text")) throw $util.ProtocolError("missing required 'text'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      if (!message.hasOwnProperty("sender")) throw $util.ProtocolError("missing required 'sender'", {
        instance: message
      });
      if (!message.hasOwnProperty("sesid")) throw $util.ProtocolError("missing required 'sesid'", {
        instance: message
      });
      if (!message.hasOwnProperty("msgid")) throw $util.ProtocolError("missing required 'msgid'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a PeerMessage message.
     * @function verify
     * @memberof PeerMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    PeerMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.text)) return "text: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      if (!$util.isString(message.sender)) return "sender: string expected";
      if (!$util.isString(message.sesid)) return "sesid: string expected";
      if (!$util.isString(message.msgid)) return "msgid: string expected";

      if (message.optionMessage != null && message.hasOwnProperty("optionMessage")) {
        var error = $root.OptionMessage.verify(message.optionMessage);
        if (error) return "optionMessage." + error;
      }

      return null;
    };

    return PeerMessage;
  }();

  $root.BotChatRequest = function () {
    /**
     * Properties of a BotChatRequest.
     * @exports IBotChatRequest
     * @interface IBotChatRequest
     * @property {string} chreqid BotChatRequest chreqid
     * @property {string} guest BotChatRequest guest
     * @property {string} property BotChatRequest property
     */

    /**
     * Constructs a new BotChatRequest.
     * @exports BotChatRequest
     * @classdesc Represents a BotChatRequest.
     * @implements IBotChatRequest
     * @constructor
     * @param {IBotChatRequest=} [properties] Properties to set
     */
    function BotChatRequest(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * BotChatRequest chreqid.
     * @member {string} chreqid
     * @memberof BotChatRequest
     * @instance
     */


    BotChatRequest.prototype.chreqid = "";
    /**
     * BotChatRequest guest.
     * @member {string} guest
     * @memberof BotChatRequest
     * @instance
     */

    BotChatRequest.prototype.guest = "";
    /**
     * BotChatRequest property.
     * @member {string} property
     * @memberof BotChatRequest
     * @instance
     */

    BotChatRequest.prototype.property = "";
    /**
     * Encodes the specified BotChatRequest message. Does not implicitly {@link BotChatRequest.verify|verify} messages.
     * @function encode
     * @memberof BotChatRequest
     * @static
     * @param {BotChatRequest} message BotChatRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    BotChatRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.chreqid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.guest);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      return writer;
    };
    /**
     * Decodes a BotChatRequest message from the specified reader or buffer.
     * @function decode
     * @memberof BotChatRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BotChatRequest} BotChatRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    BotChatRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.BotChatRequest();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.chreqid = reader.string();
            break;

          case 1:
            message.guest = reader.string();
            break;

          case 2:
            message.property = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("chreqid")) throw $util.ProtocolError("missing required 'chreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a BotChatRequest message.
     * @function verify
     * @memberof BotChatRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    BotChatRequest.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.chreqid)) return "chreqid: string expected";
      if (!$util.isString(message.guest)) return "guest: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      return null;
    };

    return BotChatRequest;
  }();

  $root.BotMessage = function () {
    /**
     * Properties of a BotMessage.
     * @exports IBotMessage
     * @interface IBotMessage
     * @property {string|null} [bookingNo] BotMessage bookingNo
     * @property {boolean|null} [continueTyping] BotMessage continueTyping
     * @property {string|null} [gid] BotMessage gid
     * @property {boolean|null} [isPeerStaff] BotMessage isPeerStaff
     * @property {string} property BotMessage property
     * @property {string} sender BotMessage sender
     * @property {string|null} [sfid] BotMessage sfid
     * @property {string} text BotMessage text
     * @property {boolean|null} [gallery] BotMessage gallery
     * @property {InputElementMessage|null} [inputMessage] BotMessage inputMessage
     * @property {OptionMessage|null} [optionMessage] BotMessage optionMessage
     * @property {OptionMessage|null} [serviceListMessage] BotMessage serviceListMessage
     * @property {ServiceRequestNotificationMessage|null} [serviceRequestNotification] BotMessage serviceRequestNotification
     * @property {UploadFilesMessage|null} [uploadFilesMessage] BotMessage uploadFilesMessage
     * @property {string|null} [redirectURL] BotMessage redirectURL
     * @property {CardView|null} [cardView] BotMessage cardView
     * @property {FormView|null} [formView] BotMessage formView
     * @property {string|null} [locale] BotMessage locale
     * @property {string|null} [data] BotMessage data
     */

    /**
     * Constructs a new BotMessage.
     * @exports BotMessage
     * @classdesc Represents a BotMessage.
     * @implements IBotMessage
     * @constructor
     * @param {IBotMessage=} [properties] Properties to set
     */
    function BotMessage(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * BotMessage bookingNo.
     * @member {string} bookingNo
     * @memberof BotMessage
     * @instance
     */


    BotMessage.prototype.bookingNo = "";
    /**
     * BotMessage continueTyping.
     * @member {boolean} continueTyping
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.continueTyping = false;
    /**
     * BotMessage gid.
     * @member {string} gid
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.gid = "";
    /**
     * BotMessage isPeerStaff.
     * @member {boolean} isPeerStaff
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.isPeerStaff = false;
    /**
     * BotMessage property.
     * @member {string} property
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.property = "";
    /**
     * BotMessage sender.
     * @member {string} sender
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.sender = "";
    /**
     * BotMessage sfid.
     * @member {string} sfid
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.sfid = "";
    /**
     * BotMessage text.
     * @member {string} text
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.text = "";
    /**
     * BotMessage gallery.
     * @member {boolean} gallery
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.gallery = false;
    /**
     * BotMessage inputMessage.
     * @member {InputElementMessage|null|undefined} inputMessage
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.inputMessage = null;
    /**
     * BotMessage optionMessage.
     * @member {OptionMessage|null|undefined} optionMessage
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.optionMessage = null;
    /**
     * BotMessage serviceListMessage.
     * @member {OptionMessage|null|undefined} serviceListMessage
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.serviceListMessage = null;
    /**
     * BotMessage serviceRequestNotification.
     * @member {ServiceRequestNotificationMessage|null|undefined} serviceRequestNotification
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.serviceRequestNotification = null;
    /**
     * BotMessage uploadFilesMessage.
     * @member {UploadFilesMessage|null|undefined} uploadFilesMessage
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.uploadFilesMessage = null;
    /**
     * BotMessage redirectURL.
     * @member {string} redirectURL
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.redirectURL = "";
    /**
     * BotMessage cardView.
     * @member {CardView|null|undefined} cardView
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.cardView = null;
    /**
     * BotMessage formView.
     * @member {FormView|null|undefined} formView
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.formView = null;
    /**
     * BotMessage locale.
     * @member {string} locale
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.locale = "";
    /**
     * BotMessage data.
     * @member {string} data
     * @memberof BotMessage
     * @instance
     */

    BotMessage.prototype.data = ""; // OneOf field names bound to virtual getters and setters

    var $oneOfFields;
    /**
     * BotMessage msg.
     * @member {"inputMessage"|"optionMessage"|"serviceListMessage"|"serviceRequestNotification"|"uploadFilesMessage"|"redirectURL"|undefined} msg
     * @memberof BotMessage
     * @instance
     */

    Object.defineProperty(BotMessage.prototype, "msg", {
      get: $util.oneOfGetter($oneOfFields = ["inputMessage", "optionMessage", "serviceListMessage", "serviceRequestNotification", "uploadFilesMessage", "redirectURL"]),
      set: $util.oneOfSetter($oneOfFields)
    });
    /**
     * BotMessage view.
     * @member {"cardView"|"formView"|undefined} view
     * @memberof BotMessage
     * @instance
     */

    Object.defineProperty(BotMessage.prototype, "view", {
      get: $util.oneOfGetter($oneOfFields = ["cardView", "formView"]),
      set: $util.oneOfSetter($oneOfFields)
    });
    /**
     * Encodes the specified BotMessage message. Does not implicitly {@link BotMessage.verify|verify} messages.
     * @function encode
     * @memberof BotMessage
     * @static
     * @param {BotMessage} message BotMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    BotMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.bookingNo);
      if (message.continueTyping != null && message.hasOwnProperty("continueTyping")) writer.uint32(
      /* id 1, wireType 0 =*/
      8).bool(message.continueTyping);
      if (message.gid != null && message.hasOwnProperty("gid")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.gid);
      if (message.isPeerStaff != null && message.hasOwnProperty("isPeerStaff")) writer.uint32(
      /* id 3, wireType 0 =*/
      24).bool(message.isPeerStaff);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.property);
      writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.sender);
      if (message.sfid != null && message.hasOwnProperty("sfid")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.sfid);
      writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.text);
      if (message.gallery != null && message.hasOwnProperty("gallery")) writer.uint32(
      /* id 8, wireType 0 =*/
      64).bool(message.gallery);
      if (message.inputMessage != null && message.hasOwnProperty("inputMessage")) $root.InputElementMessage.encode(message.inputMessage, writer.uint32(
      /* id 9, wireType 2 =*/
      74).fork()).ldelim();
      if (message.optionMessage != null && message.hasOwnProperty("optionMessage")) $root.OptionMessage.encode(message.optionMessage, writer.uint32(
      /* id 10, wireType 2 =*/
      82).fork()).ldelim();
      if (message.serviceListMessage != null && message.hasOwnProperty("serviceListMessage")) $root.OptionMessage.encode(message.serviceListMessage, writer.uint32(
      /* id 11, wireType 2 =*/
      90).fork()).ldelim();
      if (message.serviceRequestNotification != null && message.hasOwnProperty("serviceRequestNotification")) $root.ServiceRequestNotificationMessage.encode(message.serviceRequestNotification, writer.uint32(
      /* id 12, wireType 2 =*/
      98).fork()).ldelim();
      if (message.uploadFilesMessage != null && message.hasOwnProperty("uploadFilesMessage")) $root.UploadFilesMessage.encode(message.uploadFilesMessage, writer.uint32(
      /* id 13, wireType 2 =*/
      106).fork()).ldelim();
      if (message.cardView != null && message.hasOwnProperty("cardView")) $root.CardView.encode(message.cardView, writer.uint32(
      /* id 14, wireType 2 =*/
      114).fork()).ldelim();
      if (message.redirectURL != null && message.hasOwnProperty("redirectURL")) writer.uint32(
      /* id 15, wireType 2 =*/
      122).string(message.redirectURL);
      if (message.locale != null && message.hasOwnProperty("locale")) writer.uint32(
      /* id 16, wireType 2 =*/
      130).string(message.locale);
      if (message.formView != null && message.hasOwnProperty("formView")) $root.FormView.encode(message.formView, writer.uint32(
      /* id 17, wireType 2 =*/
      138).fork()).ldelim();
      if (message.data != null && message.hasOwnProperty("data")) writer.uint32(
      /* id 18, wireType 2 =*/
      146).string(message.data);
      return writer;
    };
    /**
     * Decodes a BotMessage message from the specified reader or buffer.
     * @function decode
     * @memberof BotMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BotMessage} BotMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    BotMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.BotMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.bookingNo = reader.string();
            break;

          case 1:
            message.continueTyping = reader.bool();
            break;

          case 2:
            message.gid = reader.string();
            break;

          case 3:
            message.isPeerStaff = reader.bool();
            break;

          case 4:
            message.property = reader.string();
            break;

          case 5:
            message.sender = reader.string();
            break;

          case 6:
            message.sfid = reader.string();
            break;

          case 7:
            message.text = reader.string();
            break;

          case 8:
            message.gallery = reader.bool();
            break;

          case 9:
            message.inputMessage = $root.InputElementMessage.decode(reader, reader.uint32());
            break;

          case 10:
            message.optionMessage = $root.OptionMessage.decode(reader, reader.uint32());
            break;

          case 11:
            message.serviceListMessage = $root.OptionMessage.decode(reader, reader.uint32());
            break;

          case 12:
            message.serviceRequestNotification = $root.ServiceRequestNotificationMessage.decode(reader, reader.uint32());
            break;

          case 13:
            message.uploadFilesMessage = $root.UploadFilesMessage.decode(reader, reader.uint32());
            break;

          case 15:
            message.redirectURL = reader.string();
            break;

          case 14:
            message.cardView = $root.CardView.decode(reader, reader.uint32());
            break;

          case 17:
            message.formView = $root.FormView.decode(reader, reader.uint32());
            break;

          case 16:
            message.locale = reader.string();
            break;

          case 18:
            message.data = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      if (!message.hasOwnProperty("sender")) throw $util.ProtocolError("missing required 'sender'", {
        instance: message
      });
      if (!message.hasOwnProperty("text")) throw $util.ProtocolError("missing required 'text'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a BotMessage message.
     * @function verify
     * @memberof BotMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    BotMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      var properties = {};
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";
      if (message.continueTyping != null && message.hasOwnProperty("continueTyping")) if (typeof message.continueTyping !== "boolean") return "continueTyping: boolean expected";
      if (message.gid != null && message.hasOwnProperty("gid")) if (!$util.isString(message.gid)) return "gid: string expected";
      if (message.isPeerStaff != null && message.hasOwnProperty("isPeerStaff")) if (typeof message.isPeerStaff !== "boolean") return "isPeerStaff: boolean expected";
      if (!$util.isString(message.property)) return "property: string expected";
      if (!$util.isString(message.sender)) return "sender: string expected";
      if (message.sfid != null && message.hasOwnProperty("sfid")) if (!$util.isString(message.sfid)) return "sfid: string expected";
      if (!$util.isString(message.text)) return "text: string expected";
      if (message.gallery != null && message.hasOwnProperty("gallery")) if (typeof message.gallery !== "boolean") return "gallery: boolean expected";

      if (message.inputMessage != null && message.hasOwnProperty("inputMessage")) {
        properties.msg = 1;
        {
          var error = $root.InputElementMessage.verify(message.inputMessage);
          if (error) return "inputMessage." + error;
        }
      }

      if (message.optionMessage != null && message.hasOwnProperty("optionMessage")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.OptionMessage.verify(message.optionMessage);
          if (error) return "optionMessage." + error;
        }
      }

      if (message.serviceListMessage != null && message.hasOwnProperty("serviceListMessage")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.OptionMessage.verify(message.serviceListMessage);
          if (error) return "serviceListMessage." + error;
        }
      }

      if (message.serviceRequestNotification != null && message.hasOwnProperty("serviceRequestNotification")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.ServiceRequestNotificationMessage.verify(message.serviceRequestNotification);
          if (error) return "serviceRequestNotification." + error;
        }
      }

      if (message.uploadFilesMessage != null && message.hasOwnProperty("uploadFilesMessage")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        {
          var error = $root.UploadFilesMessage.verify(message.uploadFilesMessage);
          if (error) return "uploadFilesMessage." + error;
        }
      }

      if (message.redirectURL != null && message.hasOwnProperty("redirectURL")) {
        if (properties.msg === 1) return "msg: multiple values";
        properties.msg = 1;
        if (!$util.isString(message.redirectURL)) return "redirectURL: string expected";
      }

      if (message.cardView != null && message.hasOwnProperty("cardView")) {
        properties.view = 1;
        {
          var error = $root.CardView.verify(message.cardView);
          if (error) return "cardView." + error;
        }
      }

      if (message.formView != null && message.hasOwnProperty("formView")) {
        if (properties.view === 1) return "view: multiple values";
        properties.view = 1;
        {
          var error = $root.FormView.verify(message.formView);
          if (error) return "formView." + error;
        }
      }

      if (message.locale != null && message.hasOwnProperty("locale")) if (!$util.isString(message.locale)) return "locale: string expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    return BotMessage;
  }();

  $root.Option = function () {
    /**
     * Properties of an Option.
     * @exports IOption
     * @interface IOption
     * @property {string|null} [icon] Option icon
     * @property {string|null} [internalRoute] Option internalRoute
     * @property {string|null} [externalUrl] Option externalUrl
     * @property {string} label Option label
     * @property {string} value Option value
     */

    /**
     * Constructs a new Option.
     * @exports Option
     * @classdesc Represents an Option.
     * @implements IOption
     * @constructor
     * @param {IOption=} [properties] Properties to set
     */
    function Option(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * Option icon.
     * @member {string} icon
     * @memberof Option
     * @instance
     */


    Option.prototype.icon = "";
    /**
     * Option internalRoute.
     * @member {string} internalRoute
     * @memberof Option
     * @instance
     */

    Option.prototype.internalRoute = "";
    /**
     * Option externalUrl.
     * @member {string} externalUrl
     * @memberof Option
     * @instance
     */

    Option.prototype.externalUrl = "";
    /**
     * Option label.
     * @member {string} label
     * @memberof Option
     * @instance
     */

    Option.prototype.label = "";
    /**
     * Option value.
     * @member {string} value
     * @memberof Option
     * @instance
     */

    Option.prototype.value = "";
    /**
     * Encodes the specified Option message. Does not implicitly {@link Option.verify|verify} messages.
     * @function encode
     * @memberof Option
     * @static
     * @param {Option} message Option message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    Option.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.icon != null && message.hasOwnProperty("icon")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.icon);
      if (message.internalRoute != null && message.hasOwnProperty("internalRoute")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.internalRoute);
      if (message.externalUrl != null && message.hasOwnProperty("externalUrl")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.externalUrl);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.label);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.value);
      return writer;
    };
    /**
     * Decodes an Option message from the specified reader or buffer.
     * @function decode
     * @memberof Option
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Option} Option
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    Option.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.Option();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.icon = reader.string();
            break;

          case 1:
            message.internalRoute = reader.string();
            break;

          case 2:
            message.externalUrl = reader.string();
            break;

          case 3:
            message.label = reader.string();
            break;

          case 4:
            message.value = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("label")) throw $util.ProtocolError("missing required 'label'", {
        instance: message
      });
      if (!message.hasOwnProperty("value")) throw $util.ProtocolError("missing required 'value'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an Option message.
     * @function verify
     * @memberof Option
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    Option.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.icon != null && message.hasOwnProperty("icon")) if (!$util.isString(message.icon)) return "icon: string expected";
      if (message.internalRoute != null && message.hasOwnProperty("internalRoute")) if (!$util.isString(message.internalRoute)) return "internalRoute: string expected";
      if (message.externalUrl != null && message.hasOwnProperty("externalUrl")) if (!$util.isString(message.externalUrl)) return "externalUrl: string expected";
      if (!$util.isString(message.label)) return "label: string expected";
      if (!$util.isString(message.value)) return "value: string expected";
      return null;
    };

    return Option;
  }();

  $root.OptionMessage = function () {
    /**
     * Properties of an OptionMessage.
     * @exports IOptionMessage
     * @interface IOptionMessage
     * @property {Array.<Option>|null} [options] OptionMessage options
     */

    /**
     * Constructs a new OptionMessage.
     * @exports OptionMessage
     * @classdesc Represents an OptionMessage.
     * @implements IOptionMessage
     * @constructor
     * @param {IOptionMessage=} [properties] Properties to set
     */
    function OptionMessage(properties) {
      this.options = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * OptionMessage options.
     * @member {Array.<Option>} options
     * @memberof OptionMessage
     * @instance
     */


    OptionMessage.prototype.options = $util.emptyArray;
    /**
     * Encodes the specified OptionMessage message. Does not implicitly {@link OptionMessage.verify|verify} messages.
     * @function encode
     * @memberof OptionMessage
     * @static
     * @param {OptionMessage} message OptionMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    OptionMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.options != null && message.options.length) for (var i = 0; i < message.options.length; ++i) {
        $root.Option.encode(message.options[i], writer.uint32(
        /* id 0, wireType 2 =*/
        2).fork()).ldelim();
      }
      return writer;
    };
    /**
     * Decodes an OptionMessage message from the specified reader or buffer.
     * @function decode
     * @memberof OptionMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {OptionMessage} OptionMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    OptionMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.OptionMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            if (!(message.options && message.options.length)) message.options = [];
            message.options.push($root.Option.decode(reader, reader.uint32()));
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies an OptionMessage message.
     * @function verify
     * @memberof OptionMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    OptionMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";

      if (message.options != null && message.hasOwnProperty("options")) {
        if (!Array.isArray(message.options)) return "options: array expected";

        for (var i = 0; i < message.options.length; ++i) {
          var error = $root.Option.verify(message.options[i]);
          if (error) return "options." + error;
        }
      }

      return null;
    };

    return OptionMessage;
  }();

  $root.SelectOption = function () {
    /**
     * Properties of a SelectOption.
     * @exports ISelectOption
     * @interface ISelectOption
     * @property {string} label SelectOption label
     * @property {string} value SelectOption value
     */

    /**
     * Constructs a new SelectOption.
     * @exports SelectOption
     * @classdesc Represents a SelectOption.
     * @implements ISelectOption
     * @constructor
     * @param {ISelectOption=} [properties] Properties to set
     */
    function SelectOption(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * SelectOption label.
     * @member {string} label
     * @memberof SelectOption
     * @instance
     */


    SelectOption.prototype.label = "";
    /**
     * SelectOption value.
     * @member {string} value
     * @memberof SelectOption
     * @instance
     */

    SelectOption.prototype.value = "";
    /**
     * Encodes the specified SelectOption message. Does not implicitly {@link SelectOption.verify|verify} messages.
     * @function encode
     * @memberof SelectOption
     * @static
     * @param {SelectOption} message SelectOption message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    SelectOption.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.label);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.value);
      return writer;
    };
    /**
     * Decodes a SelectOption message from the specified reader or buffer.
     * @function decode
     * @memberof SelectOption
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SelectOption} SelectOption
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    SelectOption.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.SelectOption();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.label = reader.string();
            break;

          case 1:
            message.value = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("label")) throw $util.ProtocolError("missing required 'label'", {
        instance: message
      });
      if (!message.hasOwnProperty("value")) throw $util.ProtocolError("missing required 'value'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a SelectOption message.
     * @function verify
     * @memberof SelectOption
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    SelectOption.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.label)) return "label: string expected";
      if (!$util.isString(message.value)) return "value: string expected";
      return null;
    };

    return SelectOption;
  }();

  $root.InputElementMessage = function () {
    /**
     * Properties of an InputElementMessage.
     * @exports IInputElementMessage
     * @interface IInputElementMessage
     * @property {string} type InputElementMessage type
     * @property {string|null} [value] InputElementMessage value
     * @property {string|null} [max] InputElementMessage max
     * @property {string|null} [maxLength] InputElementMessage maxLength
     * @property {string|null} [min] InputElementMessage min
     * @property {string|null} [minLength] InputElementMessage minLength
     * @property {string|null} [placeHolder] InputElementMessage placeHolder
     * @property {string|null} [accept] InputElementMessage accept
     * @property {boolean|null} [disabled] InputElementMessage disabled
     * @property {boolean|null} [required] InputElementMessage required
     * @property {string|null} [error] InputElementMessage error
     * @property {string|null} [label] InputElementMessage label
     * @property {string|null} [pattern] InputElementMessage pattern
     * @property {Array.<SelectOption>|null} [options] InputElementMessage options
     * @property {string|null} [name] InputElementMessage name
     */

    /**
     * Constructs a new InputElementMessage.
     * @exports InputElementMessage
     * @classdesc Represents an InputElementMessage.
     * @implements IInputElementMessage
     * @constructor
     * @param {IInputElementMessage=} [properties] Properties to set
     */
    function InputElementMessage(properties) {
      this.options = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * InputElementMessage type.
     * @member {string} type
     * @memberof InputElementMessage
     * @instance
     */


    InputElementMessage.prototype.type = "";
    /**
     * InputElementMessage value.
     * @member {string} value
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.value = "";
    /**
     * InputElementMessage max.
     * @member {string} max
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.max = "";
    /**
     * InputElementMessage maxLength.
     * @member {string} maxLength
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.maxLength = "";
    /**
     * InputElementMessage min.
     * @member {string} min
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.min = "";
    /**
     * InputElementMessage minLength.
     * @member {string} minLength
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.minLength = "";
    /**
     * InputElementMessage placeHolder.
     * @member {string} placeHolder
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.placeHolder = "";
    /**
     * InputElementMessage accept.
     * @member {string} accept
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.accept = "";
    /**
     * InputElementMessage disabled.
     * @member {boolean} disabled
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.disabled = false;
    /**
     * InputElementMessage required.
     * @member {boolean} required
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.required = false;
    /**
     * InputElementMessage error.
     * @member {string} error
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.error = "";
    /**
     * InputElementMessage label.
     * @member {string} label
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.label = "";
    /**
     * InputElementMessage pattern.
     * @member {string} pattern
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.pattern = "";
    /**
     * InputElementMessage options.
     * @member {Array.<SelectOption>} options
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.options = $util.emptyArray;
    /**
     * InputElementMessage name.
     * @member {string} name
     * @memberof InputElementMessage
     * @instance
     */

    InputElementMessage.prototype.name = "";
    /**
     * Encodes the specified InputElementMessage message. Does not implicitly {@link InputElementMessage.verify|verify} messages.
     * @function encode
     * @memberof InputElementMessage
     * @static
     * @param {InputElementMessage} message InputElementMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    InputElementMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.type);
      if (message.value != null && message.hasOwnProperty("value")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.value);
      if (message.max != null && message.hasOwnProperty("max")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.max);
      if (message.maxLength != null && message.hasOwnProperty("maxLength")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.maxLength);
      if (message.min != null && message.hasOwnProperty("min")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.min);
      if (message.minLength != null && message.hasOwnProperty("minLength")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.minLength);
      if (message.placeHolder != null && message.hasOwnProperty("placeHolder")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.placeHolder);
      if (message.accept != null && message.hasOwnProperty("accept")) writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.accept);
      if (message.disabled != null && message.hasOwnProperty("disabled")) writer.uint32(
      /* id 8, wireType 0 =*/
      64).bool(message.disabled);
      if (message.required != null && message.hasOwnProperty("required")) writer.uint32(
      /* id 9, wireType 0 =*/
      72).bool(message.required);
      if (message.error != null && message.hasOwnProperty("error")) writer.uint32(
      /* id 10, wireType 2 =*/
      82).string(message.error);
      if (message.label != null && message.hasOwnProperty("label")) writer.uint32(
      /* id 11, wireType 2 =*/
      90).string(message.label);
      if (message.pattern != null && message.hasOwnProperty("pattern")) writer.uint32(
      /* id 12, wireType 2 =*/
      98).string(message.pattern);
      if (message.options != null && message.options.length) for (var i = 0; i < message.options.length; ++i) {
        $root.SelectOption.encode(message.options[i], writer.uint32(
        /* id 13, wireType 2 =*/
        106).fork()).ldelim();
      }
      if (message.name != null && message.hasOwnProperty("name")) writer.uint32(
      /* id 14, wireType 2 =*/
      114).string(message.name);
      return writer;
    };
    /**
     * Decodes an InputElementMessage message from the specified reader or buffer.
     * @function decode
     * @memberof InputElementMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InputElementMessage} InputElementMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    InputElementMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.InputElementMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.type = reader.string();
            break;

          case 1:
            message.value = reader.string();
            break;

          case 2:
            message.max = reader.string();
            break;

          case 3:
            message.maxLength = reader.string();
            break;

          case 4:
            message.min = reader.string();
            break;

          case 5:
            message.minLength = reader.string();
            break;

          case 6:
            message.placeHolder = reader.string();
            break;

          case 7:
            message.accept = reader.string();
            break;

          case 8:
            message.disabled = reader.bool();
            break;

          case 9:
            message.required = reader.bool();
            break;

          case 10:
            message.error = reader.string();
            break;

          case 11:
            message.label = reader.string();
            break;

          case 12:
            message.pattern = reader.string();
            break;

          case 13:
            if (!(message.options && message.options.length)) message.options = [];
            message.options.push($root.SelectOption.decode(reader, reader.uint32()));
            break;

          case 14:
            message.name = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("type")) throw $util.ProtocolError("missing required 'type'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an InputElementMessage message.
     * @function verify
     * @memberof InputElementMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    InputElementMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.type)) return "type: string expected";
      if (message.value != null && message.hasOwnProperty("value")) if (!$util.isString(message.value)) return "value: string expected";
      if (message.max != null && message.hasOwnProperty("max")) if (!$util.isString(message.max)) return "max: string expected";
      if (message.maxLength != null && message.hasOwnProperty("maxLength")) if (!$util.isString(message.maxLength)) return "maxLength: string expected";
      if (message.min != null && message.hasOwnProperty("min")) if (!$util.isString(message.min)) return "min: string expected";
      if (message.minLength != null && message.hasOwnProperty("minLength")) if (!$util.isString(message.minLength)) return "minLength: string expected";
      if (message.placeHolder != null && message.hasOwnProperty("placeHolder")) if (!$util.isString(message.placeHolder)) return "placeHolder: string expected";
      if (message.accept != null && message.hasOwnProperty("accept")) if (!$util.isString(message.accept)) return "accept: string expected";
      if (message.disabled != null && message.hasOwnProperty("disabled")) if (typeof message.disabled !== "boolean") return "disabled: boolean expected";
      if (message.required != null && message.hasOwnProperty("required")) if (typeof message.required !== "boolean") return "required: boolean expected";
      if (message.error != null && message.hasOwnProperty("error")) if (!$util.isString(message.error)) return "error: string expected";
      if (message.label != null && message.hasOwnProperty("label")) if (!$util.isString(message.label)) return "label: string expected";
      if (message.pattern != null && message.hasOwnProperty("pattern")) if (!$util.isString(message.pattern)) return "pattern: string expected";

      if (message.options != null && message.hasOwnProperty("options")) {
        if (!Array.isArray(message.options)) return "options: array expected";

        for (var i = 0; i < message.options.length; ++i) {
          var error = $root.SelectOption.verify(message.options[i]);
          if (error) return "options." + error;
        }
      }

      if (message.name != null && message.hasOwnProperty("name")) if (!$util.isString(message.name)) return "name: string expected";
      return null;
    };

    return InputElementMessage;
  }();

  $root.UploadFilesMessage = function () {
    /**
     * Properties of an UploadFilesMessage.
     * @exports IUploadFilesMessage
     * @interface IUploadFilesMessage
     * @property {string} serreqid UploadFilesMessage serreqid
     * @property {boolean|null} [multiple] UploadFilesMessage multiple
     * @property {string|null} [accept] UploadFilesMessage accept
     * @property {number|Long|null} [allowedNoOfFiles] UploadFilesMessage allowedNoOfFiles
     */

    /**
     * Constructs a new UploadFilesMessage.
     * @exports UploadFilesMessage
     * @classdesc Represents an UploadFilesMessage.
     * @implements IUploadFilesMessage
     * @constructor
     * @param {IUploadFilesMessage=} [properties] Properties to set
     */
    function UploadFilesMessage(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * UploadFilesMessage serreqid.
     * @member {string} serreqid
     * @memberof UploadFilesMessage
     * @instance
     */


    UploadFilesMessage.prototype.serreqid = "";
    /**
     * UploadFilesMessage multiple.
     * @member {boolean} multiple
     * @memberof UploadFilesMessage
     * @instance
     */

    UploadFilesMessage.prototype.multiple = false;
    /**
     * UploadFilesMessage accept.
     * @member {string} accept
     * @memberof UploadFilesMessage
     * @instance
     */

    UploadFilesMessage.prototype.accept = "";
    /**
     * UploadFilesMessage allowedNoOfFiles.
     * @member {number|Long} allowedNoOfFiles
     * @memberof UploadFilesMessage
     * @instance
     */

    UploadFilesMessage.prototype.allowedNoOfFiles = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;
    /**
     * Encodes the specified UploadFilesMessage message. Does not implicitly {@link UploadFilesMessage.verify|verify} messages.
     * @function encode
     * @memberof UploadFilesMessage
     * @static
     * @param {UploadFilesMessage} message UploadFilesMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    UploadFilesMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.serreqid);
      if (message.multiple != null && message.hasOwnProperty("multiple")) writer.uint32(
      /* id 1, wireType 0 =*/
      8).bool(message.multiple);
      if (message.accept != null && message.hasOwnProperty("accept")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.accept);
      if (message.allowedNoOfFiles != null && message.hasOwnProperty("allowedNoOfFiles")) writer.uint32(
      /* id 3, wireType 0 =*/
      24).uint64(message.allowedNoOfFiles);
      return writer;
    };
    /**
     * Decodes an UploadFilesMessage message from the specified reader or buffer.
     * @function decode
     * @memberof UploadFilesMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UploadFilesMessage} UploadFilesMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    UploadFilesMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.UploadFilesMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.serreqid = reader.string();
            break;

          case 1:
            message.multiple = reader.bool();
            break;

          case 2:
            message.accept = reader.string();
            break;

          case 3:
            message.allowedNoOfFiles = reader.uint64();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("serreqid")) throw $util.ProtocolError("missing required 'serreqid'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies an UploadFilesMessage message.
     * @function verify
     * @memberof UploadFilesMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    UploadFilesMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.serreqid)) return "serreqid: string expected";
      if (message.multiple != null && message.hasOwnProperty("multiple")) if (typeof message.multiple !== "boolean") return "multiple: boolean expected";
      if (message.accept != null && message.hasOwnProperty("accept")) if (!$util.isString(message.accept)) return "accept: string expected";
      if (message.allowedNoOfFiles != null && message.hasOwnProperty("allowedNoOfFiles")) if (!$util.isInteger(message.allowedNoOfFiles) && !(message.allowedNoOfFiles && $util.isInteger(message.allowedNoOfFiles.low) && $util.isInteger(message.allowedNoOfFiles.high))) return "allowedNoOfFiles: integer|Long expected";
      return null;
    };

    return UploadFilesMessage;
  }();

  $root.ServiceRequestNotificationMessage = function () {
    /**
     * Properties of a ServiceRequestNotificationMessage.
     * @exports IServiceRequestNotificationMessage
     * @interface IServiceRequestNotificationMessage
     * @property {string} serreqid ServiceRequestNotificationMessage serreqid
     * @property {string} title ServiceRequestNotificationMessage title
     * @property {string} body ServiceRequestNotificationMessage body
     * @property {string|null} [icon] ServiceRequestNotificationMessage icon
     */

    /**
     * Constructs a new ServiceRequestNotificationMessage.
     * @exports ServiceRequestNotificationMessage
     * @classdesc Represents a ServiceRequestNotificationMessage.
     * @implements IServiceRequestNotificationMessage
     * @constructor
     * @param {IServiceRequestNotificationMessage=} [properties] Properties to set
     */
    function ServiceRequestNotificationMessage(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ServiceRequestNotificationMessage serreqid.
     * @member {string} serreqid
     * @memberof ServiceRequestNotificationMessage
     * @instance
     */


    ServiceRequestNotificationMessage.prototype.serreqid = "";
    /**
     * ServiceRequestNotificationMessage title.
     * @member {string} title
     * @memberof ServiceRequestNotificationMessage
     * @instance
     */

    ServiceRequestNotificationMessage.prototype.title = "";
    /**
     * ServiceRequestNotificationMessage body.
     * @member {string} body
     * @memberof ServiceRequestNotificationMessage
     * @instance
     */

    ServiceRequestNotificationMessage.prototype.body = "";
    /**
     * ServiceRequestNotificationMessage icon.
     * @member {string} icon
     * @memberof ServiceRequestNotificationMessage
     * @instance
     */

    ServiceRequestNotificationMessage.prototype.icon = "";
    /**
     * Encodes the specified ServiceRequestNotificationMessage message. Does not implicitly {@link ServiceRequestNotificationMessage.verify|verify} messages.
     * @function encode
     * @memberof ServiceRequestNotificationMessage
     * @static
     * @param {ServiceRequestNotificationMessage} message ServiceRequestNotificationMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ServiceRequestNotificationMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.serreqid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.title);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.body);
      if (message.icon != null && message.hasOwnProperty("icon")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.icon);
      return writer;
    };
    /**
     * Decodes a ServiceRequestNotificationMessage message from the specified reader or buffer.
     * @function decode
     * @memberof ServiceRequestNotificationMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ServiceRequestNotificationMessage} ServiceRequestNotificationMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ServiceRequestNotificationMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ServiceRequestNotificationMessage();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.serreqid = reader.string();
            break;

          case 1:
            message.title = reader.string();
            break;

          case 2:
            message.body = reader.string();
            break;

          case 3:
            message.icon = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("serreqid")) throw $util.ProtocolError("missing required 'serreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("title")) throw $util.ProtocolError("missing required 'title'", {
        instance: message
      });
      if (!message.hasOwnProperty("body")) throw $util.ProtocolError("missing required 'body'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ServiceRequestNotificationMessage message.
     * @function verify
     * @memberof ServiceRequestNotificationMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ServiceRequestNotificationMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.serreqid)) return "serreqid: string expected";
      if (!$util.isString(message.title)) return "title: string expected";
      if (!$util.isString(message.body)) return "body: string expected";
      if (message.icon != null && message.hasOwnProperty("icon")) if (!$util.isString(message.icon)) return "icon: string expected";
      return null;
    };

    return ServiceRequestNotificationMessage;
  }();

  $root.Card = function () {
    /**
     * Properties of a Card.
     * @exports ICard
     * @interface ICard
     * @property {string} backgroundImage Card backgroundImage
     * @property {string} title Card title
     * @property {string} desc Card desc
     * @property {string|null} [tag] Card tag
     * @property {string|null} [primaryButton] Card primaryButton
     * @property {string|null} [primaryButtonValue] Card primaryButtonValue
     * @property {string|null} [secondaryButton] Card secondaryButton
     * @property {string|null} [secondaryButtonValue] Card secondaryButtonValue
     */

    /**
     * Constructs a new Card.
     * @exports Card
     * @classdesc Represents a Card.
     * @implements ICard
     * @constructor
     * @param {ICard=} [properties] Properties to set
     */
    function Card(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * Card backgroundImage.
     * @member {string} backgroundImage
     * @memberof Card
     * @instance
     */


    Card.prototype.backgroundImage = "";
    /**
     * Card title.
     * @member {string} title
     * @memberof Card
     * @instance
     */

    Card.prototype.title = "";
    /**
     * Card desc.
     * @member {string} desc
     * @memberof Card
     * @instance
     */

    Card.prototype.desc = "";
    /**
     * Card tag.
     * @member {string} tag
     * @memberof Card
     * @instance
     */

    Card.prototype.tag = "";
    /**
     * Card primaryButton.
     * @member {string} primaryButton
     * @memberof Card
     * @instance
     */

    Card.prototype.primaryButton = "";
    /**
     * Card primaryButtonValue.
     * @member {string} primaryButtonValue
     * @memberof Card
     * @instance
     */

    Card.prototype.primaryButtonValue = "";
    /**
     * Card secondaryButton.
     * @member {string} secondaryButton
     * @memberof Card
     * @instance
     */

    Card.prototype.secondaryButton = "";
    /**
     * Card secondaryButtonValue.
     * @member {string} secondaryButtonValue
     * @memberof Card
     * @instance
     */

    Card.prototype.secondaryButtonValue = "";
    /**
     * Encodes the specified Card message. Does not implicitly {@link Card.verify|verify} messages.
     * @function encode
     * @memberof Card
     * @static
     * @param {Card} message Card message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    Card.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.backgroundImage);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.title);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.desc);
      if (message.tag != null && message.hasOwnProperty("tag")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.tag);
      if (message.primaryButton != null && message.hasOwnProperty("primaryButton")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.primaryButton);
      if (message.primaryButtonValue != null && message.hasOwnProperty("primaryButtonValue")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.primaryButtonValue);
      if (message.secondaryButton != null && message.hasOwnProperty("secondaryButton")) writer.uint32(
      /* id 6, wireType 2 =*/
      50).string(message.secondaryButton);
      if (message.secondaryButtonValue != null && message.hasOwnProperty("secondaryButtonValue")) writer.uint32(
      /* id 7, wireType 2 =*/
      58).string(message.secondaryButtonValue);
      return writer;
    };
    /**
     * Decodes a Card message from the specified reader or buffer.
     * @function decode
     * @memberof Card
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Card} Card
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    Card.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.Card();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.backgroundImage = reader.string();
            break;

          case 1:
            message.title = reader.string();
            break;

          case 2:
            message.desc = reader.string();
            break;

          case 3:
            message.tag = reader.string();
            break;

          case 4:
            message.primaryButton = reader.string();
            break;

          case 5:
            message.primaryButtonValue = reader.string();
            break;

          case 6:
            message.secondaryButton = reader.string();
            break;

          case 7:
            message.secondaryButtonValue = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("backgroundImage")) throw $util.ProtocolError("missing required 'backgroundImage'", {
        instance: message
      });
      if (!message.hasOwnProperty("title")) throw $util.ProtocolError("missing required 'title'", {
        instance: message
      });
      if (!message.hasOwnProperty("desc")) throw $util.ProtocolError("missing required 'desc'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a Card message.
     * @function verify
     * @memberof Card
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    Card.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.backgroundImage)) return "backgroundImage: string expected";
      if (!$util.isString(message.title)) return "title: string expected";
      if (!$util.isString(message.desc)) return "desc: string expected";
      if (message.tag != null && message.hasOwnProperty("tag")) if (!$util.isString(message.tag)) return "tag: string expected";
      if (message.primaryButton != null && message.hasOwnProperty("primaryButton")) if (!$util.isString(message.primaryButton)) return "primaryButton: string expected";
      if (message.primaryButtonValue != null && message.hasOwnProperty("primaryButtonValue")) if (!$util.isString(message.primaryButtonValue)) return "primaryButtonValue: string expected";
      if (message.secondaryButton != null && message.hasOwnProperty("secondaryButton")) if (!$util.isString(message.secondaryButton)) return "secondaryButton: string expected";
      if (message.secondaryButtonValue != null && message.hasOwnProperty("secondaryButtonValue")) if (!$util.isString(message.secondaryButtonValue)) return "secondaryButtonValue: string expected";
      return null;
    };

    return Card;
  }();

  $root.CardView = function () {
    /**
     * Properties of a CardView.
     * @exports ICardView
     * @interface ICardView
     * @property {Array.<Card>|null} [cards] CardView cards
     */

    /**
     * Constructs a new CardView.
     * @exports CardView
     * @classdesc Represents a CardView.
     * @implements ICardView
     * @constructor
     * @param {ICardView=} [properties] Properties to set
     */
    function CardView(properties) {
      this.cards = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * CardView cards.
     * @member {Array.<Card>} cards
     * @memberof CardView
     * @instance
     */


    CardView.prototype.cards = $util.emptyArray;
    /**
     * Encodes the specified CardView message. Does not implicitly {@link CardView.verify|verify} messages.
     * @function encode
     * @memberof CardView
     * @static
     * @param {CardView} message CardView message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    CardView.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.cards != null && message.cards.length) for (var i = 0; i < message.cards.length; ++i) {
        $root.Card.encode(message.cards[i], writer.uint32(
        /* id 0, wireType 2 =*/
        2).fork()).ldelim();
      }
      return writer;
    };
    /**
     * Decodes a CardView message from the specified reader or buffer.
     * @function decode
     * @memberof CardView
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CardView} CardView
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    CardView.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.CardView();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            if (!(message.cards && message.cards.length)) message.cards = [];
            message.cards.push($root.Card.decode(reader, reader.uint32()));
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a CardView message.
     * @function verify
     * @memberof CardView
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    CardView.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";

      if (message.cards != null && message.hasOwnProperty("cards")) {
        if (!Array.isArray(message.cards)) return "cards: array expected";

        for (var i = 0; i < message.cards.length; ++i) {
          var error = $root.Card.verify(message.cards[i]);
          if (error) return "cards." + error;
        }
      }

      return null;
    };

    return CardView;
  }();

  $root.FormView = function () {
    /**
     * Properties of a FormView.
     * @exports IFormView
     * @interface IFormView
     * @property {string|null} [title] FormView title
     * @property {Array.<InputElementMessage>|null} [formControls] FormView formControls
     */

    /**
     * Constructs a new FormView.
     * @exports FormView
     * @classdesc Represents a FormView.
     * @implements IFormView
     * @constructor
     * @param {IFormView=} [properties] Properties to set
     */
    function FormView(properties) {
      this.formControls = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * FormView title.
     * @member {string} title
     * @memberof FormView
     * @instance
     */


    FormView.prototype.title = "";
    /**
     * FormView formControls.
     * @member {Array.<InputElementMessage>} formControls
     * @memberof FormView
     * @instance
     */

    FormView.prototype.formControls = $util.emptyArray;
    /**
     * Encodes the specified FormView message. Does not implicitly {@link FormView.verify|verify} messages.
     * @function encode
     * @memberof FormView
     * @static
     * @param {FormView} message FormView message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    FormView.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.title != null && message.hasOwnProperty("title")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.title);
      if (message.formControls != null && message.formControls.length) for (var i = 0; i < message.formControls.length; ++i) {
        $root.InputElementMessage.encode(message.formControls[i], writer.uint32(
        /* id 1, wireType 2 =*/
        10).fork()).ldelim();
      }
      return writer;
    };
    /**
     * Decodes a FormView message from the specified reader or buffer.
     * @function decode
     * @memberof FormView
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FormView} FormView
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    FormView.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.FormView();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.title = reader.string();
            break;

          case 1:
            if (!(message.formControls && message.formControls.length)) message.formControls = [];
            message.formControls.push($root.InputElementMessage.decode(reader, reader.uint32()));
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a FormView message.
     * @function verify
     * @memberof FormView
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    FormView.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.title != null && message.hasOwnProperty("title")) if (!$util.isString(message.title)) return "title: string expected";

      if (message.formControls != null && message.hasOwnProperty("formControls")) {
        if (!Array.isArray(message.formControls)) return "formControls: array expected";

        for (var i = 0; i < message.formControls.length; ++i) {
          var error = $root.InputElementMessage.verify(message.formControls[i]);
          if (error) return "formControls." + error;
        }
      }

      return null;
    };

    return FormView;
  }();

  return $root;
});