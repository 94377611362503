import { BotMessage } from "messaging";
import { BotStore } from "./bot";
import { ClientStore } from "../client";
import { ContextAndIdStore } from "../context-and-id";
import { LocaleStore } from "../locale";
import { autorun } from "mobx";
import { ONBOARDING } from "../../containers/StateContext";

export class BotManagerStore {
  constructor(
    private botStore: BotStore,
    private clientStore: ClientStore,
    private contextAndIdStore: ContextAndIdStore,
    private localeStore: LocaleStore
  ) {
    this.contextAndIdStore = contextAndIdStore;

    //NOTE: setup the BotManagerStore once the property context and gid is set and the client isConnectedToMQTTBroker
    autorun(async () => {
      if (!this.clientStore.isConnectedToMQTTBroker) {
        return;
      }

      if (!this.contextAndIdStore.gid || !this.contextAndIdStore.property) {
        return;
      }

      await this.setup();
      this.ping();
    });
  }

  public async reply({ text, gallery, data }: { text: string; gallery?: boolean; data?: any }) {
    const { gid, guest, property } = this.contextAndIdStore;

    if (!gid || !guest || !property) {
      throw new Error("Can't reply to bot, first set all contexts and IDs");
    }

    const { locale } = this.localeStore;
    const client = await this.clientStore.getClient();
    client &&
      (await client.sendBotMessage(
        new BotMessage({
          bookingNo: gid,
          data: data ? JSON.stringify(data) : undefined,
          gallery,
          gid,
          locale,
          property: `${property}`,
          sender: `${guest}`,
          text
        })
      ));
  }

  private async setup() {
    const { gid, property } = this.contextAndIdStore;
    if (!gid || !property) {
      throw new Error("Can't setup bot manager, first set all contexts and IDs");
    }

    const client = await this.clientStore.getClient();
    client && (await client.acceptBotMessage(property, this.botStore.addNewMessageToChat, `/${gid}`));
  }

  public ping() {
    const { tid, pid } = this.contextAndIdStore;
    const gid = localStorage.getItem(`${tid}/${pid}`) as string | undefined;

    if (gid) {
      this.reply({ text: ONBOARDING.WELCOME_BACK });
      return;
    }

    this.reply({ text: ONBOARDING.WELCOME });
  }
}
