import {
  AppBar as MuiAppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
  Theme
} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/GTranslate";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import RefreshIcon from "@material-ui/icons/Refresh";
import { inject, observer } from "mobx-react";
import React, { Component, createRef, Fragment, MouseEvent, ReactNode } from "react";
import { LocaleStore, ClientStore, ContextAndIdStore } from "../../stores";
import { NotificationToneStore } from "../../stores/notification-tone";
import { ILocaleConfiguration } from "messages";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundImage: "linear-gradient(to top, #ffd273, #ffde3b)",
      color: "#000000"
    },
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    offlineToast: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      padding: "4px 0 4px 16px"
    },
    content: {
      backgroundImage: `url("/img/bg.png")`,
      backgroundBlendMode: "difference",
      backgroundColor: "#f0f0f0",
      flexGrow: 1,
      overflowY: "auto"
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12
    }
  });

interface IAppBarProps extends WithStyles<typeof styles> {
  clientStore?: ClientStore;
  contextAndIdStore: ContextAndIdStore;
  isLocaleShown?: boolean;
  localeStore?: LocaleStore;
  notificationToneStore: NotificationToneStore;
  render?: (props: {}) => ReactNode;
  title: string;
}

export enum CHILDREN_PROP_TYPE_VARIANT {
  REACT_NODE = "REACT_NODE",
  RENDER_PROP = "RENDER_PROP"
}

type AppBarProps =
  | IAppBarProps & {
      children: ReactNode;
      variant: CHILDREN_PROP_TYPE_VARIANT.REACT_NODE;
    }
  | IAppBarProps & {
      children: (props: { contentContainerRef: HTMLDivElement | null }) => ReactNode;
      variant: CHILDREN_PROP_TYPE_VARIANT.RENDER_PROP;
    };

interface IAppBarState {
  translateAnchorEl: HTMLElement | undefined;
  muteNotification: boolean;
  dialogOpen: boolean;
}

@inject("clientStore", "localeStore")
@observer
class AppBar extends Component<AppBarProps, IAppBarState> {
  private contentContainerRef = createRef<HTMLDivElement>();

  public state: IAppBarState = {
    translateAnchorEl: undefined,
    muteNotification: false,
    dialogOpen: false
  };

  public setTranslateAnchorEl = (value: HTMLElement | undefined) => {
    this.setState({ translateAnchorEl: value });
  };

  public toggleMute = () => {
    const muteNotification = !this.state.muteNotification;
    this.props.notificationToneStore.setMuteConf(muteNotification);
    this.setState({ muteNotification });
  };

  public handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  public handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  public handleNewSession = () => {
    const { tid, pid } = this.props.contextAndIdStore;
    console.log(tid, pid);
    if (tid && pid) {
      localStorage.removeItem(`${tid}/${pid}`);
      localStorage.removeItem(`${tid}/${pid}/BOT_CHAT`);
      window.location.reload();
    }
    this.setState({ dialogOpen: false });
  };

  public render() {
    const { translateAnchorEl, muteNotification } = this.state;
    const { children, classes, clientStore, isLocaleShown, localeStore, render, title } = this.props;
    const { locale, localeList, setLocale, localeEnabled } = localeStore!;
    const { isConnectedToMQTTBroker } = clientStore!;
    return (
      <div className={classes.root}>
        <MuiAppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {title ? title : null}
            </Typography>
            {render && render({})}
            {isLocaleShown && (
              <Fragment>
                {localeEnabled && (
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Translate"
                    aria-haspopup="true"
                    aria-owns={Boolean(translateAnchorEl) ? "translate-menu" : undefined}
                    onClick={(event: MouseEvent<HTMLElement>) => this.setTranslateAnchorEl(event.currentTarget)}
                  >
                    <TranslateIcon />
                  </IconButton>
                )}
                <IconButton color="inherit" onClick={(event: MouseEvent<HTMLElement>) => this.toggleMute()}>
                  {muteNotification ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                <IconButton color="inherit" onClick={(event: MouseEvent<HTMLElement>) => this.handleDialogOpen()}>
                  {<RefreshIcon />}
                </IconButton>

                <Dialog
                  open={this.state.dialogOpen}
                  onClose={this.handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Start a new session ?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="inherit">
                      Are you sure you want to start a new session ? All current chats may be lost
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleDialogClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={this.handleNewSession} color="primary" autoFocus>
                      New Session
                    </Button>
                  </DialogActions>
                </Dialog>

                <Menu
                  id="translate-menu"
                  anchorEl={translateAnchorEl}
                  open={Boolean(translateAnchorEl)}
                  onClose={() => this.setTranslateAnchorEl(undefined)}
                >
                  {localeList.map((localeSelect: ILocaleConfiguration) => (
                    <MenuItem
                      key={`${localeSelect.localeLabel}-${localeSelect.localeValue}`}
                      selected={localeSelect.localeValue === locale}
                      onClick={() => {
                        if (localeSelect.localeValue) {
                          setLocale(localeSelect.localeValue);
                          this.setTranslateAnchorEl(undefined);
                        }
                      }}
                    >
                      {localeSelect.localeLabel}
                    </MenuItem>
                  ))}
                </Menu>
              </Fragment>
            )}
          </Toolbar>
        </MuiAppBar>
        {!isConnectedToMQTTBroker && (
          <div className={classes.offlineToast}>You're offline! We're trying to reconnect...</div>
        )}
        <div ref={this.contentContainerRef} className={classes.content}>
          {this.props.variant === CHILDREN_PROP_TYPE_VARIANT.RENDER_PROP &&
            this.props.children({ contentContainerRef: this.contentContainerRef.current })}
          {this.props.variant === CHILDREN_PROP_TYPE_VARIANT.REACT_NODE && children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppBar);
