import React, { FunctionComponent } from "react";

import {
  AppBar,
  createStyles,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { AppBarProps } from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import { Gallery } from "../Gallery";
import styled from "../../styles/styled-components";

const styles = () =>
  createStyles({
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    }
  });

const StyledAppBar = styled(AppBar as React.SFC<AppBarProps>)`
  background-image: linear-gradient(to top, #ffd273, #ffde3b);
  color: #000000 !important;
`;

export interface IFullscreenGalleryDialogProps extends WithStyles<typeof styles> {
  imgUrls: string | string[];
  clickedStep: number;
  open: boolean;
  handleClose: () => void;
}

const FullscreenGalleryDialog: FunctionComponent<IFullscreenGalleryDialogProps> = (
  props: IFullscreenGalleryDialogProps
) => {
  const { classes, clickedStep, handleClose, open, imgUrls } = props;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={props => <Slide direction="up" {...props} />}
    >
      <StyledAppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Uploaded Images
          </Typography>
        </Toolbar>
      </StyledAppBar>
      <Gallery fullscreen={true} initialActiveStep={clickedStep} imgUrls={imgUrls} stopAutoPlay={true} />
    </Dialog>
  );
};

export default withStyles(styles)(FullscreenGalleryDialog);
