import React, { FunctionComponent, ChangeEvent } from "react";
import { IUploadFilesMessage } from "messaging";
import { Grow, Button, Typography, createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "36px",
      "&:hover": {
        backgroundColor: grey[50]
      },
      backgroundColor: "#ffffff",
      color: theme.palette.getContrastText("#ffffff")
    }
  });

interface IFilesUploadProps extends WithStyles<typeof styles> {
  uploadFilesMessage: IUploadFilesMessage;
  handleFilesChange: (e: ChangeEvent<HTMLInputElement>, serreqid: string) => void;
}

const FilesUpload: FunctionComponent<IFilesUploadProps> = (props: IFilesUploadProps) => {
  const { classes, uploadFilesMessage, handleFilesChange } = props;
  return (
    <Grow in={true} timeout={100}>
      <Button classes={{ root: classes.root }} variant="extendedFab" color="primary" component="label">
        <Typography color="default">Upload File(s)</Typography>
        <input
          style={{ display: "none" }}
          type="file"
          accept={uploadFilesMessage.accept!}
          multiple={uploadFilesMessage.multiple || false}
          onChange={e => handleFilesChange(e, uploadFilesMessage!.serreqid)}
        />
      </Button>
    </Grow>
  );
};

export default withStyles(styles)(FilesUpload);
