import { IPeerMessage, PeerMessage } from "messaging";
import { GuestChatPersistenceService, NotificationToneService } from "../../services";
import { observable } from "mobx";
import { ContextAndIdStore } from "../context-and-id";

enum DEFAULTS {
  NO_OF_RECORDS = 15
}

export class ChatStore {
  @observable public messages: PeerMessage[] = [];
  @observable public preConnectionMessages: PeerMessage[] = [];
  @observable public postConnectionMessages: PeerMessage[] = [];
  @observable public unreadMessages: PeerMessage[] = [];

  @observable private noOfRecordsInLastFetch?: number;
  private onNewMessage?: () => void;
  private onFirstPageLoad?: () => void;

  private noOfRecords: number = DEFAULTS.NO_OF_RECORDS;
  private sesid?: string;

  constructor(private contextAndIdStore: ContextAndIdStore, private notificationToneService: NotificationToneService) {
    this.addMessage = this.addMessage.bind(this);
  }

  public setOnNewMessage(callback: () => void) {
    this.onNewMessage = callback;
  }

  public setOnFirstPageLoad(callback: () => void) {
    this.onFirstPageLoad = callback;
  }

  public init(sesid: string) {
    this.messages = [];
    this.unreadMessages = [];
    this.sesid = sesid;
    this.fetch(0);
  }

  public clear() {
    this.messages = [];
    this.postConnectionMessages = [];
    this.preConnectionMessages = [];
    this.unreadMessages = [];
    this.sesid = undefined;
  }

  public clearPreConnectionMessages() {
    this.preConnectionMessages = [];
  }

  public addPreConnectionMessage(message: PeerMessage) {
    this.preConnectionMessages.push(message);
    this.playNotificationTone({ sender: message.sender, isPreOrPostConnectionMessage: true });
    this.onNewMessage && this.onNewMessage();
  }

  public addPostConnectionMessage(message: PeerMessage) {
    this.postConnectionMessages.push(message);
    this.playNotificationTone({ sender: message.sender, isPreOrPostConnectionMessage: true });
    this.onNewMessage && this.onNewMessage();
  }

  public addUnreadMessage(message: PeerMessage) {
    this.unreadMessages.push(message);
  }

  public addMessage(message: PeerMessage) {
    this.messages.push(message);
    this.playNotificationTone({ sender: message.sender, isPreOrPostConnectionMessage: false });
    this.onNewMessage && this.onNewMessage();
  }

  public readAllUnreadMessages() {
    this.messages = [...this.messages, ...this.unreadMessages];
    this.unreadMessages = [];
  }

  // NOTE: Could fail in a case where the total no of messages are in the multiples of noOfRecords
  // TODO: This could be improved @technical-debt @future-improvement
  public hasMoreMessagesToFetch(): boolean {
    return this.noOfRecordsInLastFetch === this.noOfRecords;
  }

  public async fetch(page: number) {
    if (!this.sesid) {
      throw new Error("Can't fetch chat messages, create a chat properly");
    }

    const messages = await GuestChatPersistenceService.fetchMessages(this.sesid, page, this.noOfRecords);
    this.messages = [
      ...(messages && messages.map((message: IPeerMessage) => new PeerMessage(message))),
      ...this.messages
    ];

    this.noOfRecordsInLastFetch = messages.length;

    if (page === 0) {
      this.onFirstPageLoad && this.onFirstPageLoad();
    }
  }

  private playNotificationTone({
    isPreOrPostConnectionMessage,
    sender
  }: {
    sender: string;
    isPreOrPostConnectionMessage: boolean;
  }) {
    const { guest } = this.contextAndIdStore;
    if (guest && sender !== `${guest}` && isPreOrPostConnectionMessage) {
      this.notificationToneService.play("bot.message.receive");
    } else if (guest && sender === `${guest}` && isPreOrPostConnectionMessage) {
      this.notificationToneService.play("bot.message.send");
    } else if (guest && sender !== `${guest}` && !isPreOrPostConnectionMessage) {
      this.notificationToneService.play("chat.message.receive");
    } else if (guest && sender === `${guest}` && !isPreOrPostConnectionMessage) {
      this.notificationToneService.play("chat.message.send");
    }
  }
}
