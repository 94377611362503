/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function (global, factory) {
  /* global define, require, module */

  /* AMD */
  if (typeof define === 'function' && define.amd) define(["protobufjs/minimal"], factory);
  /* CommonJS */
  else if (typeof require === 'function' && typeof module === 'object' && module && module.exports) module.exports = factory(require("protobufjs/minimal"));
})(this, function ($protobuf) {
  "use strict"; // Common aliases

  var $Reader = $protobuf.Reader,
      $Writer = $protobuf.Writer,
      $util = $protobuf.util; // Exported root namespace

  var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

  $root.ProxyGuestChatRequest = function () {
    /**
     * Properties of a ProxyGuestChatRequest.
     * @exports IProxyGuestChatRequest
     * @interface IProxyGuestChatRequest
     * @property {string} chreqid ProxyGuestChatRequest chreqid
     * @property {string} guest ProxyGuestChatRequest guest
     * @property {string} property ProxyGuestChatRequest property
     * @property {string|null} [bookingNo] ProxyGuestChatRequest bookingNo
     * @property {string|null} [email] ProxyGuestChatRequest email
     * @property {string|null} [name] ProxyGuestChatRequest name
     */

    /**
     * Constructs a new ProxyGuestChatRequest.
     * @exports ProxyGuestChatRequest
     * @classdesc Represents a ProxyGuestChatRequest.
     * @implements IProxyGuestChatRequest
     * @constructor
     * @param {IProxyGuestChatRequest=} [properties] Properties to set
     */
    function ProxyGuestChatRequest(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ProxyGuestChatRequest chreqid.
     * @member {string} chreqid
     * @memberof ProxyGuestChatRequest
     * @instance
     */


    ProxyGuestChatRequest.prototype.chreqid = "";
    /**
     * ProxyGuestChatRequest guest.
     * @member {string} guest
     * @memberof ProxyGuestChatRequest
     * @instance
     */

    ProxyGuestChatRequest.prototype.guest = "";
    /**
     * ProxyGuestChatRequest property.
     * @member {string} property
     * @memberof ProxyGuestChatRequest
     * @instance
     */

    ProxyGuestChatRequest.prototype.property = "";
    /**
     * ProxyGuestChatRequest bookingNo.
     * @member {string} bookingNo
     * @memberof ProxyGuestChatRequest
     * @instance
     */

    ProxyGuestChatRequest.prototype.bookingNo = "";
    /**
     * ProxyGuestChatRequest email.
     * @member {string} email
     * @memberof ProxyGuestChatRequest
     * @instance
     */

    ProxyGuestChatRequest.prototype.email = "";
    /**
     * ProxyGuestChatRequest name.
     * @member {string} name
     * @memberof ProxyGuestChatRequest
     * @instance
     */

    ProxyGuestChatRequest.prototype.name = "";
    /**
     * Encodes the specified ProxyGuestChatRequest message. Does not implicitly {@link ProxyGuestChatRequest.verify|verify} messages.
     * @function encode
     * @memberof ProxyGuestChatRequest
     * @static
     * @param {ProxyGuestChatRequest} message ProxyGuestChatRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ProxyGuestChatRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.chreqid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.guest);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.bookingNo);
      if (message.email != null && message.hasOwnProperty("email")) writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.email);
      if (message.name != null && message.hasOwnProperty("name")) writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.name);
      return writer;
    };
    /**
     * Decodes a ProxyGuestChatRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ProxyGuestChatRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProxyGuestChatRequest} ProxyGuestChatRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ProxyGuestChatRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ProxyGuestChatRequest();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.chreqid = reader.string();
            break;

          case 1:
            message.guest = reader.string();
            break;

          case 2:
            message.property = reader.string();
            break;

          case 3:
            message.bookingNo = reader.string();
            break;

          case 4:
            message.email = reader.string();
            break;

          case 5:
            message.name = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("chreqid")) throw $util.ProtocolError("missing required 'chreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ProxyGuestChatRequest message.
     * @function verify
     * @memberof ProxyGuestChatRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ProxyGuestChatRequest.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.chreqid)) return "chreqid: string expected";
      if (!$util.isString(message.guest)) return "guest: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      if (message.bookingNo != null && message.hasOwnProperty("bookingNo")) if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";
      if (message.email != null && message.hasOwnProperty("email")) if (!$util.isString(message.email)) return "email: string expected";
      if (message.name != null && message.hasOwnProperty("name")) if (!$util.isString(message.name)) return "name: string expected";
      return null;
    };

    return ProxyGuestChatRequest;
  }();

  $root.GuestChatRequest = function () {
    /**
     * Properties of a GuestChatRequest.
     * @exports IGuestChatRequest
     * @interface IGuestChatRequest
     * @property {BookingInformation|null} [booking] GuestChatRequest booking
     * @property {ProxyGuestChatRequest} proxy GuestChatRequest proxy
     * @property {string} staff GuestChatRequest staff
     */

    /**
     * Constructs a new GuestChatRequest.
     * @exports GuestChatRequest
     * @classdesc Represents a GuestChatRequest.
     * @implements IGuestChatRequest
     * @constructor
     * @param {IGuestChatRequest=} [properties] Properties to set
     */
    function GuestChatRequest(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatRequest booking.
     * @member {BookingInformation|null|undefined} booking
     * @memberof GuestChatRequest
     * @instance
     */


    GuestChatRequest.prototype.booking = null;
    /**
     * GuestChatRequest proxy.
     * @member {ProxyGuestChatRequest} proxy
     * @memberof GuestChatRequest
     * @instance
     */

    GuestChatRequest.prototype.proxy = null;
    /**
     * GuestChatRequest staff.
     * @member {string} staff
     * @memberof GuestChatRequest
     * @instance
     */

    GuestChatRequest.prototype.staff = "";
    /**
     * Encodes the specified GuestChatRequest message. Does not implicitly {@link GuestChatRequest.verify|verify} messages.
     * @function encode
     * @memberof GuestChatRequest
     * @static
     * @param {GuestChatRequest} message GuestChatRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatRequest.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.booking != null && message.hasOwnProperty("booking")) $root.BookingInformation.encode(message.booking, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      $root.ProxyGuestChatRequest.encode(message.proxy, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.staff);
      return writer;
    };
    /**
     * Decodes a GuestChatRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatRequest} GuestChatRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatRequest.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatRequest();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.booking = $root.BookingInformation.decode(reader, reader.uint32());
            break;

          case 1:
            message.proxy = $root.ProxyGuestChatRequest.decode(reader, reader.uint32());
            break;

          case 2:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("proxy")) throw $util.ProtocolError("missing required 'proxy'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatRequest message.
     * @function verify
     * @memberof GuestChatRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatRequest.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";

      if (message.booking != null && message.hasOwnProperty("booking")) {
        var error = $root.BookingInformation.verify(message.booking);
        if (error) return "booking." + error;
      }

      {
        var error = $root.ProxyGuestChatRequest.verify(message.proxy);
        if (error) return "proxy." + error;
      }
      if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return GuestChatRequest;
  }();

  $root.GuestChatAnswer = function () {
    /**
     * Properties of a GuestChatAnswer.
     * @exports IGuestChatAnswer
     * @interface IGuestChatAnswer
     * @property {GuestChatRequest} request GuestChatAnswer request
     * @property {string} staff GuestChatAnswer staff
     */

    /**
     * Constructs a new GuestChatAnswer.
     * @exports GuestChatAnswer
     * @classdesc Represents a GuestChatAnswer.
     * @implements IGuestChatAnswer
     * @constructor
     * @param {IGuestChatAnswer=} [properties] Properties to set
     */
    function GuestChatAnswer(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatAnswer request.
     * @member {GuestChatRequest} request
     * @memberof GuestChatAnswer
     * @instance
     */


    GuestChatAnswer.prototype.request = null;
    /**
     * GuestChatAnswer staff.
     * @member {string} staff
     * @memberof GuestChatAnswer
     * @instance
     */

    GuestChatAnswer.prototype.staff = "";
    /**
     * Encodes the specified GuestChatAnswer message. Does not implicitly {@link GuestChatAnswer.verify|verify} messages.
     * @function encode
     * @memberof GuestChatAnswer
     * @static
     * @param {GuestChatAnswer} message GuestChatAnswer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatAnswer.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.GuestChatRequest.encode(message.request, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.staff);
      return writer;
    };
    /**
     * Decodes a GuestChatAnswer message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatAnswer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatAnswer} GuestChatAnswer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatAnswer.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatAnswer();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.request = $root.GuestChatRequest.decode(reader, reader.uint32());
            break;

          case 1:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("request")) throw $util.ProtocolError("missing required 'request'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatAnswer message.
     * @function verify
     * @memberof GuestChatAnswer
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatAnswer.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.GuestChatRequest.verify(message.request);
        if (error) return "request." + error;
      }
      if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return GuestChatAnswer;
  }();

  $root.GuestChatDecline = function () {
    /**
     * Properties of a GuestChatDecline.
     * @exports IGuestChatDecline
     * @interface IGuestChatDecline
     * @property {GuestChatRequest} request GuestChatDecline request
     * @property {string} staff GuestChatDecline staff
     */

    /**
     * Constructs a new GuestChatDecline.
     * @exports GuestChatDecline
     * @classdesc Represents a GuestChatDecline.
     * @implements IGuestChatDecline
     * @constructor
     * @param {IGuestChatDecline=} [properties] Properties to set
     */
    function GuestChatDecline(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatDecline request.
     * @member {GuestChatRequest} request
     * @memberof GuestChatDecline
     * @instance
     */


    GuestChatDecline.prototype.request = null;
    /**
     * GuestChatDecline staff.
     * @member {string} staff
     * @memberof GuestChatDecline
     * @instance
     */

    GuestChatDecline.prototype.staff = "";
    /**
     * Encodes the specified GuestChatDecline message. Does not implicitly {@link GuestChatDecline.verify|verify} messages.
     * @function encode
     * @memberof GuestChatDecline
     * @static
     * @param {GuestChatDecline} message GuestChatDecline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatDecline.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.GuestChatRequest.encode(message.request, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.staff);
      return writer;
    };
    /**
     * Decodes a GuestChatDecline message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatDecline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatDecline} GuestChatDecline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatDecline.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatDecline();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.request = $root.GuestChatRequest.decode(reader, reader.uint32());
            break;

          case 1:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("request")) throw $util.ProtocolError("missing required 'request'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatDecline message.
     * @function verify
     * @memberof GuestChatDecline
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatDecline.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.GuestChatRequest.verify(message.request);
        if (error) return "request." + error;
      }
      if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return GuestChatDecline;
  }();

  $root.GuestChatHandled = function () {
    /**
     * Properties of a GuestChatHandled.
     * @exports IGuestChatHandled
     * @interface IGuestChatHandled
     * @property {GuestChatAnswer} answer GuestChatHandled answer
     * @property {string} staff GuestChatHandled staff
     */

    /**
     * Constructs a new GuestChatHandled.
     * @exports GuestChatHandled
     * @classdesc Represents a GuestChatHandled.
     * @implements IGuestChatHandled
     * @constructor
     * @param {IGuestChatHandled=} [properties] Properties to set
     */
    function GuestChatHandled(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatHandled answer.
     * @member {GuestChatAnswer} answer
     * @memberof GuestChatHandled
     * @instance
     */


    GuestChatHandled.prototype.answer = null;
    /**
     * GuestChatHandled staff.
     * @member {string} staff
     * @memberof GuestChatHandled
     * @instance
     */

    GuestChatHandled.prototype.staff = "";
    /**
     * Encodes the specified GuestChatHandled message. Does not implicitly {@link GuestChatHandled.verify|verify} messages.
     * @function encode
     * @memberof GuestChatHandled
     * @static
     * @param {GuestChatHandled} message GuestChatHandled message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatHandled.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.GuestChatAnswer.encode(message.answer, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.staff);
      return writer;
    };
    /**
     * Decodes a GuestChatHandled message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatHandled
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatHandled} GuestChatHandled
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatHandled.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatHandled();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.answer = $root.GuestChatAnswer.decode(reader, reader.uint32());
            break;

          case 1:
            message.staff = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("answer")) throw $util.ProtocolError("missing required 'answer'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatHandled message.
     * @function verify
     * @memberof GuestChatHandled
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatHandled.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.GuestChatAnswer.verify(message.answer);
        if (error) return "answer." + error;
      }
      if (!$util.isString(message.staff)) return "staff: string expected";
      return null;
    };

    return GuestChatHandled;
  }();

  $root.GuestChatComplete = function () {
    /**
     * Properties of a GuestChatComplete.
     * @exports IGuestChatComplete
     * @interface IGuestChatComplete
     * @property {GuestChatSession} session GuestChatComplete session
     */

    /**
     * Constructs a new GuestChatComplete.
     * @exports GuestChatComplete
     * @classdesc Represents a GuestChatComplete.
     * @implements IGuestChatComplete
     * @constructor
     * @param {IGuestChatComplete=} [properties] Properties to set
     */
    function GuestChatComplete(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatComplete session.
     * @member {GuestChatSession} session
     * @memberof GuestChatComplete
     * @instance
     */


    GuestChatComplete.prototype.session = null;
    /**
     * Encodes the specified GuestChatComplete message. Does not implicitly {@link GuestChatComplete.verify|verify} messages.
     * @function encode
     * @memberof GuestChatComplete
     * @static
     * @param {GuestChatComplete} message GuestChatComplete message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatComplete.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.GuestChatSession.encode(message.session, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestChatComplete message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatComplete
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatComplete} GuestChatComplete
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatComplete.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatComplete();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.session = $root.GuestChatSession.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("session")) throw $util.ProtocolError("missing required 'session'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatComplete message.
     * @function verify
     * @memberof GuestChatComplete
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatComplete.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.GuestChatSession.verify(message.session);
        if (error) return "session." + error;
      }
      return null;
    };

    return GuestChatComplete;
  }();

  $root.NoStaffOnline = function () {
    /**
     * Properties of a NoStaffOnline.
     * @exports INoStaffOnline
     * @interface INoStaffOnline
     * @property {ProxyGuestChatRequest} proxy NoStaffOnline proxy
     */

    /**
     * Constructs a new NoStaffOnline.
     * @exports NoStaffOnline
     * @classdesc Represents a NoStaffOnline.
     * @implements INoStaffOnline
     * @constructor
     * @param {INoStaffOnline=} [properties] Properties to set
     */
    function NoStaffOnline(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * NoStaffOnline proxy.
     * @member {ProxyGuestChatRequest} proxy
     * @memberof NoStaffOnline
     * @instance
     */


    NoStaffOnline.prototype.proxy = null;
    /**
     * Encodes the specified NoStaffOnline message. Does not implicitly {@link NoStaffOnline.verify|verify} messages.
     * @function encode
     * @memberof NoStaffOnline
     * @static
     * @param {NoStaffOnline} message NoStaffOnline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    NoStaffOnline.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.ProxyGuestChatRequest.encode(message.proxy, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a NoStaffOnline message from the specified reader or buffer.
     * @function decode
     * @memberof NoStaffOnline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NoStaffOnline} NoStaffOnline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    NoStaffOnline.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.NoStaffOnline();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.proxy = $root.ProxyGuestChatRequest.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("proxy")) throw $util.ProtocolError("missing required 'proxy'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a NoStaffOnline message.
     * @function verify
     * @memberof NoStaffOnline
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    NoStaffOnline.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.ProxyGuestChatRequest.verify(message.proxy);
        if (error) return "proxy." + error;
      }
      return null;
    };

    return NoStaffOnline;
  }();

  $root.ProxyGuestChatRequestTimeout = function () {
    /**
     * Properties of a ProxyGuestChatRequestTimeout.
     * @exports IProxyGuestChatRequestTimeout
     * @interface IProxyGuestChatRequestTimeout
     * @property {ProxyGuestChatRequest} proxy ProxyGuestChatRequestTimeout proxy
     */

    /**
     * Constructs a new ProxyGuestChatRequestTimeout.
     * @exports ProxyGuestChatRequestTimeout
     * @classdesc Represents a ProxyGuestChatRequestTimeout.
     * @implements IProxyGuestChatRequestTimeout
     * @constructor
     * @param {IProxyGuestChatRequestTimeout=} [properties] Properties to set
     */
    function ProxyGuestChatRequestTimeout(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ProxyGuestChatRequestTimeout proxy.
     * @member {ProxyGuestChatRequest} proxy
     * @memberof ProxyGuestChatRequestTimeout
     * @instance
     */


    ProxyGuestChatRequestTimeout.prototype.proxy = null;
    /**
     * Encodes the specified ProxyGuestChatRequestTimeout message. Does not implicitly {@link ProxyGuestChatRequestTimeout.verify|verify} messages.
     * @function encode
     * @memberof ProxyGuestChatRequestTimeout
     * @static
     * @param {ProxyGuestChatRequestTimeout} message ProxyGuestChatRequestTimeout message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ProxyGuestChatRequestTimeout.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.ProxyGuestChatRequest.encode(message.proxy, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a ProxyGuestChatRequestTimeout message from the specified reader or buffer.
     * @function decode
     * @memberof ProxyGuestChatRequestTimeout
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProxyGuestChatRequestTimeout} ProxyGuestChatRequestTimeout
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ProxyGuestChatRequestTimeout.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ProxyGuestChatRequestTimeout();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.proxy = $root.ProxyGuestChatRequest.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("proxy")) throw $util.ProtocolError("missing required 'proxy'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ProxyGuestChatRequestTimeout message.
     * @function verify
     * @memberof ProxyGuestChatRequestTimeout
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ProxyGuestChatRequestTimeout.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.ProxyGuestChatRequest.verify(message.proxy);
        if (error) return "proxy." + error;
      }
      return null;
    };

    return ProxyGuestChatRequestTimeout;
  }();

  $root.ProxyGuestChatDecline = function () {
    /**
     * Properties of a ProxyGuestChatDecline.
     * @exports IProxyGuestChatDecline
     * @interface IProxyGuestChatDecline
     * @property {ProxyGuestChatRequest} proxy ProxyGuestChatDecline proxy
     */

    /**
     * Constructs a new ProxyGuestChatDecline.
     * @exports ProxyGuestChatDecline
     * @classdesc Represents a ProxyGuestChatDecline.
     * @implements IProxyGuestChatDecline
     * @constructor
     * @param {IProxyGuestChatDecline=} [properties] Properties to set
     */
    function ProxyGuestChatDecline(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * ProxyGuestChatDecline proxy.
     * @member {ProxyGuestChatRequest} proxy
     * @memberof ProxyGuestChatDecline
     * @instance
     */


    ProxyGuestChatDecline.prototype.proxy = null;
    /**
     * Encodes the specified ProxyGuestChatDecline message. Does not implicitly {@link ProxyGuestChatDecline.verify|verify} messages.
     * @function encode
     * @memberof ProxyGuestChatDecline
     * @static
     * @param {ProxyGuestChatDecline} message ProxyGuestChatDecline message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    ProxyGuestChatDecline.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.ProxyGuestChatRequest.encode(message.proxy, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a ProxyGuestChatDecline message from the specified reader or buffer.
     * @function decode
     * @memberof ProxyGuestChatDecline
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProxyGuestChatDecline} ProxyGuestChatDecline
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    ProxyGuestChatDecline.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.ProxyGuestChatDecline();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.proxy = $root.ProxyGuestChatRequest.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("proxy")) throw $util.ProtocolError("missing required 'proxy'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a ProxyGuestChatDecline message.
     * @function verify
     * @memberof ProxyGuestChatDecline
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    ProxyGuestChatDecline.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.ProxyGuestChatRequest.verify(message.proxy);
        if (error) return "proxy." + error;
      }
      return null;
    };

    return ProxyGuestChatDecline;
  }();

  $root.GuestChatRequestTimeout = function () {
    /**
     * Properties of a GuestChatRequestTimeout.
     * @exports IGuestChatRequestTimeout
     * @interface IGuestChatRequestTimeout
     * @property {GuestChatRequest} request GuestChatRequestTimeout request
     */

    /**
     * Constructs a new GuestChatRequestTimeout.
     * @exports GuestChatRequestTimeout
     * @classdesc Represents a GuestChatRequestTimeout.
     * @implements IGuestChatRequestTimeout
     * @constructor
     * @param {IGuestChatRequestTimeout=} [properties] Properties to set
     */
    function GuestChatRequestTimeout(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatRequestTimeout request.
     * @member {GuestChatRequest} request
     * @memberof GuestChatRequestTimeout
     * @instance
     */


    GuestChatRequestTimeout.prototype.request = null;
    /**
     * Encodes the specified GuestChatRequestTimeout message. Does not implicitly {@link GuestChatRequestTimeout.verify|verify} messages.
     * @function encode
     * @memberof GuestChatRequestTimeout
     * @static
     * @param {GuestChatRequestTimeout} message GuestChatRequestTimeout message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatRequestTimeout.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      $root.GuestChatRequest.encode(message.request, writer.uint32(
      /* id 0, wireType 2 =*/
      2).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestChatRequestTimeout message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatRequestTimeout
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatRequestTimeout} GuestChatRequestTimeout
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatRequestTimeout.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatRequestTimeout();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.request = $root.GuestChatRequest.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("request")) throw $util.ProtocolError("missing required 'request'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatRequestTimeout message.
     * @function verify
     * @memberof GuestChatRequestTimeout
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatRequestTimeout.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      {
        var error = $root.GuestChatRequest.verify(message.request);
        if (error) return "request." + error;
      }
      return null;
    };

    return GuestChatRequestTimeout;
  }();

  $root.BookingInformation = function () {
    /**
     * Properties of a BookingInformation.
     * @exports IBookingInformation
     * @interface IBookingInformation
     * @property {string|null} [firstName] BookingInformation firstName
     * @property {string|null} [lastName] BookingInformation lastName
     * @property {string|null} [roomNo] BookingInformation roomNo
     * @property {string|null} [email] BookingInformation email
     */

    /**
     * Constructs a new BookingInformation.
     * @exports BookingInformation
     * @classdesc Represents a BookingInformation.
     * @implements IBookingInformation
     * @constructor
     * @param {IBookingInformation=} [properties] Properties to set
     */
    function BookingInformation(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * BookingInformation firstName.
     * @member {string} firstName
     * @memberof BookingInformation
     * @instance
     */


    BookingInformation.prototype.firstName = "";
    /**
     * BookingInformation lastName.
     * @member {string} lastName
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.lastName = "";
    /**
     * BookingInformation roomNo.
     * @member {string} roomNo
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.roomNo = "";
    /**
     * BookingInformation email.
     * @member {string} email
     * @memberof BookingInformation
     * @instance
     */

    BookingInformation.prototype.email = "";
    /**
     * Encodes the specified BookingInformation message. Does not implicitly {@link BookingInformation.verify|verify} messages.
     * @function encode
     * @memberof BookingInformation
     * @static
     * @param {BookingInformation} message BookingInformation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    BookingInformation.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.firstName != null && message.hasOwnProperty("firstName")) writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.firstName);
      if (message.lastName != null && message.hasOwnProperty("lastName")) writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.lastName);
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.roomNo);
      if (message.email != null && message.hasOwnProperty("email")) writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.email);
      return writer;
    };
    /**
     * Decodes a BookingInformation message from the specified reader or buffer.
     * @function decode
     * @memberof BookingInformation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookingInformation} BookingInformation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    BookingInformation.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.BookingInformation();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.firstName = reader.string();
            break;

          case 1:
            message.lastName = reader.string();
            break;

          case 2:
            message.roomNo = reader.string();
            break;

          case 3:
            message.email = reader.string();
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      return message;
    };
    /**
     * Verifies a BookingInformation message.
     * @function verify
     * @memberof BookingInformation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    BookingInformation.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.firstName != null && message.hasOwnProperty("firstName")) if (!$util.isString(message.firstName)) return "firstName: string expected";
      if (message.lastName != null && message.hasOwnProperty("lastName")) if (!$util.isString(message.lastName)) return "lastName: string expected";
      if (message.roomNo != null && message.hasOwnProperty("roomNo")) if (!$util.isString(message.roomNo)) return "roomNo: string expected";
      if (message.email != null && message.hasOwnProperty("email")) if (!$util.isString(message.email)) return "email: string expected";
      return null;
    };

    return BookingInformation;
  }();

  $root.GuestChatSession = function () {
    /**
     * Properties of a GuestChatSession.
     * @exports IGuestChatSession
     * @interface IGuestChatSession
     * @property {string} chreqid GuestChatSession chreqid
     * @property {string} sesid GuestChatSession sesid
     * @property {string} property GuestChatSession property
     * @property {string} guest GuestChatSession guest
     * @property {string} staff GuestChatSession staff
     * @property {string} bookingNo GuestChatSession bookingNo
     * @property {BookingInformation|null} [booking] GuestChatSession booking
     */

    /**
     * Constructs a new GuestChatSession.
     * @exports GuestChatSession
     * @classdesc Represents a GuestChatSession.
     * @implements IGuestChatSession
     * @constructor
     * @param {IGuestChatSession=} [properties] Properties to set
     */
    function GuestChatSession(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestChatSession chreqid.
     * @member {string} chreqid
     * @memberof GuestChatSession
     * @instance
     */


    GuestChatSession.prototype.chreqid = "";
    /**
     * GuestChatSession sesid.
     * @member {string} sesid
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.sesid = "";
    /**
     * GuestChatSession property.
     * @member {string} property
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.property = "";
    /**
     * GuestChatSession guest.
     * @member {string} guest
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.guest = "";
    /**
     * GuestChatSession staff.
     * @member {string} staff
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.staff = "";
    /**
     * GuestChatSession bookingNo.
     * @member {string} bookingNo
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.bookingNo = "";
    /**
     * GuestChatSession booking.
     * @member {BookingInformation|null|undefined} booking
     * @memberof GuestChatSession
     * @instance
     */

    GuestChatSession.prototype.booking = null;
    /**
     * Encodes the specified GuestChatSession message. Does not implicitly {@link GuestChatSession.verify|verify} messages.
     * @function encode
     * @memberof GuestChatSession
     * @static
     * @param {GuestChatSession} message GuestChatSession message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestChatSession.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.chreqid);
      writer.uint32(
      /* id 1, wireType 2 =*/
      10).string(message.sesid);
      writer.uint32(
      /* id 2, wireType 2 =*/
      18).string(message.property);
      writer.uint32(
      /* id 3, wireType 2 =*/
      26).string(message.guest);
      writer.uint32(
      /* id 4, wireType 2 =*/
      34).string(message.staff);
      writer.uint32(
      /* id 5, wireType 2 =*/
      42).string(message.bookingNo);
      if (message.booking != null && message.hasOwnProperty("booking")) $root.BookingInformation.encode(message.booking, writer.uint32(
      /* id 6, wireType 2 =*/
      50).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestChatSession message from the specified reader or buffer.
     * @function decode
     * @memberof GuestChatSession
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestChatSession} GuestChatSession
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestChatSession.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestChatSession();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.chreqid = reader.string();
            break;

          case 1:
            message.sesid = reader.string();
            break;

          case 2:
            message.property = reader.string();
            break;

          case 3:
            message.guest = reader.string();
            break;

          case 4:
            message.staff = reader.string();
            break;

          case 5:
            message.bookingNo = reader.string();
            break;

          case 6:
            message.booking = $root.BookingInformation.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("chreqid")) throw $util.ProtocolError("missing required 'chreqid'", {
        instance: message
      });
      if (!message.hasOwnProperty("sesid")) throw $util.ProtocolError("missing required 'sesid'", {
        instance: message
      });
      if (!message.hasOwnProperty("property")) throw $util.ProtocolError("missing required 'property'", {
        instance: message
      });
      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      if (!message.hasOwnProperty("bookingNo")) throw $util.ProtocolError("missing required 'bookingNo'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestChatSession message.
     * @function verify
     * @memberof GuestChatSession
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestChatSession.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.chreqid)) return "chreqid: string expected";
      if (!$util.isString(message.sesid)) return "sesid: string expected";
      if (!$util.isString(message.property)) return "property: string expected";
      if (!$util.isString(message.guest)) return "guest: string expected";
      if (!$util.isString(message.staff)) return "staff: string expected";
      if (!$util.isString(message.bookingNo)) return "bookingNo: string expected";

      if (message.booking != null && message.hasOwnProperty("booking")) {
        var error = $root.BookingInformation.verify(message.booking);
        if (error) return "booking." + error;
      }

      return null;
    };

    return GuestChatSession;
  }();

  $root.GuestRoster = function () {
    /**
     * Properties of a GuestRoster.
     * @exports IGuestRoster
     * @interface IGuestRoster
     * @property {string} guest GuestRoster guest
     * @property {GuestChatSession|null} [session] GuestRoster session
     */

    /**
     * Constructs a new GuestRoster.
     * @exports GuestRoster
     * @classdesc Represents a GuestRoster.
     * @implements IGuestRoster
     * @constructor
     * @param {IGuestRoster=} [properties] Properties to set
     */
    function GuestRoster(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * GuestRoster guest.
     * @member {string} guest
     * @memberof GuestRoster
     * @instance
     */


    GuestRoster.prototype.guest = "";
    /**
     * GuestRoster session.
     * @member {GuestChatSession|null|undefined} session
     * @memberof GuestRoster
     * @instance
     */

    GuestRoster.prototype.session = null;
    /**
     * Encodes the specified GuestRoster message. Does not implicitly {@link GuestRoster.verify|verify} messages.
     * @function encode
     * @memberof GuestRoster
     * @static
     * @param {GuestRoster} message GuestRoster message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    GuestRoster.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.guest);
      if (message.session != null && message.hasOwnProperty("session")) $root.GuestChatSession.encode(message.session, writer.uint32(
      /* id 1, wireType 2 =*/
      10).fork()).ldelim();
      return writer;
    };
    /**
     * Decodes a GuestRoster message from the specified reader or buffer.
     * @function decode
     * @memberof GuestRoster
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GuestRoster} GuestRoster
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    GuestRoster.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.GuestRoster();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.guest = reader.string();
            break;

          case 1:
            message.session = $root.GuestChatSession.decode(reader, reader.uint32());
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("guest")) throw $util.ProtocolError("missing required 'guest'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a GuestRoster message.
     * @function verify
     * @memberof GuestRoster
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    GuestRoster.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.guest)) return "guest: string expected";

      if (message.session != null && message.hasOwnProperty("session")) {
        var error = $root.GuestChatSession.verify(message.session);
        if (error) return "session." + error;
      }

      return null;
    };

    return GuestRoster;
  }();

  $root.StaffRoster = function () {
    /**
     * Properties of a StaffRoster.
     * @exports IStaffRoster
     * @interface IStaffRoster
     * @property {string} staff StaffRoster staff
     * @property {Array.<GuestChatSession>|null} [sessions] StaffRoster sessions
     */

    /**
     * Constructs a new StaffRoster.
     * @exports StaffRoster
     * @classdesc Represents a StaffRoster.
     * @implements IStaffRoster
     * @constructor
     * @param {IStaffRoster=} [properties] Properties to set
     */
    function StaffRoster(properties) {
      this.sessions = [];
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) {
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }
    }
    /**
     * StaffRoster staff.
     * @member {string} staff
     * @memberof StaffRoster
     * @instance
     */


    StaffRoster.prototype.staff = "";
    /**
     * StaffRoster sessions.
     * @member {Array.<GuestChatSession>} sessions
     * @memberof StaffRoster
     * @instance
     */

    StaffRoster.prototype.sessions = $util.emptyArray;
    /**
     * Encodes the specified StaffRoster message. Does not implicitly {@link StaffRoster.verify|verify} messages.
     * @function encode
     * @memberof StaffRoster
     * @static
     * @param {StaffRoster} message StaffRoster message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */

    StaffRoster.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      writer.uint32(
      /* id 0, wireType 2 =*/
      2).string(message.staff);
      if (message.sessions != null && message.sessions.length) for (var i = 0; i < message.sessions.length; ++i) {
        $root.GuestChatSession.encode(message.sessions[i], writer.uint32(
        /* id 1, wireType 2 =*/
        10).fork()).ldelim();
      }
      return writer;
    };
    /**
     * Decodes a StaffRoster message from the specified reader or buffer.
     * @function decode
     * @memberof StaffRoster
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StaffRoster} StaffRoster
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */


    StaffRoster.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.StaffRoster();

      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 0:
            message.staff = reader.string();
            break;

          case 1:
            if (!(message.sessions && message.sessions.length)) message.sessions = [];
            message.sessions.push($root.GuestChatSession.decode(reader, reader.uint32()));
            break;

          default:
            reader.skipType(tag & 7);
            break;
        }
      }

      if (!message.hasOwnProperty("staff")) throw $util.ProtocolError("missing required 'staff'", {
        instance: message
      });
      return message;
    };
    /**
     * Verifies a StaffRoster message.
     * @function verify
     * @memberof StaffRoster
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */


    StaffRoster.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (!$util.isString(message.staff)) return "staff: string expected";

      if (message.sessions != null && message.hasOwnProperty("sessions")) {
        if (!Array.isArray(message.sessions)) return "sessions: array expected";

        for (var i = 0; i < message.sessions.length; ++i) {
          var error = $root.GuestChatSession.verify(message.sessions[i]);
          if (error) return "sessions." + error;
        }
      }

      return null;
    };

    return StaffRoster;
  }();

  return $root;
});