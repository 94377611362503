import { action, observable } from "mobx";

export class ChatWithUsStore {
  @observable public bookingNo: string = "";
  @observable public email: string = "";
  @observable public name: string = "";
  public chreqid: string = "";

  @action public clearAll = async () => {
    this.bookingNo = "";
    this.chreqid = "";
    this.email = "";
    this.name = "";
  };

  @action public setBookingNo = async (bookingNo: string) => {
    this.bookingNo = bookingNo;
  };

  public setChreqid = async (chreqid: string) => {
    this.chreqid = chreqid;
  };

  @action public setEmail = (email: string) => {
    this.email = email;
  };

  @action public setName = (name: string) => {
    this.name = name;
  };
}
