import { ICard, ICardView } from "messaging";
import React, { FunctionComponent } from "react";
import styled from "../../styles/styled-components";
import Card from "./Card";

const CardViewWrapper = styled.div`
  display: flex;
  margin-top: 4px;
  padding-left: 48px;
  width: 90vw;
  overflow-x: auto;
  white-space: nowrap;
`;

export interface ICardViewProps {
  cardView: ICardView;
  transitionDelay: number;
  handleClick: (reply: string) => void;
}

const CardView: FunctionComponent<ICardViewProps> = (props: ICardViewProps) => {
  const {
    cardView: { cards },
    transitionDelay,
    handleClick
  } = props;
  return (
    <CardViewWrapper>
      {cards &&
        cards.length > 0 &&
        cards.map((card: ICard, index: number) => (
          <Card
            card={card}
            handleClick={handleClick}
            index={index}
            transitionDelay={transitionDelay}
            key={`card-view#${index}`}
          />
        ))}
    </CardViewWrapper>
  );
};

export default CardView;
